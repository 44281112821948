/** @format */

import { i18n } from 'i18n/i18n';
import {
  getUserDetailsAsUserLoad,
  getUserDetailsAsUserSuccess,
  getUserDetailsAsUserFailure,
  updateUserDetailsFailure,
  updateUserDetailsLoad,
  updateUserDetailsSuccess,
  updatePasswordLoad,
  updatePasswordSuccess,
  updatePasswordFailure,
  postForgetPasswordMailLoad,
  postForgetPasswordMailSuccess,
  postForgetPasswordMailFailure,
} from 'redux/slices/UserDetails-slice';
import { AppThunk } from 'redux/store';
import { ProtectedUrls, PublicUrls } from 'routes/urls';

import commonAPI from 'shared/Api/commonAPI';
import {
  ForgotPasswordMailPayload,
  GetUserDetailsAsUserPayload,
  UpdateUserDetailsPayload,
} from 'types/UserDetails-types';
import { Toast } from 'widgets/Toast/Toast';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export const getUserDetailsAsUserServiceApi =
  (payload: GetUserDetailsAsUserPayload): AppThunk =>
  async (dispatch) => {
    dispatch(getUserDetailsAsUserLoad());

    await commonAPI
      .get(ProtectedUrls.getUserDetailsAsUserUrl.url(payload))
      .then((response) => {
        dispatch(getUserDetailsAsUserSuccess(response.data));
      })
      .catch((error) =>
        dispatch(
          getUserDetailsAsUserFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

export const updateUserDetailsServiceApi =
  (
    profileImage: string,
    selectedImage: File | null,
    payload: UpdateUserDetailsPayload
  ): AppThunk =>
  async (dispatch) => {
    dispatch(updateUserDetailsLoad());
    let postDetailsUrl = ProtectedUrls.updateUserDetailsUrl.url(payload);
    let response;
    const formData = new FormData();
    try {
      if (selectedImage) {
        formData.append('files', selectedImage);
        response = await commonAPI.post(
          ProtectedUrls.updateUserDetailsUrl.url(payload),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        response = await commonAPI.post(
          ProtectedUrls.updateUserDetailsUrl.url(payload)
        );
      }
      dispatch(updateUserDetailsSuccess(response.data));
      sessionStorage.setItem('profile_image_bytes', profileImage);
      sessionStorage.setItem('first_name', payload.first_name);
      sessionStorage.setItem('last_name', payload.last_name);
      Toast(
        i18n.t('toastMessageNotifications.UserDetailsUpdatedSuccessfully'),
        'success'
      );
    } catch (error) {
      dispatch(
        updateUserDetailsFailure(
          error ||
            i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain')
        )
      );
    }
  };

export const getUpdatePasswordService =
  (oldPassword: string, newPassword: string): AppThunk =>
  async (dispatch) => {
    dispatch(updatePasswordLoad());

    await commonAPI
      .get(ProtectedUrls.updatePassword.url(oldPassword, newPassword))
      .then((response) => {
        dispatch(updatePasswordSuccess(response.data));
        Toast(
          i18n.t('toastMessageNotifications.PasswordUpdatedSuccessfully'),
          'success'
        );
      })
      .catch((error) =>
        dispatch(
          updatePasswordFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

export const sendForgetPasswordMailServiceApi =
  (payload: ForgotPasswordMailPayload): AppThunk =>
  async (dispatch) => {
    dispatch(postForgetPasswordMailLoad());

    await commonAPI
      .post(PublicUrls.postUpdatePasswordMail.url(payload))
      .then((response) => {
        dispatch(postForgetPasswordMailSuccess(response.data));
        Toast(
          i18n.t('toastMessageNotifications.AResetPasswordEmailHasBeenSent'),
          'success'
        );
      })
      .catch((error) =>
        dispatch(
          postForgetPasswordMailFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };

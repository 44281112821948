/** @format */

import { Box, useMediaQuery } from '@mui/material';
import { Field } from 'formik';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'widgets/Toast/Toast';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import { uploadWithLinkProps } from 'types/Upload-types';

function UploadWithLink({
  setVideoLinkIdForIframe,
  values,
  handleChange,
  touched,
  errors,
  setFieldValue,
  videoLinkIdForIframe,
  setSelectedSessionType,
}: uploadWithLinkProps) {
  const { t } = useTranslation();
  const isValidYouTubeUrl = (url: string) => {
    const youtubeUrlPattern = /(?:youtube\.com|youtu\.be)/i;
    return youtubeUrlPattern.test(url);
  };

  const handleVerifyLink = (
    values: { videoLink: string },
    functionCallFromButton: boolean
  ) => {
    if (isValidYouTubeUrl(values.videoLink)) {
      const videoIdPattern =
        /(?:\/videos\/|embed\/|v%3D|v\/|e\/|watch\?v=|v=|\/v\/|watch\?v%3D|v%3D)([^#\\&\\?/]*).*/;
      const shortVideoIdPattern = /\/shorts\/([^/]+)/;

      const match = values.videoLink.match(videoIdPattern);
      const matchShorts = values.videoLink.match(shortVideoIdPattern);
      if (match) setVideoLinkIdForIframe(match[1]);
      if (matchShorts && matchShorts[1]) {
        setVideoLinkIdForIframe(matchShorts[1]);
      }
      if (functionCallFromButton)
        Toast(t('toastMessageNotifications.Verified'));
    } else {
      setVideoLinkIdForIframe(
        t('upload.VideoIdNotFoundPreviewCannotBeGenerated')
      );
    }
  };

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  return (
    <Box
      sx={{
        width: '100%',
        mt: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '70%',
          display: 'flex',
          gap: 3,
          flexDirection: 'column',
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: isMobile || isTablet ? 'center' : 'baseline',
            gap: isMobile || isTablet ? 6 : 2,

            flexDirection: isMobile || isTablet ? 'column' : 'row',
          }}
        >
          <Field
            sx={{ width: isMobile || isTablet ? '100%' : '35%' }}
            name='videoDesc'
            as={TextField}
            label={t('upload.AddVideoTitle')}
            placeholder={t('upload.AddVideoTitle')}
            variant='outlined'
            value={values.videoDesc}
            onChange={handleChange}
            error={touched.videoDesc && !!errors.videoDesc}
            helperText={touched.videoDesc && errors.videoDesc}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth={isMobile || isTablet ? true : false}
          />

          <Field
            fullWidth={isMobile || isTablet ? true : false}
            variant='outlined'
            id='combo-box-demo'
            placeholder={t('upload.PasteTheLinkHere')}
            sx={{ width: isMobile || isTablet ? '100%' : '35%' }}
            label={t('upload.LinkUrl')}
            name='videoLink'
            as={TextField}
            value={values.videoLink}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleChange(e);
              // handleVerifyLink({ videoLink: e.target.value }, false);
            }}
            error={touched.videoLink && !!errors.videoLink}
            helperText={touched.videoLink && errors.videoLink}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Button
            sx={{
              color: '#1206f9',
              borderRadius: 25,
              mb: 3,
              p: 1,
              border: '1px solid #1206f9',
            }}
            variant='outlined'
            onClick={() => handleVerifyLink(values, true)}
            label='upload.VerifyLink'
          />

          <Button
            sx={{
              color: '#959595',
              borderRadius: 25,
              mb: 3,
              p: 1,
            }}
            onClick={() => {
              setVideoLinkIdForIframe('');
              setFieldValue('videoLink', '');
            }}
            label='upload.Clear'
          />
        </Box>

        <Typography
          sx={{
            color: 'var(--Body-Text, #33344B)',
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '-0.198px',

            width: '72%',
            textAlign: 'left',
          }}
          label='upload.Preview'
        />

        <Box
          sx={{
            minHeight: '40vh',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#F9F9F9',
            borderRadius: 3,
          }}
        >
          {videoLinkIdForIframe === '' ? (
            <Typography
              sx={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
                p: 1,
              }}
              label='upload.AddVideoLinkToGeneratePreview'
            />
          ) : videoLinkIdForIframe ===
            t('upload.VideoIdNotFoundPreviewCannotBeGenerated') ? (
            <Typography
              sx={{
                width: '100%',
                height: '100%',

                textAlign: 'center',
              }}
              label='upload.VideoIdNotFoundPreviewCannotBeGenerated'
            />
          ) : (
            <iframe
              width='100%'
              height='100%'
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              src={`https://www.youtube.com/embed/${videoLinkIdForIframe}`}
              title='YouTube Video'
              allowFullScreen
            ></iframe>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            flexDirection: isMobile || isTablet ? 'column' : 'row',
          }}
        >
          <Button
            sx={{
              backgroundColor: '#1206F9',
              borderRadius: 25,
              minWidth: '20%',
            }}
            variant='contained'
            type='submit'
            label='upload.Upload'
          />

          <Button
            sx={{ color: '#959595', borderRadius: 25 }}
            onClick={() => {
              setSelectedSessionType({
                id: 3,
                name: 'Casual Session',
              });
              setFieldValue('videoDesc', '');
              setFieldValue('videoLink', '');
              setVideoLinkIdForIframe('');
            }}
            label='upload.Cancel'
          />
        </Box>
      </Box>
    </Box>
  );
}

export default UploadWithLink;

import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RecordedVideoPreview from 'components/Record/RecordedVideoPreview';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import getMediaDevices from 'utils/getMediaDevices';
import Typography from 'widgets/Typography/Typography';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import QuestionVideoPlayer from './QuestionVideoPlayer';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

const initialValuesForRecordings: CamerasType | MicrophonesType = {
  deviceId: '',
  groupId: '',
  kind: '',
  label: '',
};
function PatientVideoRecorder({
  onRecordingComplete,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  Questionnaire,
  selectedVideoDevice,
  selectedAudioDevice,
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
  isReUpload,
  clearBlobUrl,
  isUploaded,
  isCameraEnabled,
}: {
  onRecordingComplete: (
    blobUrl: string | null,
    duration: number,
    isPrev: boolean
  ) => void;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex: number;
  Questionnaire: Array<QuestionnaireData>;
  selectedVideoDevice: MicrophoneDetails;
  selectedAudioDevice: CameraDetails;
  status: string;
  startRecording: () => void;
  stopRecording: () => void;
  mediaBlobUrl: string;
  isReUpload: boolean;
  clearBlobUrl: () => void;
  isUploaded: boolean;
  isCameraEnabled: boolean;
}) {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');

  const [recording, setRecording] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const totalSecondsRef = useRef<number>(0);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (recording) {
      startRecording();
      setRecording(false);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [recording]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTotalSeconds((prev) => {
          const newTotal = prev + 1;
          totalSecondsRef.current = newTotal;
          return newTotal;
        });
      }, 1000);

      if (totalSeconds === 600) {
        stopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    if (status === 'recording') {
      startTimer();
    }

    if (status === 'stopped') {
      pauseTimer();
    }
  }, [status]);

  const startTimer = useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRunning) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const resetTimer = () => {
    setIsRunning(false);
    setTotalSeconds(0);
    totalSecondsRef.current = 0;
    setMinutes(0);
    setSeconds(0);
  };

  const handlePreviousQuestion = async () => {
    const recordedSeconds = totalSecondsRef.current;
    if (mediaBlobUrl) {
      onRecordingComplete(mediaBlobUrl, recordedSeconds, true);
      clearBlobUrl();
      resetTimer();
    } else {
      setCurrentQuestionIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

        return newIndex;
      });
    }
  };

  useEffect(() => {
    if (status === 'stopped') {
      if (nextButtonRef.current) {
        nextButtonRef.current.focus();
      }

      const recordedSeconds = totalSecondsRef.current;

      console.log('mediaBlobUrl', mediaBlobUrl);
      if (mediaBlobUrl && currentQuestionIndex < Questionnaire.length - 1) {
        onRecordingComplete(mediaBlobUrl, recordedSeconds, false);
        clearBlobUrl();
        resetTimer();
      }
    }
  }, [mediaBlobUrl]);

  const handleStopClick = async () => {
    if (status === 'recording') {
      await stopRecording();
    }
  };

  const handleStopRecording = async () => {
    if (status === 'recording') {
      stopRecording();
    }

    const recordedSeconds = totalSecondsRef.current;

    if (mediaBlobUrl) {
      onRecordingComplete(mediaBlobUrl, recordedSeconds, false);
      clearBlobUrl();
      resetTimer();
    } else {
      setCurrentQuestionIndex((prevIndex) => {
        if (prevIndex < Questionnaire.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    }
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (selectedVideoDevice) {
      navigator.mediaDevices

        .getUserMedia({
          video: isCameraEnabled
            ? { deviceId: selectedVideoDevice.deviceId }
            : false,
          audio: true,
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRef.current.muted = true;
          }
        });
    }
  }, [selectedVideoDevice, isCameraEnabled]);
  const MemoizedVideoPreview = useMemo(() => {
    return (
      <>
        {isCameraEnabled ? (
          <video
            ref={videoRef}
            id='MemoizedVideoPreview'
            autoPlay
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              aspectRatio: 16 / 9,
              objectFit: 'cover',
              transform: 'scaleX(-1)',
            }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: 'auto',
              backgroundColor: 'black',
              minHeight: '55vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AudiotrackIcon
              sx={{
                color: 'white',
                fontSize: 50,
              }}
            />
          </Box>
        )}
      </>
    );
  }, [status, mediaBlobUrl, isCameraEnabled]);

  return (
    <Paper sx={{ height: '100%' }}>
      <Box sx={{ Height: '70%' }}>{MemoizedVideoPreview}</Box>{' '}
      <Box
        sx={{
          width: '95%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          p: 1,
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Button
            ref={nextButtonRef}
            sx={{ color: '#1206F9' }}
            endIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 1 || status === 'recording'}
          >
            Prev
          </Button>
          {status === 'recording' ? (
            <Button
              sx={{
                backgroundColor: '#1206F9',
                borderRadius: '30px',
                border: '1px solid #1206F9',
              }}
              variant='contained'
              startIcon={<StopRecordingIcon />}
              onClick={handleStopClick}
              id='stopRecordingButton'
            >
              Stop
            </Button>
          ) : (
            <Box>
              {status === 'acquiring_media' ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={startRecording}
                  sx={{
                    backgroundColor: '#1206F9',
                    borderRadius: '30px',
                    textTransform: 'none',
                  }}
                  size='small'
                  variant='contained'
                  startIcon={<StartAssessmentRecordIcon />}
                  id='startRecordingButton'
                >
                  {isReUpload || mediaBlobUrl
                    ? 'Re-record My Response'
                    : 'Record My Response'}
                </Button>
              )}
            </Box>
          )}
          <Button
            sx={{ color: '#1206F9' }}
            ref={nextButtonRef}
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleStopRecording}
            disabled={status === 'recording' || (!isUploaded && !mediaBlobUrl)}
            id={
              currentQuestionIndex === Questionnaire.length - 1
                ? 'FinishButton'
                : 'NextButton'
            }
          >
            {currentQuestionIndex === Questionnaire.length - 1
              ? 'Finish'
              : 'Next'}
          </Button>{' '}
        </Box>

        {/* status TBD             <Typography
          label=''
          sx={{
            position: isMobileOrTablet ? '' : 'absolute',
            right: isMobileOrTablet ? '' : '20px',
            top: isMobileOrTablet ? '' : '50%',
            transform: isMobileOrTablet ? '' : 'translateY(-50%)',
            fontSize: 12,
            mt: isMobileOrTablet ? 1 : 0,
            animation:
              status === 'recording' ? 'blinker 2s linear infinite' : '',
            '@keyframes blinker': {
              '50%': { opacity: 0 },
            },
          }}
        >
          {status}
        </Typography> */}
      </Box>
    </Paper>
  );
}

export default PatientVideoRecorder;

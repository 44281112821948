/** @format */

import * as React from 'react';
import Button from 'widgets/CustomButton/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from 'widgets/Typography/Typography';

interface ConfirmationModalProps {
  message: string;
  openConfirmationModal: boolean;
  handleCloseConfirmationModal: () => void;
}

export default function ConfirmationModal({
  openConfirmationModal,
  handleCloseConfirmationModal,
  message,
}: ConfirmationModalProps) {
  return (
    <Dialog
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent sx={{ pl: 18, pr: 18, pt: 4 }}>
        <DialogContentText
          id='alert-dialog-description'
          sx={{ textAlign: 'center' }}
        >
          <Typography label={message} />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',

          justifyContent: 'center',
        }}
      >
        <Button
          sx={{ backgroundColor: '#1206f9', color: '#fff' }}
          variant='contained'
          onClick={handleCloseConfirmationModal}
          label='Ok'
        />
      </DialogActions>
    </Dialog>
  );
}

/** @format */

import React, { useCallback } from 'react';
import { useState, useEffect, SyntheticEvent } from 'react';
import {
  Grid,
  Autocomplete,
  InputAdornment,
  Checkbox,
  ListItemText,
  IconButton,
  makeStyles,
  AutocompleteChangeReason,
  useMediaQuery,
  List,
  ListItem,
  Box,
} from '@mui/material';
import FlagIcon from 'icons/FlagIcon';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  FlagOption,
  VideoPreviewAdvanceFilterProps,
} from 'types/VideoPreview-types';
import { FLAG_OPTIONS } from 'constants/Flags';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserRoleFilter from './UserRoleFilter';
import { useTranslation } from 'react-i18next';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';

const VideoPreviewAdvanceFilter = ({
  setSelectedFlags,
  selectedFlags,
  setSelectedUserRole,
  selectedUserRole,
}: VideoPreviewAdvanceFilterProps) => {
  const [isFlagClicked, setFlagIsClicked] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const handleSet = useCallback(() => {
    setShowFilters((prevState) => !prevState);
    setIsRotated((prevState) => !prevState);
  }, []);

  const handleFlagChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event> | undefined,
      newValue: FlagOption[] | null
    ) => {
      if (newValue) {
        const isSelectAllSelected =
          Array.isArray(newValue) &&
          newValue.some((option) => option.id === -1);

        if (isSelectAllSelected) {
          setSelectAll((prevState: boolean) => !prevState);
          setSelectedFlags((prevState: FlagOption[]) =>
            !prevState ? FLAG_OPTIONS.slice(1) : []
          );
        } else {
          setSelectedFlags((prevState: FlagOption[]) => {
            const updatedOptions = [...prevState];

            for (const option of newValue) {
              const existingOptionIndex = updatedOptions.findIndex(
                (flag) => flag.id === option.id
              );

              if (existingOptionIndex === -1) {
                updatedOptions.push(option);
              } else {
                updatedOptions.splice(existingOptionIndex, 1);
              }
            }

            return updatedOptions;
          });

          setSelectAll((prevState: boolean) =>
            prevState ? newValue.length === FLAG_OPTIONS.length - 1 : false
          );
        }
      } else {
        setSelectedFlags([]);
        setSelectAll(false);
      }
    },
    []
  );

  const handleFlagFocus = useCallback(() => {
    setFlagIsClicked(true);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: isMobile || isTablet ? 'column' : 'row',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
        gap: 2,
      }}
    >
      {showFilters && (
        <Box
          sx={{
            maxWidth: isMobile || isTablet ? '100%' : '30%',
            width: '100%',
            height: '100%',
          }}
        >
          <UserRoleFilter
            setSelectedUserRole={setSelectedUserRole}
            selectedUserRole={selectedUserRole}
          />
        </Box>
      )}

      {showFilters && (
        <Box
          sx={{
            display: 'flex',
            maxWidth: isMobile || isTablet ? '100%' : '50%',
            width: '100%',
            height: '100%',
            border: '1px solid #1206f9',
            borderRadius: '8px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-10px',
              left: '10px',
              background: '#FFFFFF',
              padding: '0 8px',
            }}
          >
            <Typography
              sx={{
                color: 'rgba(0, 0, 0, 0.6)',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: 400,
                fontSize: '0.7rem',
                lineHeight: '1.4375em',
                letterSpacing: '0.00938em',
                padding: 0,
                position: 'relative',
                display: 'block',
                transformOrigin: 'top left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                left: 0,
                top: 0,
              }}
              label='Flags'
            />
          </Box>

          {FLAG_OPTIONS.map((option) => (
            <ListItem
              key={option.id}
              disablePadding
              onClick={(e) => handleFlagChange(e, [option])}
              onFocus={handleFlagFocus}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                  e.preventDefault();
                }

                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Checkbox
                sx={{
                  color: (theme) =>
                    selectedFlags.some(
                      (flag: FlagOption) => flag.id === option.id
                    ) ||
                    (option.id === -1 && selectAll)
                      ? option.name
                      : theme.palette.grey[600], // Default color for unchecked state
                }}
                checked={
                  selectedFlags.some(
                    (flag: FlagOption) => flag.id === option.id
                  ) ||
                  (option.id === -1 && selectAll)
                }
                style={{
                  color:
                    selectedFlags.some(
                      (flag: FlagOption) => flag.id === option.id
                    ) ||
                    (option.id === -1 && selectAll)
                      ? option.name
                      : '#DFDFDF',
                }}
              />
              <ListItemText sx={{ fontSize: '10px' }} primary={option.label} />
            </ListItem>
          ))}
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          maxWidth:
            isMobile || isTablet ? '100%' : showFilters ? '20%' : '100%',
          width: '100%',
          height: '100%',
        }}
      >
        <Button
          sx={{
            borderRadius: '40px',
            border: '1px solid #1206F9',
            background: '#FFF',
            color: '#1206F9',
            marginLeft: 'auto',
            marginRight: '5px',
          }}
          onClick={handleSet}
          startIcon={
            <FilterListIcon
              sx={{ transform: isRotated ? 'rotate(90deg)' : 'rotate(0deg)' }}
            />
          }
          label='videoPreviewAdvance.Filters'
        />
      </Box>
    </Box>
  );
};

export default VideoPreviewAdvanceFilter;

/** @format */

import React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import RenderMenuList from './RenderMenuList';
import { styled, useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface MobileDrawerProps {
  handleOpenMobileDrawer: () => void;
  handleCloseMobileDrawer: () => void;
  openMobileDrawer: boolean;
}

export default function MobileDrawer({
  handleOpenMobileDrawer,
  handleCloseMobileDrawer,
  openMobileDrawer,
}: MobileDrawerProps) {
  const theme = useTheme();

  return (
    <SwipeableDrawer
      anchor={'top'}
      open={openMobileDrawer}
      onClose={handleCloseMobileDrawer}
      onOpen={handleOpenMobileDrawer}
    >
      <DrawerHeader>
        <IconButton onClick={handleCloseMobileDrawer}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <RenderMenuList open={true} />
    </SwipeableDrawer>
  );
}

import { Box } from '@mui/material';
import React from 'react';
import Typography from 'widgets/Typography/Typography';
import { formatTimeInMinSecs } from 'utils/formatTimeInMinSecs';
import { TranscriptDataArray } from 'types/VideoPreview-types';
import { date } from 'yup';

interface TranscriptComponentProps {
  Data: TranscriptDataArray[];
}

const TranscriptComponent: React.FC<TranscriptComponentProps> = ({ Data }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '85vh',
        p: 3,
        overflow: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Typography
        label='Transcript'
        sx={{ color: '#33344B', fontSize: '16px', fontWeight: 600 }}
      />
      <Typography
        label='Overall'
        sx={{ color: '#605E5E', fontSize: '11px', fontWeight: 400 }}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '20vh',
          mt: 2,
          mb: 2,
          maxHeight: '100%',
          overflow: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {Data.length === 0 ? (
          <Typography
            sx={{
              color: '#000',
              fontSize: '14px',
              fontWeight: 500,
              zIndex: 999,
            }}
            label='Please reprocess session to get Transcript'
          />
        ) : (
          Data.map((item, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'center', mb: 3 }}
            >
              <Typography
                sx={{
                  color: '#605E5E',
                  backgroundColor: '#E0E0E09E',
                  width: 'fit-content',
                  height: 'fit-content',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  borderRadius: '35px',
                  marginRight: '10px',
                }}
                label=''
              >
                {formatTimeInMinSecs(Math.floor(item.start_time_stamp))}
              </Typography>
              <Typography
                sx={{
                  color: '#33344B',
                  fontSize: '14px',
                  fontWeight: 400,
                }}
                label={item.transcription}
              />
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default TranscriptComponent;

import { Box, IconButton, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import OverallSummaryVersion2 from './VideoPreviewAdvanceVersion2/VideoPreviewAdvanceComponents/OverallSummaryVersion2';

import {
  SectionConfig,
  TabsForPromptSectionProps,
} from 'types/VideoPreview-types';

import Loader from 'widgets/Loader/Loader';
import { useAppDispatch } from 'redux/store';

import Button from 'widgets/CustomButton/Button';

import EditSummaryIcon from 'icons/EditSummaryIcon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Toast } from 'widgets/Toast/Toast';
import { usePDF } from 'react-to-pdf';
import {
  GetProcessedDataListForParticipantsVersion2ServiceAPI,
  updateSummaryIframeService,
  updateSummaryService,
} from 'services/VideoPreview-services';

const recoveryPlanKeys = {
  'Immediate Actions': 'immediate_actions',
  'Short-term Goals': 'short_term_goals',
  'Long-term Strategies': 'long_term_strategies',
  'Emotional Support': 'emotional_support',
  'Progress Monitoring': 'progress_monitoring',
} as const;

type RecoveryPlanKey = keyof typeof recoveryPlanKeys;

function TabsForPromptSection({
  selectedSummaryTab,
  setSummaryTab,
  ProcessDataListForParticipantsVersion2,
  selectedPrompt,
  sessionId,
  isIframe,
  apiKey = '',
}: TabsForPromptSectionProps) {
  const dispatch = useAppDispatch();
  const [addMargin, setAddMargin] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'Report.pdf' });
  const [isEditing, setIsEditing] = useState(false);

  const sectionsConfig: SectionConfig[] = [
    {
      label: 'videoPreviewAdvance.PatientOverview',
      rows: 4,
      value:
        `${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.patient_overview?.relevant_info || ''
        }\n` +
        `${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.patient_overview?.medical_history || ''
        }\n` +
        `${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.patient_overview?.current_health_status || ''
        }`,
      onChangeKey: 'patient_overview',
    },
    {
      label: 'videoPreviewAdvance.EnergyLevels',
      rows: 10,
      value:
        `Dominant Emotion: ${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.overall_sentiment?.dominant_emotion || ''
        }\n` +
        `${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.overall_sentiment?.energy_levels || ''
        }\n` +
        `Engagement Score: ${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.overall_sentiment?.engagement_score || ''
        }\n` +
        `Confident Tone: ${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.overall_sentiment?.confidence_fatigue_stress?.confident_tone || ''
        }\n` +
        `Fatigued Tone: ${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.overall_sentiment?.confidence_fatigue_stress?.fatigued_tone || ''
        }\n` +
        `Stressed Tone: ${ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.overall_sentiment?.confidence_fatigue_stress?.stressed_tone || ''
        }`,
      onChangeKey: 'overall_sentiment',
    },
    {
      label: 'videoPreviewAdvance.EmotionalDiscrepancies',
      rows: 4,
      value:
        ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.detailed_tone_analysis?.emotional_discrepancies || '',
      onChangeKey: 'detailed_tone_analysis.emotional_discrepancies',
    },
    {
      label: 'videoPreviewAdvance.StressIndicators',
      rows: 4,
      value: (
        ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.detailed_tone_analysis?.stress_indicators || []
      ).join('\n'),
      onChangeKey: 'detailed_tone_analysis.stress_indicators',
    },
    {
      label: 'videoPreviewAdvance.RecoveryPlan',
      rows: 20,
      value: Object.keys(recoveryPlanKeys)
        .map((sectionTitle) => {

          const key = recoveryPlanKeys[sectionTitle as RecoveryPlanKey];

          const items =
            ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
              ?.recovery_plan?.[key] || [];
          console.log('keykeykey', items);
          return `${sectionTitle}:\n${items.join('\n')}\n`;
        })
        .join('\n'),
      onChangeKey: 'recovery_plan',
    },
    {
      label: 'videoPreviewAdvance.BillableCodewithIdentifiedServices',
      rows: 4,
      value: (
        ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
          ?.medicaid_billable_events?.billable_codes || []
      )
        .map((code, index) => {
          const service =
            ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
              ?.medicaid_billable_events?.identified_services?.[index] || '';
          return `${code} : ${service}`;
        })
        .join('\n'),
      onChangeKey: 'medicaid_billable_events',
    },
  ];



  const [editedContent, setEditedContent] = useState<Record<string, string>>(
    sectionsConfig.reduce((acc, section) => {
      acc[section.onChangeKey] = section.value;
      return acc;
    }, {} as Record<string, string>)
  );

  const [editedOverallSummary, setEditedOverallSummary] = useState(
    ProcessDataListForParticipantsVersion2[0]?.overall_summary || {}
  );

  const [showOriginal, setShowOriginal] = useState(true);

  const detailedSummary =
    ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary;
  const detailedSummaryEdited =
    ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary_edited;

  const data = ProcessDataListForParticipantsVersion2[0]?.is_prompt_section
    ? ProcessDataListForParticipantsVersion2[0]?.prompt_sections[selectedPrompt]
    : ProcessDataListForParticipantsVersion2[0];

  const dataToCopy =
    !showOriginal && detailedSummaryEdited
      ? JSON.stringify(detailedSummaryEdited, null, 2)
      : ProcessDataListForParticipantsVersion2?.[0]?.detailed_summary
        ? JSON.stringify(detailedSummary, null, 2)
        : !showOriginal
          ? JSON.stringify(
            ProcessDataListForParticipantsVersion2?.[0]?.overall_summary
          ) || ''
          : JSON.stringify(
            ProcessDataListForParticipantsVersion2[0]?.detailed_summary_edited ||
            ''
          );

  console.log("ProcessDataListForParticipantsVersion2", editedContent)
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const renderArray = (array: string[]) => {
    return (array || []).map((item, index) => (
      <Typography key={index} label='' sx={{ fontSize: '14px' }}>
        {item}
      </Typography>
    ));
  };

  const handleExport = async () => {
    setAddMargin(true);
    await toPDF();
    setAddMargin(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    if (
      ProcessDataListForParticipantsVersion2.length > 0 &&
      (ProcessDataListForParticipantsVersion2?.[0]?.session_type_id === 58 ||
        ProcessDataListForParticipantsVersion2?.[0]?.session_type_id === 59)
    ) {
      if (isIframe) {
        dispatch(updateSummaryIframeService(sessionId, editedContent, apiKey));
      } else {
        dispatch(updateSummaryService(sessionId, editedContent));
        dispatch(
          GetProcessedDataListForParticipantsVersion2ServiceAPI(sessionId)
        );
      }

      setIsEditing(false);
    } else {
      if (isIframe) {
        dispatch(
          updateSummaryIframeService(sessionId, editedOverallSummary, apiKey)
        );
      } else {
        dispatch(updateSummaryService(sessionId, editedOverallSummary));
        dispatch(
          GetProcessedDataListForParticipantsVersion2ServiceAPI(sessionId)
        );
      }

      setIsEditing(false);
    }
  };
  const handleCancle = () => {
    setIsEditing(false);
  };

  const handleTextFieldChange = (key: string, newValue: string) => {
    setEditedContent((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };

  const handleOverallSummaryChange = (value: string) => {
    setEditedOverallSummary(value);
  };

  const handleCopy = (data: string) => {
    navigator.clipboard.writeText(data).then(
      () => {
        Toast('Content copied successfully!', 'success');
      },
      (err) => {
        Toast('Failed to copy', 'error');
      }
    );
  };

  if (!data) {
    return <Loader />;
  }
  if (!ProcessDataListForParticipantsVersion2) {
    return <Loader />;
  }

  console.log("data.detailed_summary", ProcessDataListForParticipantsVersion2[0]);

  return (
    <Box sx={{ display: 'block', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          minHeight: '10vh',
          maxWidth: isMobile || isTablet ? '100%' : '85vw',
          backgroundColor: '#F9F9FB',
          boxShadow: '0px 4px 50px 0px #65646414',
          justifyContent: 'flex-end',
          gap: 3,
          pr: 5,
        }}
      >
        <Button
          variant='outlined'
          label={isEditing ? 'Cancle' : 'Edit'}
          sx={{
            width: 'auto',
            height: 'auto',
            p: 1,
            color: '#1206F9',
            borderColor: '#1206F9',
            fontSize: '16px',
            textTransform: 'none',
            minWidth: '80px',
          }}
          startIcon={!isEditing && <EditSummaryIcon />}
          onClick={isEditing ? handleCancle : handleEditClick}
        />
        <Button
          variant='contained'
          label={isEditing ? 'Save' : 'Export'}
          sx={{
            width: 'auto',
            height: 'auto',
            p: 1,
            backgroundColor: '#0904F0',
            color: '#FFF',
            fontSize: '16px',
            textTransform: 'none',
            minWidth: '80px',
          }}
          onClick={isEditing ? handleSaveClick : handleExport}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile || isTablet ? 'column' : 'row',
        }}
      >
        {detailedSummaryEdited &&
          Object.values(detailedSummaryEdited).some(
            (value) =>
              value &&
              (typeof value !== 'object' ||
                Object.values(value).some((nestedValue) => nestedValue))
          ) && (
            <Box
              sx={{
                display: 'block',
                width: '100%',

                maxWidth: isMobile || isTablet ? '100%' : '20vw',
                height: '100% !important',
                p: 3,
                gap: 2,
                textAlign: 'left',
              }}
            >
              {detailedSummaryEdited &&
                Object.values(detailedSummaryEdited).some(
                  (value) =>
                    value &&
                    (typeof value !== 'object' ||
                      Object.values(value).some((nestedValue) => nestedValue))
                ) && (
                  <>
                    <Button
                      variant='text'
                      onClick={() => setShowOriginal(true)}
                      label='Mirro AI Original Report'
                      sx={{
                        color: showOriginal ? '#1906F9' : '#444655',
                        textTransform: 'none',
                        width: 'auto',
                        minWidth: '15vw',
                        textAlign: 'left',
                      }}
                    />

                    <Button
                      variant='text'
                      onClick={() => setShowOriginal(false)}
                      disabled={!detailedSummaryEdited}
                      label='Edited'
                      sx={{
                        color: !showOriginal ? '#1906F9' : '#444655',
                        textTransform: 'none',
                        minWidth: '15vw',
                        textAlign: 'left',
                      }}
                    />
                  </>
                )}
            </Box>
          )}
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            p:
              detailedSummaryEdited &&
                Object.values(detailedSummaryEdited).some(
                  (value) =>
                    value &&
                    (typeof value !== 'object' ||
                      Object.values(value).some((nestedValue) => nestedValue))
                )
                ? 3
                : 10,
            maxWidth:
              isMobile || isTablet
                ? '100%'
                : detailedSummaryEdited &&
                  Object.values(detailedSummaryEdited).some(
                    (value) =>
                      value &&
                      (typeof value !== 'object' ||
                        Object.values(value).some((nestedValue) => nestedValue))
                  )
                  ? '70vw'
                  : '80vw',
            wordWrap: 'break-word',
            overflow: 'auto',
          }}
        >
          <Box
            ref={targetRef}
            sx={{
              padding: '20px',
              backgroundColor: '#FFFFFF',
              margin: addMargin ? '15%' : '0',
              gap: 2,
              maxWidth: '100%',
              background: 'transparent',
              overflow: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {ProcessDataListForParticipantsVersion2?.[0]?.session_type_id ===
              58 ||
              ProcessDataListForParticipantsVersion2?.[0]?.session_type_id ===
              59 ? (
                <div>
                  {ProcessDataListForParticipantsVersion2?.[0]
                    ?.detailed_summary ? (
                      <>
                        {isEditing ? (
                          <div>
                            {sectionsConfig.map((section, index) => (
                              <section
                                key={index}
                                style={{ marginTop: 2, marginBottom: 2 }}
                              >
                                <Typography
                                  label={section.label}
                                  sx={{ mt: 1, mb: 1 }}
                                />
                                <TextField
                                  multiline
                                  rows={section.rows}
                                  value={editedContent[section.onChangeKey]}
                                  onChange={(e) =>
                                    handleTextFieldChange(
                                      section.onChangeKey,
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                  variant='outlined'
                                  sx={{ fontSize: '14px' }}
                                />
                              </section>
                            ))}
                          </div>
                        ) : (
                            <>
                              {showOriginal ? (
                                <div>
                                  <section style={{ marginTop: 1, marginBottom: 1 }}>
                                    <Typography
                                      label='videoPreviewAdvance.PatientOverview'
                                      sx={{ fontSize: '15px', fontWeight: 600 }}
                                    ></Typography>
                                    <Typography label='' sx={{ fontSize: '14px' }}>
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.patient_overview
                                          ?.relevant_info
                                      }
                                    </Typography>
                                    <Typography label='' sx={{ fontSize: '14px' }}>
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.patient_overview
                                          ?.medical_history
                                      }
                                    </Typography>
                                    <Typography label='' sx={{ fontSize: '14px' }}>
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.patient_overview
                                          ?.current_health_status
                                      }
                                    </Typography>
                                  </section>

                                  <section style={{ marginTop: 2, marginBottom: 2 }}>
                                    <Typography
                                      label='videoPreviewAdvance.EnergyLevels'
                                      sx={{ fontSize: '15px', fontWeight: 600 }}
                                    ></Typography>
                                    <Typography
                                      label='Dominant Emotion'
                                      sx={{ fontSize: '14px' }}
                                    >
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.overall_sentiment
                                          ?.dominant_emotion
                                      }
                                    </Typography>
                                    <Typography
                                      label='videoPreviewAdvance.EnergyLevels'
                                      sx={{ fontSize: '14px' }}
                                    >
                                      :{' '}
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.overall_sentiment
                                          ?.energy_levels
                                      }
                                    </Typography>
                                    <Typography
                                      label='videoPreviewAdvance.EngagementScore'
                                      sx={{ fontSize: '14px' }}
                                    >
                                      :{' '}
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.overall_sentiment
                                          ?.engagement_score
                                      }
                                    </Typography>
                                    <Typography
                                      label='videoPreviewAdvance.Confident'
                                      sx={{ fontSize: '14px' }}
                                    >
                                      :{' '}
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.overall_sentiment
                                          ?.confidence_fatigue_stress?.confident_tone
                                      }
                                    </Typography>
                                    <Typography
                                      label='videoPreviewAdvance.Fatigued'
                                      sx={{ fontSize: '14px' }}
                                    >
                                      :{' '}
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.overall_sentiment
                                          ?.confidence_fatigue_stress?.fatigued_tone
                                      }
                                    </Typography>
                                    <Typography
                                      label='videoPreviewAdvance.Stressed'
                                      sx={{ fontSize: '14px' }}
                                    >
                                      :{' '}
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.overall_sentiment
                                          ?.confidence_fatigue_stress?.stressed_tone
                                      }
                                    </Typography>
                                  </section>

                                  <section style={{ marginTop: 2, marginBottom: 1 }}>
                                    <Typography
                                      label='videoPreviewAdvance.EmotionalDiscrepancies'
                                      sx={{ fontSize: '15px', fontWeight: 600 }}
                                    ></Typography>
                                    <Typography label='' sx={{ fontSize: '14px' }}>
                                      {
                                        ProcessDataListForParticipantsVersion2?.[0]
                                          ?.detailed_summary?.detailed_tone_analysis
                                          ?.emotional_discrepancies
                                      }
                                    </Typography>
                                  </section>

                                  <section style={{ marginTop: 1, marginBottom: 1 }}>
                                    <Typography
                                      label='videoPreviewAdvance.StressIndicators'
                                      sx={{ fontSize: '15px', fontWeight: 600 }}
                                    ></Typography>
                                    {renderArray(
                                      ProcessDataListForParticipantsVersion2?.[0]
                                        ?.detailed_summary?.detailed_tone_analysis
                                        ?.stress_indicators
                                    )}
                                  </section>

                                  <section style={{ marginTop: 1, marginBottom: 1 }}>
                                    <Typography
                                      label='videoPreviewAdvance.RecoveryPlan'
                                      sx={{ fontSize: '15px', fontWeight: 600 }}
                                    />
                                    {Object.keys(recoveryPlanKeys).map(
                                      (sectionTitle) => {
                                        const key =
                                          recoveryPlanKeys[
                                          sectionTitle as RecoveryPlanKey
                                          ];
                                        const items =
                                          ProcessDataListForParticipantsVersion2[0]?.detailed_summary?.recovery_plan?.[
                                          key
                                          ] || [];

                                        return (
                                          <React.Fragment key={sectionTitle}>
                                            <Typography
                                              label={sectionTitle}
                                              sx={{
                                                fontSize: '15px',
                                                fontWeight: 600,
                                              }}
                                            />
                                            <ul>
                                              {items.map((item, index) => (
                                                <li
                                                  key={index}
                                                  style={{ fontSize: '14px' }}
                                                >
                                                  {item}
                                                </li>
                                              ))}
                                            </ul>
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </section>

                                  <section style={{ marginTop: 1, marginBottom: 1 }}>
                                    <Typography
                                      label='videoPreviewAdvance.BillableCodewithIdentifiedServices'
                                      sx={{ fontSize: '15px', fontWeight: 600 }}
                                    />
                                    <ul>
                                      {(
                                        ProcessDataListForParticipantsVersion2[0]?.overall_summary?.billable_codes || []
                                      ).map((code: any, index: any) => {
                                        const service =
                                          ProcessDataListForParticipantsVersion2[0]?.overall_summary?.identified_services?.[index] || '';
                                        return (
                                          <li
                                            key={index}
                                            style={{ fontSize: '14px' }}
                                          >
                                            {`${code} : ${service}`}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </section>
                                </div>
                              ) : (
                                  <Box
                                    sx={{
                                      maxWidth: '100%',
                                      overflow: 'scroll',
                                      scrollbarWidth: 'none',
                                      msOverflowStyle: 'none',
                                      '&::-webkit-scrollbar': {
                                        display: 'none',
                                      },
                                    }}
                                  >
                                    <section style={{ marginTop: 1, marginBottom: 1 }}>
                                      <Typography
                                        label='videoPreviewAdvance.PatientOverview'
                                        sx={{ fontSize: '15px', fontWeight: 600 }}
                                      />

                                      <Typography label='' sx={{ fontSize: '14px' }}>
                                        <pre>
                                          {detailedSummaryEdited?.patient_overview}
                                        </pre>
                                      </Typography>
                                    </section>

                                    <section style={{ marginTop: 2, marginBottom: 2 }}>
                                      <Typography
                                        label='videoPreviewAdvance.EnergyLevels'
                                        sx={{ fontSize: '15px', fontWeight: 600 }}
                                      />
                                      <Typography label='' sx={{ fontSize: '14px' }}>
                                        <pre>
                                          {detailedSummaryEdited?.overall_sentiment}
                                        </pre>
                                      </Typography>
                                    </section>
                                    {detailedSummaryEdited?.detailed_tone_analysis && (
                                      <section
                                        style={{ marginTop: 2, marginBottom: 1 }}
                                      >
                                        <Typography
                                          label='videoPreviewAdvance.EmotionalDiscrepancies'
                                          sx={{ fontSize: '15px', fontWeight: 600 }}
                                        />
                                        <Typography label='' sx={{ fontSize: '14px' }}>
                                          <pre>
                                            {
                                              detailedSummaryEdited
                                                .detailed_tone_analysis
                                                .emotional_discrepancies
                                            }
                                          </pre>
                                        </Typography>
                                      </section>
                                    )}
                                    {detailedSummaryEdited?.detailed_tone_analysis && (
                                      <section
                                        style={{ marginTop: 2, marginBottom: 1 }}
                                      >
                                        <Typography
                                          label='videoPreviewAdvance.StressIndicators'
                                          sx={{ fontSize: '15px', fontWeight: 600 }}
                                        />
                                        <Typography label='' sx={{ fontSize: '14px' }}>
                                          <pre>
                                            {
                                              detailedSummaryEdited
                                                .detailed_tone_analysis
                                                .stress_indicators
                                            }
                                          </pre>
                                        </Typography>
                                      </section>
                                    )}

                                    <section style={{ marginTop: 1, marginBottom: 1 }}>
                                      <Typography
                                        label='videoPreviewAdvance.RecoveryPlan'
                                        sx={{ fontSize: '15px', fontWeight: 600 }}
                                      />
                                      <Typography label='' sx={{ fontSize: '14px' }}>
                                        <pre>
                                          {detailedSummaryEdited?.recovery_plan}
                                        </pre>
                                      </Typography>
                                    </section>

                                    <section style={{ marginTop: 1, marginBottom: 1 }}>
                                      <Typography
                                        label='videoPreviewAdvance.BillableCodewithIdentifiedServices'
                                        sx={{ fontSize: '15px', fontWeight: 600 }}
                                      />
                                      <Typography label='' sx={{ fontSize: '14px' }}>
                                        <pre>
                                          {
                                            detailedSummaryEdited?.medicaid_billable_events
                                          }
                                        </pre>
                                      </Typography>
                                    </section>
                                  </Box>
                                )}
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {isEditing ? (
                          <div>
                            <TextField
                              multiline
                              rows={30}
                              defaultValue={
                                ProcessDataListForParticipantsVersion2[0]
                                  ?.overall_summary
                              }
                              fullWidth
                              variant='outlined'
                              sx={{ fontSize: '14px' }}
                            />
                          </div>
                        ) : (
                            <Typography label=''>
                              <pre
                                style={{
                                  whiteSpace: 'pre-wrap',
                                  wordWrap: 'break-word',
                                }}
                              >
                                {
                                  ProcessDataListForParticipantsVersion2[0]
                                    ?.overall_summary
                                }
                              </pre>
                            </Typography>
                          )}
                      </>
                    )}
                </div>
              ) : (
                <>
                  {isEditing ? (
                    <div>
                      <TextField
                        multiline
                        rows={30}
                        defaultValue={
                          ProcessDataListForParticipantsVersion2[0]
                            .overall_summary
                        }
                        onChange={(e) =>
                          handleOverallSummaryChange(e.target.value)
                        }
                        fullWidth
                        variant='outlined'
                        sx={{ fontSize: '14px' }}
                      />
                    </div>
                  ) : (
                      <Typography label=''>
                        <pre
                          style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                        >
                          {
                            ProcessDataListForParticipantsVersion2[0]
                              .overall_summary
                          }
                        </pre>
                      </Typography>
                    )}
                </>
              )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: 'auto',
          }}
        >
          <IconButton
            onClick={() => handleCopy(dataToCopy)}
            sx={{
              color: 'inherit',
              width: 'fit-content',
              height: 'fit-content',
              top: 40,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <ContentCopyIcon />
            <Typography label='Copy' sx={{ cursor: 'pointer' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default TabsForPromptSection;

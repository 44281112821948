/** @format */

import React from 'react';

function RecordCamera() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='13'
      viewBox='0 0 20 13'
      fill='none'
    >
      <path
        d='M13.7914 13H2.09651C0.940559 13 -0.00050354 12.2287 -0.00050354 11.2814V1.71864C-0.00050354 0.771265 0.940559 0 2.09651 0H13.7914C14.9473 0 15.8884 0.771265 15.8884 1.71864V11.3034C15.8882 12.2288 14.9473 12.9999 13.7912 12.9999L13.7914 13ZM2.09651 1.10169C1.69331 1.10169 1.34373 1.36605 1.34373 1.71864V11.3034C1.34373 11.6338 1.66629 11.9203 2.09651 11.9203H13.7914C14.1946 11.9203 14.5441 11.656 14.5441 11.3034L14.544 1.71864C14.544 1.3882 14.2214 1.10169 13.7912 1.10169H2.09651Z'
        fill='#817C7C'
      />
      <path
        d='M18.7947 10.9201C18.5284 10.9201 18.2622 10.8233 18.02 10.6297L15.2608 8.28186V4.60277L18.02 2.25495C18.3348 1.96455 18.8429 1.89196 19.2544 2.08544C19.69 2.2791 19.9563 2.69055 19.9563 3.17459V9.73395C19.9563 10.1939 19.69 10.6295 19.2785 10.8231C19.1335 10.8959 18.964 10.92 18.7947 10.92V10.9201ZM16.471 7.72502L18.7703 9.68552V3.22299L16.471 5.18349V7.72502Z'
        fill='#817C7C'
      />
    </svg>
  );
}

export default RecordCamera;

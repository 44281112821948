/** @format */

import React, { useState, useEffect, useCallback } from 'react';
import Paper from '@mui/material/Paper';

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material';
import ThumbsUpIcon from 'icons/ThumbsUpIcon';

import { useAppSelector } from 'redux/store';
import Loader from 'widgets/Loader/Loader';
import {
  AttendeeRecord,
  CoachingMoment,
  FlagOption,
  TimeSegment,
  VideoData,
  knowledge_tips,
  SelectedUserRoleTypes,
  SummaryDetailsProps,
} from 'types/VideoPreview-types';
import { getEmotionTypeSelector } from 'redux/selectors/selectors';
import { useDispatch } from 'react-redux';

import TipsIcon from 'icons/TipsIcon';
import Slider, {
  SliderThumb,
  SliderValueLabelProps,
} from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from 'widgets/Typography/Typography';
import { marks } from 'constants/videoPreview';
import DisabledBadge from 'icons/DisabledBadge';
import EngagementBadge from 'icons/EngagementBadge';
import EnergyBadge from 'icons/EnergyBadge';
import PositivityBadge from 'icons/PositivityBadge';
import VariationBadge from 'icons/VariationBadge';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement='top' title={value}>
      <Typography
        sx={{
          color: '#1206F9',
          fontFamily: 'Poppins',
          fontSize: '9.882px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          letterSpacing: '-0.109px',
        }}
        label=''
      >
        {' '}
        {children}
      </Typography>
    </Tooltip>
  );
}

const CustomSlider = styled(Slider)({
  color: `linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(242,214,13,1) 50%, rgba(0,255,0,1) 100%)`,
  height: 8,
  marginTop: 8,

  '& .MuiSlider-rail': {
    opacity: 1,
    background: '#344BFD',
  },
  '&:hover': {
    cursor: 'default',
  },
  '& .MuiSlider-thumb': {
    width: 8,
    height: 8,
    color: '#fff',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',

    '&:before': {
      boxShadow: 'none',
    },

    '&:hover': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-track': {
    background: `linear-gradient(to right,
                rgba(0, 0, 255, 1) 0%, 
                rgba(0, 0, 255, 1) 100%
              )`,

    width: 8,
    height: 8,
  },
  '& .MuiSlider-track[data-index="50"]': {
    background: '#000',
    width: 10,
    height: '100%',
  },

  '& .MuiSlider-track[data-index="75"]': {
    background: '#1206F9',
    width: 10,
    height: '100%',
  },

  '& .MuiSlider-mark': {
    background: '#1206f9',
    width: 1,
    height: '50%',
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    top: 0,
    backgroundColor: 'unset',
    color: '#1306f9',
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '-0.069px',
  },
});

function isTimeSegment(segment: TimeSegment | null): segment is TimeSegment {
  return segment !== null && segment.sno !== undefined;
}

function OverallSummaryDetailsVersion2({
  processDataListForParticipants,
  selectedUserRole,
  selectedFlags,
  selectedPrompt,
}: SummaryDetailsProps) {
  const [loading, setLoading] = useState(true);
  const [filteredRoleData, setFilteredRoleData] = useState<
    Array<AttendeeRecord>
  >([]);
  const [filteredSegments, setFilteredSegments] = useState<Array<TimeSegment>>(
    []
  );
  const [kudosCount, SetKuodosCount] = useState(0);
  const [tipsCount, SetTipsCount] = useState(0);
  const videoData = processDataListForParticipants[0];
  const [newScores, setNewScores] = useState([
    { label: 'Fatigued Tone %', value: 25, color: ' #D62D2D' },
    {
      label: 'Stressed Tone %',
      value: 60,
      color: '#F6D41F',
    },
    {
      label: 'Confident Tone %',
      value: 15,
      color: '#62C960',
    },
  ]);

  const { t } = useTranslation();

  const attendeesRecord =
    processDataListForParticipants[0]?.attendees_record || [];

  useEffect(() => {
    if (processDataListForParticipants) {
      setLoading(false);
    }
  }, [processDataListForParticipants]);

  useEffect(() => {
    if (
      filteredRoleData.length === 0 &&
      processDataListForParticipants.length > 0
    ) {
      setNewScores([
        {
          label: `${t('dashboard.StressedTone')} %`,
          value: processDataListForParticipants[0].stressed_tone,
          color: '#D62D2D',
        },
        {
          label: `${t('dashboard.FatiguedTone')} %`,
          value: processDataListForParticipants[0].fatigued_tone,
          color: '#F6D41F',
        },
        {
          label: `${t('dashboard.ConfidentTone')} %`,
          value: processDataListForParticipants[0].confident_tone,
          color: '#62C960',
        },
      ]);
    } else if (filteredRoleData.length > 0) {
      setNewScores([
        {
          label: `${t('dashboard.StressedTone')} %`,
          value: filteredRoleData[0].stressed_tone,
          color: '#D62D2D',
        },
        {
          label: `${t('dashboard.FatiguedTone')} %`,
          value: filteredRoleData[0].fatigued_tone,
          color: '#F6D41F',
        },
        {
          label: `${t('dashboard.ConfidentTone')} %`,
          value: filteredRoleData[0].confident_tone,
          color: '#62C960',
        },
      ]);
    }
  }, [filteredRoleData, processDataListForParticipants]);

  const { EmotionTypeList } = useAppSelector(getEmotionTypeSelector);

  useEffect(() => {
    if (!videoData) {
      return;
    }

    let tempKnowledgeTipsArray: knowledge_tips[] = [];

    for (const coachingMoment of videoData.coaching_moments) {
      const knowledgeTips = coachingMoment.knowledge_tips;

      if (knowledgeTips.length > 0) {
        tempKnowledgeTipsArray = [...tempKnowledgeTipsArray, ...knowledgeTips];

        break;
      }
    }
  }, [videoData]);

  const segments = videoData?.time_segments;

  useEffect(() => {
    const filteredSegments = selectedUserRole.map(
      (role: SelectedUserRoleTypes) => {
        return attendeesRecord[role.index];
      }
    );

    setFilteredRoleData(filteredSegments);
  }, [selectedUserRole, processDataListForParticipants, attendeesRecord]);

  const updateSpeakerTimeSegments = useCallback(() => {
    setFilteredSegments((prevFilteredSegments) => {
      const filteredByFlags: TimeSegment[] = segments.filter(
        (segment: TimeSegment) =>
          selectedFlags.some(
            (selectedFlag: FlagOption) => selectedFlag.id === segment.flag_id
          )
      );

      if (selectedUserRole.length > 0) {
        const newFilteredSegments: TimeSegment[] = (
          selectedUserRole.flatMap((role: SelectedUserRoleTypes) => {
            const selectedAttendee = attendeesRecord[role.index];
            if (selectedAttendee) {
              return filteredByFlags.flatMap((segment: TimeSegment | null) =>
                selectedAttendee.time_segment_list.includes(segment?.sno || 0)
                  ? { ...segment, updatedValue: 'yourUpdatedValue' }
                  : null
              );
            }
            return [];
          }) as (TimeSegment | null)[]
        ).filter(
          (segment: TimeSegment | null): segment is TimeSegment =>
            segment !== null
        );

        const sortedNewFilteredSegments: TimeSegment[] =
          newFilteredSegments.sort(
            (a, b) => (a?.start_time_stamp || 0) - (b?.start_time_stamp || 0)
          );

        return sortedNewFilteredSegments;
      }

      const sortedFilteredByFlags = filteredByFlags.sort(
        (a, b) => a.start_time_stamp - b.start_time_stamp
      );

      return sortedFilteredByFlags;
    });
  }, [attendeesRecord, selectedUserRole, selectedFlags, segments]);

  useEffect(() => {
    if (segments) {
      updateSpeakerTimeSegments();
    }
  }, [segments, updateSpeakerTimeSegments]);

  useEffect(() => {
    let tipCount = 0;
    let kudosCountTemp = 0;

    if (!videoData?.coaching_moments) {
      return;
    }

    filteredSegments.map((item: TimeSegment) => {
      if (item.flag_id === 3) kudosCountTemp++;
      return null;
    });

    const startStampsInFilteredSegments = filteredSegments.map(
      (segment: TimeSegment) => segment.start_time_stamp
    );

    const knowledgeTipsWithMedia = videoData.coaching_moments.map(
      (item: CoachingMoment) => {
        if (startStampsInFilteredSegments.includes(item.delivery_time_stamp)) {
          tipCount++;

          const filteredTips = item.knowledge_tips.filter(
            (tip: knowledge_tips) => tip.media_link !== null
          );
          tipCount += filteredTips.length;

          return filteredTips;
        } else {
          return [];
        }
      }
    );

    SetTipsCount(tipCount);
    SetKuodosCount(kudosCountTemp);
  }, [filteredSegments, videoData]);

  return (
    <Box sx={{ wdith: '100%', height: '100%', p: 1 }}>
      {filteredRoleData.length === 0 || filteredRoleData.length >= 2 ? (
        <Box sx={{ wdith: '100%', height: 'auto', justifyContent: 'center' }}>
          <Box>
            <Paper
              sx={{
                width: '95%',
                height: '100%',
                border: '1px solid #D4D4D4',
                background: '#FFF',
                margin: '5px',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '20px',
              }}
              elevation={1}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <img
                    src={`./comonImg/Emoticons-mirro-Default-en/${processDataListForParticipants[0]?.overall_emotion_name}.png`}
                    alt='OverallEmotions'
                    className='overallEmotionImg'
                  />
                </Box>
                <Box
                  sx={{ width: '50%', alignItems: 'left', textAlign: 'left' }}
                >
                  <Typography
                    sx={{
                      color: 'var(--Body-Text-Light, #605E5E)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.132px',
                    }}
                    label='Overall Emotion'
                  >
                    <Typography
                      sx={{
                        color: 'var(--Body-Text, #33344B)',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.176px',
                      }}
                      label={
                        processDataListForParticipants[0]?.overall_emotion_name
                      }
                    />
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'block',
                  width: '100%',
                  height: 'fit-content',
                  justifyItems: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Engagement'
                  />

                  {processDataListForParticipants[0]?.engagement_score >= 75 ? (
                    <EngagementBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='engagement_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={processDataListForParticipants[0]?.engagement_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='positivityBox'>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Positivity'
                  />

                  {processDataListForParticipants[0]?.positivity_score >= 75 ? (
                    <PositivityBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='positivity_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={processDataListForParticipants[0]?.positivity_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='energyBox'>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Energy'
                  />
                  {processDataListForParticipants[0]?.overall_energy >= 75 ? (
                    <EnergyBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='overall_energy-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={processDataListForParticipants[0]?.overall_energy}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>

              <Box className='variationBox'>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Variation'
                  />

                  {processDataListForParticipants[0]?.variation_score >= 75 ? (
                    <VariationBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='positivity_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={processDataListForParticipants[0]?.variation_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Box sx={{ wdith: '100%', height: 'auto', justifyContent: 'center' }}>
          <Box
            sx={{
              width: '100%',
              alignItems: 'left',
              textAlign: 'left',
              ml: 1,
            }}
          >
            <Typography
              sx={{
                color: '#6C6C6C',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
                letterSpacing: '-0.154px',
              }}
              label={selectedUserRole[0]?.name}
            ></Typography>
          </Box>
          <Box>
            <Paper
              sx={{
                width: '95%',
                height: 'fit-content',
                border: '1px solid #D4D4D4',
                background: '#FFF',
                margin: '5px',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '20px',
              }}
              elevation={1}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <img
                    src={`./comonImg/Emoticons-mirro-Default-en/${filteredRoleData[0]?.overall_emotion_name}.png`}
                    alt='OverallEmotions'
                    className='overallEmotionImg'
                  />
                </Box>
                <Box
                  sx={{ width: '50%', alignItems: 'left', textAlign: 'left' }}
                >
                  <Typography
                    sx={{
                      color: 'var(--Body-Text-Light, #605E5E)',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.132px',
                    }}
                    label='Overall Emotion'
                  >
                    <Typography
                      sx={{
                        color: 'var(--Body-Text, #33344B)',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.176px',
                      }}
                      label={filteredRoleData[0]?.overall_emotion_name}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'block',
                  width: '100%',
                  height: 'fit-content',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Engagement'
                  />
                  {filteredRoleData[0]?.total_score >= 75 ? (
                    <EngagementBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='engagement_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={filteredRoleData[0]?.total_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='positivityBox'>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Positivity'
                  />
                  {filteredRoleData[0]?.avg_positivity_score >= 75 ? (
                    <PositivityBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='positivity_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={filteredRoleData[0]?.avg_positivity_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
              <Box className='energyBox'>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Energy'
                  />

                  {filteredRoleData[0]?.avg_energy >= 75 ? (
                    <EnergyBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='overall_energy-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={filteredRoleData[0]?.avg_energy}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>

              <Box className='variationBox'>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    label='Variation'
                  />
                  {filteredRoleData[0]?.variation_score >= 75 ? (
                    <VariationBadge />
                  ) : (
                    <DisabledBadge />
                  )}
                </Box>
                <Box sx={{ pt: 1, p: 0.5 }}>
                  <CustomSlider
                    aria-label='positivity_score-indicator'
                    size='small'
                    min={0}
                    step={1}
                    max={100}
                    track={false}
                    marks={marks}
                    value={filteredRoleData[0]?.variation_score}
                    valueLabelDisplay='on'
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: -4,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='0'
                    />

                    <Typography
                      sx={{
                        color: '#000',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.069px',
                      }}
                      label='100'
                    />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
      <Box>
        <Paper
          sx={{
            width: '95%',
            height: 'fit-content',
            border: '1px solid #D4D4D4',
            background: '#FFF',
            margin: '5px',
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '20px',
          }}
          elevation={1}
        >
          <Box
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              alignItems: 'center',
              textAlign: 'center',

              mt: 1,
              mb: 1,
            }}
          >
            <PieChart
              series={[
                {
                  data: newScores.filter((item) => item.value !== 0),
                  outerRadius: 80,
                },
              ]}
              margin={{ left: 90 }}
              width={180}
              height={160}
              slotProps={{ legend: { hidden: true } }}
            />
          </Box>
          <Box
            sx={{
              display: 'block',

              top: 0,
            }}
          >
            {newScores
              .filter((item) => item.value !== 0) // Filter out items with value 0
              .map((item, index) => (
                <Box
                  key={item.label}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left', // Center items vertically
                    justifyContent: 'left',
                    textAlign: 'left',
                    gap: '15px',
                    marginBottom: '5px', // Add margin bottom for separation between items
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: item.color,
                      width: '17px',
                      height: '15px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#555',
                      lineHeight: '15px',
                      fontWeight: 400,
                    }}
                    label={item.label}
                  >
                    : {item.value}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Paper>
      </Box>
      {/* <Box>
        <Paper
          sx={{
            width: '95%',
            height: 'fit-content',
            border: '1px solid #D4D4D4',
            margin: '5px',
            background: '#FFF',
            p: 1,
            gap: '10px',
          }}
          elevation={1}
        >
          <Box className='OtherDetails'>
            <Typography label='videoPreviewAdvance.Kudos'>
              &nbsp;
              <ThumbsUpIcon />
            </Typography>
            <Avatar
              sx={{
                marginLeft: 'auto',
                marginRight: '10px',
                backgroundColor: '#EEF1FF',
                color: '#1306f9',
                width: 'auto',
                height: 'auto',
                minWidth: '30px',
                minHeight: '30px',
                border: '2px solid #CED7FF',
              }}
            >
              <Typography sx={{ color: '#1306f9', fontSize: '12px' }} label=''>
                {kudosCount}
              </Typography>
            </Avatar>
          </Box>
          <Box className='OtherDetails'>
            <Typography label='Tips'>
              <TipsIcon />
            </Typography>
            <Avatar
              sx={{
                marginLeft: 'auto',
                marginRight: '10px',
                backgroundColor: '#EEF1FF',
                color: '#1306f9',
                width: 'auto',
                height: 'auto',
                minWidth: '30px',
                minHeight: '30px',
                border: '2px solid #CED7FF',
              }}
            >
              <Typography sx={{ color: '#1306f9', fontSize: '12px' }} label=''>
                {tipsCount}
              </Typography>
            </Avatar>
          </Box>
        </Paper>
      </Box> */}
    </Box>
  );
}
export default OverallSummaryDetailsVersion2;

/** @format */

import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from 'widgets/Loader/Loader';
import Typography from 'widgets/Typography/Typography';
import TeleHealthProcessDataListTable from './TeleHealthProcessDataListTable';
import { useLocation } from 'react-router-dom';
import Wrapper from 'components/MirroSandbox/SandboxUpload/Wrapper';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getRecoveryDashboardDataService } from 'services/RecoveryServices/RecoveryDashboard-service';
import { getRecoveryDashboardDataSelector } from 'redux/selectors/selectors';
interface Params {
  [key: string]: string;
}
function TeleHealthDashboardComponent() {
  const [apiKey, setApiKey] = useState('');
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  useEffect(() => {
    const { apikey: newApiKey } = params;

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    return () => {};
  }, [params]);

  useEffect(() => {
    if (apiKey) {
      dispatch(getRecoveryDashboardDataService(apiKey));
    }
  }, [apiKey]);

  const { DashboardData } = useAppSelector(getRecoveryDashboardDataSelector);

  return (
    <Wrapper>
      {loader ? (
        <Loader />
      ) : (
        <Box>
          <Typography
            sx={{
              color: '#2C2C2C',
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '-0.198px',
              marginTop: 2,
              marginLeft: 2,
            }}
            label='dashboard.Dashboard'
          />
          <TeleHealthProcessDataListTable TableReceivedData={DashboardData} />
        </Box>
      )}
    </Wrapper>
  );
}

export default TeleHealthDashboardComponent;

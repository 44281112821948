/** @format */

import fixWebmDuration from 'fix-webm-duration';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { AudioRecorderState } from 'types/Record-types';
import {
  getSessionTypesServiceApi,
  UploadSingleFileService,
} from 'services/UploadMedia-services';
import { getDefaultVideoDescription } from 'utils/getDefaultVideoDescription';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { featureFlagList } from 'constants/FeatureFlags';
import { getAIPermissionsSelector } from 'redux/selectors/selectors';

function useIosRecord() {
  const dispatch = useAppDispatch();
  const { isFeatureEnable } = useFeatureFlag();
  const { getAIPermissions } = useAppSelector(getAIPermissionsSelector);

  const [
    openRecordedSessionUploadCompleteModal,
    setOpenRecordedSessionUploadCompleteModal,
  ] = useState(false);
  const [recordingType, setRecordingType] = useState<{
    id: number;
    name: string;
  }>({ id: 1, name: 'Video' });
  const [videoDesc, setVideoDesc] = useState<string>(
    getDefaultVideoDescription()
  );

  const [selectedSessionType, setSelectedSessionType] = useState<{
    id: number;
    name: string;
  }>({
    id: 3,
    name: 'Casual Session',
  });

  const [openAfterRecordConfirmModal, setOpenAfterRecordConfirmModal] =
    useState(false);

  const [state, setState] = useState<AudioRecorderState>({
    recorder: null,
    gumStream: null,
    audioBlobUrl: null,
  });

  const [totalSeconds, setTotalSeconds] = useState<number>(0);

  const [isRunning, setIsRunning] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);
  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTotalSeconds(totalSeconds + 1);
      }, 1000);
      if (totalSeconds === 600) {
        stopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);
  useEffect(() => {
    dispatch(getSessionTypesServiceApi(-1));
  }, [dispatch]);

  function handleCloseRecordedSessionUploadCompleteModal() {
    setOpenRecordedSessionUploadCompleteModal(false);
  }
  function handleOpenRecordedSessionUploadCompleteModal() {
    setOpenRecordedSessionUploadCompleteModal(true);
  }

  const handleOpenAfterRecordConfirmModal = () => {
    setOpenAfterRecordConfirmModal(true);
    stopRecording();
  };

  const handleCloseAfterRecordConfirmModal = () => {
    setOpenAfterRecordConfirmModal(false);
    setState({
      recorder: null,
      gumStream: null,
      audioBlobUrl: null,
    });
    resetTimer();
  };

  const resetTimer = useCallback(() => {
    setIsRunning(false);
    setTotalSeconds(0);
  }, []);

  const startTimer = useCallback(() => {
    if (!isRunning) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRunning) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const startRecordingInternal = useCallback(async () => {
    startTimer();
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const newRecorder = new MediaRecorder(stream);
      const chunks: Blob[] = [];

      newRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      newRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: 'audio/mp3' });
        const audioBlobUrl = URL.createObjectURL(audioBlob);
        setState((prevState) => ({
          ...prevState,
          audioBlobUrl,
        }));
      };

      newRecorder.start();
      setState((prevState) => ({
        ...prevState,
        recorder: newRecorder,
        gumStream: stream,
      }));
    } catch (error) {}
  }, [startTimer]);

  const stopRecording = useCallback(() => {
    const { recorder, gumStream } = state;

    if (
      (recorder && recorder.state === 'recording') ||
      (recorder && recorder.state === 'paused')
    ) {
      recorder.stop();
      gumStream?.getTracks().forEach((track) => track.stop());
      pauseTimer();
    }
  }, [state, pauseTimer]);

  const handleUploadComplete = useCallback(() => {
    resetTimer();
    setState({
      recorder: null,
      gumStream: null,
      audioBlobUrl: null,
    });
    handleOpenRecordedSessionUploadCompleteModal();
  }, [resetTimer, setState, handleOpenRecordedSessionUploadCompleteModal]);

  const handleUploadAudioWithDurationFIx = useCallback(async () => {
    const videoAnalysisEnabled = false;
    // selectedSessionType.id === 59 ? getAIPermissions.video_analysis :
    const textAnalysisEnabled =
      selectedSessionType.id === 59 ? getAIPermissions.text_analysis : false;

    handleCloseAfterRecordConfirmModal();
    if (state.audioBlobUrl) {
      try {
        const response = await fetch(state.audioBlobUrl);
        const blob = await response.blob();
        fixWebmDuration(blob, totalSeconds * 1000, function (fixedBlob) {
          const url = URL.createObjectURL(fixedBlob);
          dispatch(
            UploadSingleFileService(
              url,
              selectedSessionType.id,
              videoDesc,
              recordingType,
              handleUploadComplete,
              isFeatureEnable(featureFlagList.dynamicPrediction),
              videoAnalysisEnabled,
              textAnalysisEnabled
            )
          );
        });
      } catch (error) {}
    }
  }, [
    handleCloseAfterRecordConfirmModal,
    state,
    totalSeconds,
    dispatch,
    selectedSessionType.id,
    videoDesc,
    recordingType,
    handleUploadComplete,
  ]);

  return {
    videoDesc,
    selectedSessionType,
    recordingType,
    state,
    openRecordedSessionUploadCompleteModal,
    openAfterRecordConfirmModal,
    setVideoDesc,
    setSelectedSessionType,
    setRecordingType,
    handleOpenAfterRecordConfirmModal,
    startRecordingInternal,
    handleCloseRecordedSessionUploadCompleteModal,
    handleCloseAfterRecordConfirmModal,
    handleUploadAudioWithDurationFIx,
    handleOpenRecordedSessionUploadCompleteModal,
    startTimer,
    pauseTimer,
  };
}

export default useIosRecord;

/** @format */

import React from 'react';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';
import Button from 'widgets/CustomButton/Button';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { Toast } from 'widgets/Toast/Toast';
import { useHistory } from 'react-router-dom';
import GoogleIcon from 'icons/GoogleIcon';
import Loader from 'widgets/Loader/Loader';
import { handleSignUpGoogleSuccessServiceApi } from 'services/GoogleSSO-service';
import { googleSSOSelector } from 'redux/selectors/selectors';
import { googleSSOLoad } from 'redux/slices/GoogleSSO-slice';
import { useTranslation } from 'react-i18next';

function GoogleSignupButton() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const login = useGoogleLogin({
    onSuccess: (response) =>
      dispatch(handleSignUpGoogleSuccessServiceApi(response, history)),
    onError: (error) => handleSignupGoogleFailed(error),
  });

  const { isLoadingGoogleSSO } = useAppSelector(googleSSOSelector);

  function handleSignupGoogleFailed(
    error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>
  ) {
    {
      Toast(t('toastMessageNotifications.GoogleAuthenticationFailed'), 'error');
    }
  }

  return isLoadingGoogleSSO ? (
    <Loader />
  ) : (
    <Button
      onClick={() => {
        dispatch(googleSSOLoad());
        login();
      }}
      startIcon={<GoogleIcon />}
      id='btnSignupgoogle'
      variant='outlined'
      sx={{
        textTransform: 'capitalize',
        width: '100%',
        height: '100%',
        padding: 2,
        borderRadius: '25px',
      }}
      label=' Google'
    />
  );
}

export default GoogleSignupButton;

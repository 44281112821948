/** @format */
import * as React from 'react';
import Button from 'widgets/CustomButton/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from 'widgets/Typography/Typography';

interface DialogModalProps {
  onClickCancelButton: () => void;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  dialogModalTitle: string;
  dialogModalBody: string;
  openDialogModal: boolean;
  onClickConfirmButton: () => void;
}

export default function DialogModal({
  onClickCancelButton,
  confirmButtonLabel,
  cancelButtonLabel,
  dialogModalTitle,
  dialogModalBody,
  openDialogModal,
  onClickConfirmButton,
}: DialogModalProps) {
  return (
    <div>
      <Dialog
        open={openDialogModal}
        onClose={onClickCancelButton}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle sx={{ padding: 2 }} id='responsive-dialog-title'>
          <Typography label={dialogModalTitle} />
        </DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText>{dialogModalBody}</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}
        >
          <Button
            variant='contained'
            sx={{ backgroundColor: '#1206f9', color: '#fff' }}
            onClick={onClickConfirmButton}
            autoFocus
            label={confirmButtonLabel}
          />

          <Button
            variant='contained'
            sx={{ backgroundColor: '#1206f9', color: '#fff' }}
            autoFocus
            onClick={onClickCancelButton}
            label={cancelButtonLabel}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

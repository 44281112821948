/** @format */

import {
  Toolbar,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteBulkInactiveIcon from 'icons/DeleteBulkInactiveIcon';
import DeleteBulkActiveIcon from 'icons/DeleteBulkActiveIcon';
import DeleteModal from 'widgets/DeleteModal/DeleteModal';

import GlobalSearchIcon from 'icons/GlobalSearchIcon';
import { TeleHealthTableToolbarProps } from 'types/TeleHealth/RecoveryDashboard-types';

function TeleHealthTableToolbar({
  SearchFilter,
  handleSearchOnchange,
  selectedRows,
}: TeleHealthTableToolbarProps) {
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const [isActive, setIsActive] = useState<Boolean>(false);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selectedRows]);

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  function handleDelete() {
    try {
      const queryParams = new URLSearchParams();

      selectedRows.forEach((id: number) => {
        queryParams.append('id', String(id));
      });
      const params = String(queryParams);
      return params;
    } catch (error) {}
  }

  const handleDeleteProcessVideo = () => {
    handleCloseDeleteModal();
  };

  return (
    <Toolbar
      sx={{
        p: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: '1 1 100%',
          color: '#2C2C2C',
          fontFamily: 'Poppins',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.198px',
        }}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        {t('dashboard.MyRecordings')}
      </Typography>

      <TextField
        sx={{
          backgroundColor: '#FAFAFA',
          width: '100%',
          maxWidth: '250px',
          margin: 1,
          textAlign: 'center',
          borderRadius: '66px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '66px',
            paddingLeft: 2,
            '& fieldset': {
              borderRadius: '66px',
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <GlobalSearchIcon />
            </InputAdornment>
          ),
        }}
        size='small'
        placeholder='Search Sessions'
        value={SearchFilter}
        onChange={handleSearchOnchange}
      />
      <Button
        variant='outlined'
        startIcon={
          isActive ? <DeleteBulkActiveIcon /> : <DeleteBulkInactiveIcon />
        }
        sx={{
          '&.Mui-disabled': {
            color: '#605E5E',
            borderColor: '#605E5E',
          },
          borderRadius: '25px',
          color: '#EA3323',
          border: '1px solid #EA3323',
          fontWeight: 600,
          width: '100%',
          maxWidth: 115,
          marginRight: 2,
        }}
        id='basic-button'
        aria-haspopup='true'
        onClick={handleOpenDeleteModal}
        disabled={selectedRows.length === 0}
      >
        {t('Delete')}
      </Button>

      <DeleteModal
        openDeleteModal={openDeleteModal}
        onCloseDeleteModal={handleCloseDeleteModal}
        onConfirmDelete={handleDeleteProcessVideo}
        onCancelDelete={handleCloseDeleteModal}
      />
    </Toolbar>
  );
}

export default TeleHealthTableToolbar;

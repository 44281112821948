/** @format */

import * as React from 'react';
import Button from 'widgets/CustomButton/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from 'react-i18next';
import Typography from 'widgets/Typography/Typography';
import { SessionChangeModalProps } from 'types/VideoPreview-types';

export default function SessionChangeModal({
  setShow,
  setReprocess,
  handleYesReprocess,
}: SessionChangeModalProps) {
  const [open, setOpen] = React.useState(true);

  const handleResponse = React.useCallback(() => {
    setOpen(false);
    setShow(false);
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleResponse}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent>
          <DialogContentText>
            <Typography label='videoPreviewAdvance.FileWillBeReprocessed' />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleYesReprocess}
            autoFocus
            label='videoPreviewAdvance.Yes'
          />

          <Button
            onClick={handleResponse}
            autoFocus
            label='videoPreviewAdvance.No'
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

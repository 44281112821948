/** @format */

import { useMediaQuery, Box, Paper, Checkbox } from '@mui/material';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { OpenRoutes } from 'routes/routes';
import { RedirectUrls } from 'routes/urls';
import { SignUpSchema } from 'schema/Auth/Auth';
import Footer from 'shared/Footer/Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useAuth from 'hooks/useAuth';
import { getAuthProviderDetailsSelector } from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getAuthProviderDetailsServiceApi } from 'services/Auth-service';
import env from 'envConfig';
import GoogleSignupButton from 'components/SSO-Google/GoogleSignupButton';

import MicrosoftSignInButton from 'components/MicrosoftLoginSSO/MicrosoftSignInButton';
import Button from 'widgets/CustomButton/Button';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import FacebookSigninButton from 'components/SSO/Facebook/FacebookSigninButton';
import { featureFlagList } from 'constants/FeatureFlags';
import { SignUpFormProps } from 'types/Auth/Auth-types';

function SignUpForm({
  handleSignUpFormSubmit,
  signUpStatus,
  authProviderDetailsList,
}: SignUpFormProps) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');
  const { t } = useTranslation();
  const history = useHistory();
  const { isFeatureEnable } = useFeatureFlag();

  const initialValuesSignUp = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
    captcha: '',
    authProvider: authProviderDetailsList['Personal'],
  };

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : isTablet ? '70%' : '50%',
        minHeight: '65vh',
        height: '100%',
        zIndex: 1,
        ml: -1,
        mt: -2,
        mb: 1,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          height: 'fit-content',
          borderRadius: '6px',
          padding: 5,
          border: '2px solid #D4D4D4',
          pb: 8,
        }}
      >
        <Formik
          initialValues={initialValuesSignUp}
          validationSchema={SignUpSchema(t, isFeatureEnable)}
          onSubmit={handleSignUpFormSubmit}
        >
          {({ setFieldValue }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '60vh',
                  height: '100%',
                  justifyContent: 'space-around',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: isMobile || isTablet ? 'column' : 'row',
                      gap: isMobile || isTablet ? 2 : 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: '#33344B',
                        fontFamily: 'Poppins',
                        textAlign: 'center',
                        fontStyle: 'normal',
                      }}
                      label='signuppage.ExistingAccountOrHaveAMicrosoftAccount'
                    />

                    <Typography
                      onClick={() => history.push(OpenRoutes.signInRoute)}
                      id='signupRedirect'
                      sx={{
                        color: '#1206F9',
                        fontWeight: ' 600',
                        lineHeight: '22px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                      label='signuppage.Signin'
                    />
                  </Box>
                  <Typography
                    sx={{ textAlign: 'center' }}
                    label='signuppage.Or'
                  />

                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#33344b',
                      fontFamily: 'Poppins-SemiBold, Helvetica',
                      fontSize: '16px',
                      fontWeight: 700,
                      letterSpacing: '0',
                      lineHeight: '23px',
                      marginBottom: '16px',
                      whiteSpace: 'nowrap',
                    }}
                    label='signuppage.CreateAccount'
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      width: '100%',
                      flexDirection: isMobile || isTablet ? 'column' : 'row',
                    }}
                  >
                    <Field
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      as={TextField}
                      variant='outlined'
                      name='firstName'
                      fullWidth
                      helperText={
                        <ErrorMessage
                          name='firstName'
                          component='div'
                          className='error-message'
                        />
                      }
                      label='signuppage.FirstName'
                      InputProps={{
                        style: {
                          borderRadius: '35px',
                        },
                      }}
                      id='txtfirstname'
                      size='small'
                      sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
                    />
                    <Field
                      as={TextField}
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      variant='outlined'
                      name='lastName'
                      fullWidth
                      helperText={
                        <ErrorMessage
                          name='lastName'
                          component='div'
                          className='error-message'
                        />
                      }
                      label='signuppage.LastName'
                      size='small'
                      InputProps={{
                        style: {
                          borderRadius: '35px',
                        },
                      }}
                      id='txtlastname'
                      sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
                    />
                  </Box>

                  <Field
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    fullWidth
                    variant='outlined'
                    label='signuppage.Email'
                    name='email'
                    helperText={
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='error-message'
                      />
                    }
                    InputProps={{
                      style: {
                        borderRadius: '35px',
                      },
                    }}
                    id='txtemailid'
                    size='small'
                    sx={{
                      width: isMobile || isTablet ? '100%' : '100%',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      width: '100%',
                      flexDirection: isMobile || isTablet ? 'column' : 'row',
                    }}
                  >
                    <Field
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      label='signuppage.Password'
                      type='password'
                      InputProps={{
                        style: {
                          borderRadius: '35px',
                        },
                      }}
                      id='txtpassword'
                      size='small'
                      as={TextField}
                      variant='outlined'
                      name='password'
                      fullWidth
                      helperText={
                        <ErrorMessage
                          name='password'
                          component='div'
                          className='error-message'
                        />
                      }
                      sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
                    />

                    <Field
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      as={TextField}
                      label='signuppage.ConfirmPassword'
                      InputProps={{
                        style: {
                          borderRadius: '35px',
                        },
                      }}
                      type='password'
                      id='txtconfirmpassword'
                      size='small'
                      variant='outlined'
                      name='confirmPassword'
                      fullWidth
                      helperText={
                        <ErrorMessage
                          name='confirmPassword'
                          component='div'
                          className='error-message'
                        />
                      }
                      sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
                    />
                  </Box>
                  {isFeatureEnable(featureFlagList.recaptcha) && (
                    <Field
                      as={ReCAPTCHA}
                      InputProps={{
                        style: {
                          borderRadius: '35px',
                        },
                      }}
                      id='g-recaptcha'
                      size='small'
                      variant='outlined'
                      name='captcha'
                      fullWidth
                      sitekey={env.REACT_APP_ReCAPTCHA_SITE_KEY}
                      onChange={(token: string) => {
                        setFieldValue('captcha', token);
                      }}
                    />
                  )}

                  <ErrorMessage
                    name='captcha'
                    component='div'
                    className='error-message'
                  />
                  <Field
                    name='agreeToTerms'
                    aria-label='agreeToTerms'
                    role='checkbox'
                    aria-checked='false'
                  >
                    {({ field }: FieldProps) => (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                      >
                        <Checkbox
                          style={{ marginRight: '2px' }}
                          {...field}
                          id='terms-of-service-checkbox'
                        />
                        <label
                          className='span'
                          id='terms-of-service'
                          htmlFor='terms'
                        >
                          <Typography
                            sx={{ fontSize: '13px' }}
                            label='signuppage.IhaveReadandagreeto'
                          />
                        </label>

                        <span
                          className='text-wrapper-4'
                          id='tos'
                          onClick={() =>
                            window.open(RedirectUrls.termsOfService, '_blank')
                          }
                        >
                          <Typography
                            sx={{ color: '#1206f9', fontSize: '13px' }}
                            label='signuppage.TermsOfService'
                          />
                        </span>
                        <span className='span'>
                          <Typography
                            sx={{ fontSize: '13px' }}
                            label='signuppage.And'
                          />
                        </span>
                        <span
                          id='privacy-policy'
                          className='text-wrapper-4'
                          onClick={() =>
                            window.open(RedirectUrls.privacyPolicy, '_blank')
                          }
                        >
                          <Typography
                            sx={{ color: '#1206f9', fontSize: '13px' }}
                            label='signuppage.PrivacyPolicy'
                          />
                        </span>
                      </Box>
                    )}
                  </Field>
                  <ErrorMessage
                    name='agreeToTerms'
                    component='div'
                    className='error-message'
                  />
                </Box>
                <Button
                  sx={{
                    backgroundColor: '#1206f9',
                    mt: '4%',
                    padding: 2,
                    color: '#fff',
                    borderRadius: '25px',
                    '&:hover': {
                      backgroundColor: '#1206f9',
                    },
                  }}
                  id='mirroSignUpSubmitButton'
                  variant='contained'
                  type='submit'
                  disabled={signUpStatus.isLoadingSignUpData}
                  label='signuppage.Signup'
                />
              </Box>
            </Form>
          )}
        </Formik>
        <Box sx={{ display: 'flex', mt: 1, flexDirection: 'column' }}>
          <Box>
            <Typography sx={{ textAlign: 'center' }} label='signuppage.Or' />

            <Typography
              sx={{
                textAlign: 'center',
                color: '#33344b',
                fontFamily: 'Poppins-SemiBold, Helvetica',
                fontSize: '16px',
                fontWeight: 700,
                letterSpacing: '0',
                lineHeight: '23px',
                marginBottom: '16px',
                whiteSpace: 'nowrap',
              }}
              label='signuppage.SignUpWith'
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <MicrosoftSignInButton isCandidateScreen={false} />
            <GoogleSignupButton />
            <FacebookSigninButton isCandidateScreen={false} />
          </Box>
        </Box>
      </Paper>
      <Footer />
    </Box>
  );
}

export default SignUpForm;

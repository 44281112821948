/** @format */

import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from 'widgets/Loader/Loader';
import VideoPlayerVersion2 from './IFrameVideoPreviewAdvanceComponents/VideoPlayerVersion2';
import TipCascadeVersion2 from './IFrameVideoPreviewAdvanceComponents/TipCascadeVersion2';
import OverallSummaryDetailsVersion2 from './IFrameVideoPreviewAdvanceComponents/OverallSummaryDetailsVersion2';
import EditTitleIcon from 'icons/EditTitleIcon';
import VideoPreviewAdvanceFilter from '../VideoPreviewAdvanceFilter';
import { UploadMediaData } from 'types/Upload-types';

import { ProtectedUrls, PublicUrls } from 'routes/urls';
import { FLAG_OPTIONS } from 'constants/Flags';
import {
  FlagOption,
  SelectedUserRoleTypes,
  TranscriptDataArray,
} from 'types/VideoPreview-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import SaveIcon from 'icons/SaveIcon';
import CancelSaveIcon from 'icons/CancelSaveIcon';
import { CoachingSessionReport } from 'types/VideoPreview-types';
import axios from 'axios';
import { Toast } from 'widgets/Toast/Toast';
import { truncateFilename } from 'utils/truncateFileNames';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';
import TabsForOverallAndPromptPerformance from 'components/VideoPreviewAdvance/TabsForOverallAndPromptPerformance';
import PromptsPane from 'components/VideoPreviewAdvance/Prompts';
import TabsForPromptSection from 'components/VideoPreviewAdvance/TabsForPromptSection';
import TabsForTranscriptAndTips from 'components/VideoPreviewAdvance/TabsForTranscriptAndTips';
import { VideoData } from '../../../types/VideoPreview-types';
import TranscriptComponent from 'components/VideoPreviewAdvance/TranscriptTab';
import { EmotionType } from 'types/common/Emotion-types';
import { handleUpdateVideoTitleForIframeService } from 'services/VideoPreview-services';
import SessionChangeModal from '../TipCascadeBulbAndModal/TipCascadeBulbAndModal/SessionChangeModal';

interface Params {
  [key: string]: string;
}

function IFrameVideoPreviewAdvanceVersion2() {
  const history: {
    location: {
      search:
        | string
        | string[][]
        | Record<string, string>
        | URLSearchParams
        | undefined;
      state: {
        coaching_session_reports_id: number;
      };
    };
  } = useHistory();

  const [currentVideoTime, setCurrentVideoTime] = useState(0);

  const [tipsPlaying, setTipsPlaying] = useState(''); //Boolen does not work.
  const [tipStartTime, setTipStartTime] = useState(0);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [reprocess, setReprocess] = useState(false);
  const [loading, setLoading] = useState(false);
  const historyId = history.location.state?.coaching_session_reports_id;
  // const localStorageId = localStorage.getItem('coaching_session_reports_id');
  const localStorageId = 15365;
  // const finalId = localStorageId ? parseInt(localStorageId, 10) : 0;
  const finalId = localStorageId;
  const [sessionId, setSessionId] = useState(0);
  const [apiKey, setApiKey] = useState('');
  const [env, setEnv] = useState('');
  const [
    ProcessDataListForParticipantsVersion2,
    setProcessDataListForParticipantsVersion2,
  ] = useState<Array<CoachingSessionReport>>([]);
  const [folder, setFolder] = useState('');
  const [forceRender, setForceRender] = useState(false);
  const [selectedFlags, setSelectedFlags] =
    useState<FlagOption[]>(FLAG_OPTIONS);
  const [selectedUserRole, setSelectedUserRole] = useState<
    Array<SelectedUserRoleTypes>
  >([]);
  const [sessionTypes, setSessionTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [emoticonSwitch, setEmoticonSwitch] = useState(1);
  const [tipsSwitch, setTipsSwitch] = useState(1);
  const [EmotionTypeList, setEmotionTypeList] = useState<Array<EmotionType>>(
    []
  );

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPerformanceTab, setPerformanceTab] = useState(0);
  const [selectedSummaryTab, setSummaryTab] = useState(0);
  const [selectedPrompt, setSelectedPrompt] = useState(0);
  const [componentTab, setComponentTab] = useState([0, 1]);
  const [Data, SetData] = useState<Array<TranscriptDataArray>>([]);
  const [promptID, setPromptID] = useState(0);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  const {
    sessionid: newSessionId,
    apikey: newApiKey,
    company: folderName,
    env: envName,
    emoticons: emoticonsSwitchValue,
    expert_tips: tipsSwitchValue,
  } = params;

  useEffect(() => {
    if (newSessionId) {
      setSessionId(Number(newSessionId));
    }

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (folderName) {
      setFolder(folderName);
    }
    if (envName) {
      setEnv(envName);
    }
    if (emoticonsSwitchValue) {
      setEmoticonSwitch(Number(emoticonsSwitchValue));
    }
    if (tipsSwitchValue) {
      setTipsSwitch(Number(tipsSwitchValue));
    }

    return () => {
      setEmoticonSwitch(1);
      setTipsSwitch(1);
    };
  }, [params]);

  useEffect(() => {
    setLoading(true);
    if (sessionId && apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
            sessionId
          )
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          if (response.data?.overall && response.data?.prompt_sections) {
            const temp = [
              {
                ...response.data.overall[0],
                prompt_sections: response.data.prompt_sections,
              },
            ];

            setProcessDataListForParticipantsVersion2(temp);
          } else {
            setProcessDataListForParticipantsVersion2(response.data);
          }

          setLoading(false);
        })
        .catch((error) => {});
    }
  }, [sessionId, apiKey, env]);
  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          PublicUrls.getSessionRoute.url(-1)
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          PublicUrls.getSessionRoute.url(-1)
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          PublicUrls.getSessionRoute.url(-1)
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          PublicUrls.getSessionRoute.url(-1)
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          PublicUrls.getSessionRoute.url(-1)
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          const filteredSessionTypes = response.data.filter(
            (sessionType: { id: number; name: string }) => {
              return (
                sessionType.id === 3 ||
                sessionType.id === 7 ||
                sessionType.id === 58
              );
            }
          );

          setSessionTypes(filteredSessionTypes);
        })
        .catch((error) => {});
    }
  }, [apiKey, env]);

  useEffect(() => {
    if (
      ProcessDataListForParticipantsVersion2.length > 0 &&
      ProcessDataListForParticipantsVersion2[0].is_prompt_section
    ) {
      setPromptID(
        ProcessDataListForParticipantsVersion2[0].prompt_sections[
          selectedPrompt
        ].coaching_session_reports_id
      );
    } else {
      setPromptID(sessionId);
    }
  }, [ProcessDataListForParticipantsVersion2, selectedPrompt, dispatch]);

  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getTranscriptData.url(promptID)
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getTranscriptData.url(promptID)
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getTranscriptData.url(promptID)
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getTranscriptData.url(sessionId)
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getTranscriptData.url(sessionId)
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          const transcriptData = response.data || [];
          SetData(transcriptData);
        })
        .catch((error) => {});
    }
  }, [apiKey, env, promptID]);

  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getEmotionTypes.url()
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getEmotionTypes.url()
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getEmotionTypes.url()
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          ProtectedUrls.getEmotionTypes.url()
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getEmotionTypes.url()
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          setEmotionTypeList(response.data);
        })
        .catch((error) => {});
    }
  }, [apiKey, env]);

  const [editedTitle, setEditedTitle] = useState('');
  const [componentView, setComponentView] = useState(0);
  const handleVideoTimeUpdate = (time: number) => {
    setCurrentVideoTime(time);
  };
  const [videoPlaying, setVideoPlaying] = useState(false);

  const cascadeRef = useRef<HTMLDivElement | null>(null);
  const highlightedCardRef = useRef<HTMLDivElement | null>(null);
  const vidRef = useRef<HTMLVideoElement>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  useEffect(() => {
    if (ProcessDataListForParticipantsVersion2.length > 0) {
      setEditedTitle(
        ProcessDataListForParticipantsVersion2[0]?.session_title
          ? ProcessDataListForParticipantsVersion2[0]?.session_title
          : ProcessDataListForParticipantsVersion2[0]?.processing_event_name
      );
    }
  }, [ProcessDataListForParticipantsVersion2, forceRender]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const handleEditClick = () => {
    setIsEditing(true);
    setEditedTitle(
      ProcessDataListForParticipantsVersion2.length > 0
        ? ProcessDataListForParticipantsVersion2[0]?.session_title
          ? ProcessDataListForParticipantsVersion2[0]?.session_title
          : ProcessDataListForParticipantsVersion2[0]?.processing_event_name
        : ''
    );
  };

  const handleUpdateVideoTitle = async () => {
    dispatch(
      handleUpdateVideoTitleForIframeService(
        setIsEditing,
        setLoading,
        sessionId,
        editedTitle,
        apiKey,
        env,
        setProcessDataListForParticipantsVersion2,
        ProcessDataListForParticipantsVersion2,
        setEditedTitle,
        setForceRender
      )
    );
  };

  useEffect(() => {
    if (!ProcessDataListForParticipantsVersion2) {
      const delay = setTimeout(() => {
        setForceRender(false);
      }, 500);
      return () => clearTimeout(delay);
    }
  }, [forceRender, editedTitle, ProcessDataListForParticipantsVersion2]);

  const currentSessionType: UploadMediaData[] = sessionTypes.filter(
    (sessionType: UploadMediaData) => {
      return (
        sessionType.id ===
        ProcessDataListForParticipantsVersion2[0]?.session_type_id
      );
    }
  );

  const otherSessionTypes: UploadMediaData[] = sessionTypes.filter(
    (sessionType: UploadMediaData) => {
      return (
        sessionType.id !==
        ProcessDataListForParticipantsVersion2[0]?.session_type_id
      );
    }
  );

  const handleReprocess = async () => {
    setShow(true);
  };

  async function handleYesReprocess() {
    setLoading(true);
    setShow(false);
    try {
      const reprocessResponse = await callReprocessAPI();

      if (reprocessResponse.data.Job_Id) {
        await checkStatus();
      }
    } catch (error) {}
  }
  const checkStatus = async () => {
    try {
      const statusResponse = await callStatusAPI();

      if (statusResponse.data[0].process_status === 9) {
        if (sessionId && apiKey) {
          let apiUrl = '';

          if (env === 'pd') {
            apiUrl = `${
              process.env.REACT_APP_API_BASE_URL_PD +
              ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
                sessionId
              )
            }`;
          } else if (env === 'ci') {
            apiUrl = `${
              process.env.REACT_APP_API_BASE_URL +
              ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
                sessionId
              )
            }`;
          } else if (env === 'qa') {
            apiUrl = `${
              process.env.REACT_APP_API_BASE_URL_QA +
              ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
                sessionId
              )
            }`;
          } else if (env === 'ua') {
            apiUrl = `${
              process.env.REACT_APP_API_BASE_URL_UA +
              ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
                sessionId
              )
            }`;
          } else {
            apiUrl = `${
              process.env.REACT_APP_API_BASE_URL_PD +
              ProtectedUrls.getProcessedDataListForParticipantVersion2Service.URL(
                sessionId
              )
            }`;
          }

          axios
            .get(apiUrl, {
              headers: {
                'x-api-key': apiKey,
              },
            })
            .then((response) => {
              setProcessDataListForParticipantsVersion2(response.data);
              setLoading(false);
            })
            .catch((error) => {});
        }
      } else {
        await checkStatus();
      }
    } catch (error) {}
  };

  const callReprocessAPI = async () => {
    const newReprocessVideoData = {
      video_title: editedTitle,
      metrics_processing_events_history_id_list: finalId,
      sessionDetails: {
        id: otherSessionTypes[0].id,
        name: otherSessionTypes[0].name,
      },
    };

    let apiUrl = '';

    const payloadForApiUrl = {
      video_title: editedTitle,
      metrics_processing_events_history_id_list: sessionId,
      sessionDetails: otherSessionTypes[0],
      text_analysis: true,
      video_analysis: false,
    };

    if (env === 'pd') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_PD +
        ProtectedUrls.getReprocessVideoVersion2VideoService.url(
          payloadForApiUrl
        )
      }&language=English`;
    } else if (env === 'ci') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL +
        ProtectedUrls.getReprocessVideoVersion2VideoService.url(
          payloadForApiUrl
        )
      }&language=English`;
    } else if (env === 'qa') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_QA +
        ProtectedUrls.getReprocessVideoVersion2VideoService.url(
          payloadForApiUrl
        )
      }&language=English`;
    } else if (env === 'ua') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_UA +
        ProtectedUrls.getReprocessVideoVersion2VideoService.url(
          payloadForApiUrl
        )
      }&language=English`;
    } else {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_PD +
        ProtectedUrls.getReprocessVideoVersion2VideoService.url(
          payloadForApiUrl
        )
      }&language=English`;
    }

    return await axios
      .get(apiUrl, {
        headers: {
          'x-api-key': apiKey,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new Error('Reprocessing API request failed', error);
      });
  };

  const callStatusAPI = async () => {
    let apiUrl = '';

    if (env === 'pd') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_PD +
        ProtectedUrls.getProcessStatus.URL(sessionId)
      }`;
    } else if (env === 'ci') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL +
        ProtectedUrls.getProcessStatus.URL(sessionId)
      }`;
    } else if (env === 'qa') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_QA +
        ProtectedUrls.getProcessStatus.URL(sessionId)
      }`;
    } else if (env === 'ua') {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_UA +
        ProtectedUrls.getProcessStatus.URL(sessionId)
      }`;
    } else {
      apiUrl = `${
        process.env.REACT_APP_API_BASE_URL_PD +
        ProtectedUrls.getProcessStatus.URL(sessionId)
      }`;
    }

    return await axios
      .get(apiUrl, {
        headers: {
          'x-api-key': apiKey,
        },
      })
      .then((response) => {
        if (!response.data) {
          throw new Error('Status API request failed');
        }
        return response;
      });
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setPerformanceTab(newValue);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setComponentView(newValue);
  };

  return (
    <Box sx={{ maxWidth: '99%', width: '100%', p: 2 }}>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          {isMobile || isTablet ? (
            <Box sx={{ width: '100%' }}>
              <Paper
                elevation={1}
                sx={{ width: '100%', height: 'fit-content', mt: 1, mb: 2 }}
                id='VideoPreviewHeader'
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    float: 'left',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      {!ProcessDataListForParticipantsVersion2[0]
                        ?.is_prompt_section ? (
                        <Autocomplete
                          id='combo-box-SessionType'
                          options={otherSessionTypes}
                          optionLabelKey='name'
                          label='record.SessionType'
                          value={
                            currentSessionType.length > 0
                              ? currentSessionType[0]
                              : null
                          }
                          onChange={handleReprocess}
                          disabled={true}
                          size='small'
                          sx={{
                            border: '#1206f9',
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=''
                              sx={{ width: '100% !important' }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          id='combo-box-SessionType'
                          options={otherSessionTypes}
                          optionLabelKey='name'
                          label='record.SessionType'
                          value={
                            currentSessionType.length > 0
                              ? currentSessionType[0]
                              : null
                          }
                          onChange={handleReprocess}
                          size='small'
                          sx={{
                            border: '#1206f9',
                          }}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=''
                              sx={{ width: '100% !important' }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {isEditing ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            left: '0',
                            gap: '5px',
                          }}
                        >
                          <TextField
                            value={editedTitle}
                            sx={{ width: '100%' }}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            fullWidth
                          />

                          <Box
                            onClick={handleUpdateVideoTitle}
                            sx={{
                              width: 'min-content',
                              height: 'min-content',
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <SaveIcon />
                          </Box>
                          <Box
                            onClick={(event) => {
                              setIsEditing(false);
                            }}
                            sx={{
                              width: 'min-content',
                              height: 'min-content',
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <CancelSaveIcon />
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',

                            alignItems: 'center',
                            alignSelf: 'flex-start',

                            p: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#33344B',
                              fontFamily: 'Poppins',
                              fontSize: '17px',
                              fontStyle: 'normal',
                              fontWeight: 300,
                              lineHeight: '20px',
                            }}
                            label={truncateFilename(
                              editedTitle ||
                                (ProcessDataListForParticipantsVersion2.length >
                                0
                                  ? ProcessDataListForParticipantsVersion2[0]
                                      ?.session_title
                                  : ProcessDataListForParticipantsVersion2[0]
                                      ?.processing_event_name),
                              35
                            )}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: '100%',
                          height: 'fit-content',
                          display: 'flex',
                        }}
                      >
                        <VideoPreviewAdvanceFilter
                          selectedUserRole={selectedUserRole}
                          selectedFlags={selectedFlags}
                          setSelectedUserRole={setSelectedUserRole}
                          setSelectedFlags={setSelectedFlags}
                          processDataListForParticipantsVersion2={
                            ProcessDataListForParticipantsVersion2
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <Grid item xs={12} sm={12} id='VideoPlayerGrid'>
                <Box
                  sx={{
                    background: '#FFF',
                    width: '100%',
                    height: 'fit-content',
                    mt: 2,
                    mb: 2,
                    display: 'inline-block',
                  }}
                >
                  <Tabs
                    value={false}
                    onChange={handleTabChange}
                    aria-label='Inline tabs example'
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexGrow: 1,
                      width: '100%',
                    }}
                  >
                    <Tab
                      label='Report'
                      value={0}
                      id='ReportTabButton'
                      sx={{
                        position: 'relative',

                        backgroundColor:
                          componentView === 0 ? '#1206f9' : '#F2F2F2',
                        color: componentView === 0 ? '#fff' : '#000',
                        fontWeight: 700,
                        width: 'auto',
                        height: 'auto',
                        padding: '13.75px 20px',
                        borderRadius: '4.3px 4.3px 0px 0px',

                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',

                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        marginBottom: '10px',
                      }}
                    />
                    <Tab
                      label='Deep Dive'
                      value={1}
                      id='DeepDiveTabButton'
                      sx={{
                        position: 'relative',

                        backgroundColor:
                          componentView === 1 ? '#1206f9' : '#F2F2F2',
                        color: componentView === 1 ? '#fff' : '#000',
                        fontWeight: 700,
                        width: 'auto',
                        height: 'auto',
                        padding: '13.75px 20px',
                        borderRadius: '4.3px 4.3px 0px 0px',

                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',

                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        marginBottom: '10px',
                      }}
                    />

                    <Tab
                      label='Transcript'
                      value={2}
                      id='TranscriptTabButton'
                      sx={{
                        position: 'relative',

                        backgroundColor:
                          componentView === 2 ? '#1206f9' : '#F2F2F2',
                        color: componentView === 2 ? '#fff' : '#000',
                        fontWeight: 700,
                        width: 'auto',
                        height: 'auto',
                        padding: '13.75px 20px',
                        borderRadius: '4.3px 4.3px 0px 0px',

                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',

                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        marginBottom: '10px',
                      }}
                    />
                  </Tabs>
                </Box>

                {componentView === 0 && (
                  <TabsForPromptSection
                    selectedSummaryTab={selectedSummaryTab}
                    setSummaryTab={setSummaryTab}
                    ProcessDataListForParticipantsVersion2={
                      ProcessDataListForParticipantsVersion2
                    }
                    selectedPrompt={selectedPrompt}
                    sessionId={sessionId}
                    isIframe={true}
                    apiKey={apiKey}
                  />
                )}
                {componentView === 1 && (
                  <>
                    <VideoPlayerVersion2
                      ProcessDataListForParticipants={
                        ProcessDataListForParticipantsVersion2
                      }
                      onTimeUpdate={handleVideoTimeUpdate}
                      videoPlaying={videoPlaying}
                      setVideoPlaying={setVideoPlaying}
                      tipsPlaying={tipsPlaying}
                      tipStartTime={tipStartTime}
                      videoData={
                        selectedPrompt === 0
                          ? ProcessDataListForParticipantsVersion2[0]
                          : ProcessDataListForParticipantsVersion2[0]
                              ?.prompt_sections[selectedPrompt]
                      }
                      currentVideoTime={currentVideoTime}
                      handleVideoTips={setTipsPlaying}
                      setTipStartTime={setTipStartTime}
                      cascadeRef={cascadeRef}
                      highlightedCardRef={highlightedCardRef}
                      vidRef={vidRef}
                      highlightedIndex={highlightedIndex}
                      setHighlightedIndex={setHighlightedIndex}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      selectedUserRole={selectedUserRole}
                      selectedFlags={selectedFlags}
                      folder={folder}
                      envName={env}
                      emoticonSwitch={emoticonSwitch}
                      tipsSwitch={tipsSwitch}
                      selectedPrompt={selectedPrompt}
                    />
                    {<Divider />}
                    <Paper
                      elevation={3}
                      sx={{
                        border: '1px solid #D4D4D4',
                        background: '#FFF',
                        minHeight: '120vh',
                        width: '100%',
                        height: 'fit-content',
                        justifyContent: 'center',
                      }}
                    >
                      {ProcessDataListForParticipantsVersion2[0]
                        ?.is_prompt_section && (
                        <Box
                          sx={{
                            background: '#FFF',
                            width: '100%',
                            height: 'fit-content',
                            mt: 1,
                          }}
                        >
                          <PromptsPane
                            Data={
                              ProcessDataListForParticipantsVersion2[0]
                                ?.prompt_sections || []
                            }
                            setSelectedPrompt={setSelectedPrompt}
                            selectedPrompt={selectedPrompt}
                            SessionId={
                              ProcessDataListForParticipantsVersion2[0]
                                ?.session_type_id
                            }
                          />
                        </Box>
                      )}

                      <Box
                        sx={{
                          background: '#FFF',
                          width: '100%',
                          height: 'fit-content',
                        }}
                      >
                        <TipCascadeVersion2
                          videoData={ProcessDataListForParticipantsVersion2[0]}
                          currentVideoTime={currentVideoTime}
                          ProcessDataListForParticipants={
                            ProcessDataListForParticipantsVersion2
                          }
                          videoPlaying={videoPlaying}
                          handleVideoTips={setTipsPlaying}
                          setTipStartTime={setTipStartTime}
                          cascadeRef={cascadeRef}
                          highlightedCardRef={highlightedCardRef}
                          vidRef={vidRef}
                          highlightedIndex={highlightedIndex}
                          setHighlightedIndex={setHighlightedIndex}
                          currentIndex={currentIndex}
                          setCurrentIndex={setCurrentIndex}
                          selectedUserRole={selectedUserRole}
                          selectedFlags={selectedFlags}
                          selectedPrompt={selectedPrompt}
                        />
                      </Box>
                    </Paper>
                    {<Divider />}
                    <Paper
                      elevation={3}
                      sx={{
                        border: '1px solid #D4D4D4',
                        background: '#FFF',
                        minHeight: '120vh',
                        width: '100%',
                        height: 'fit-content',
                        justifyContent: 'center',
                      }}
                    >
                      <TabsForOverallAndPromptPerformance
                        selectedPerformanceTab={selectedPerformanceTab}
                        setPerformanceTab={setPerformanceTab}
                        ProcessDataListForParticipantsVersion2={
                          ProcessDataListForParticipantsVersion2
                        }
                        selectedPrompt={selectedPrompt}
                        selectedUserRole={selectedUserRole}
                        selectedFlags={selectedFlags}
                      />
                    </Paper>
                  </>
                )}
                {componentView === 2 && (
                  <Paper
                    elevation={3}
                    sx={{
                      border: '1px solid #D4D4D4',
                      background: '#FFF',
                      minHeight: '120vh',
                      width: '100%',
                      height: 'fit-content',
                      justifyContent: 'center',
                    }}
                  >
                    <TranscriptComponent Data={Data} />
                  </Paper>
                )}
              </Grid>
            </Box>
          ) : (
            <>
              <Paper
                elevation={1}
                sx={{ width: '100%', height: 'fit-content', mt: 1 }}
                id='VideoPreviewHeader'
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 'fit-content',
                    display: 'flex',

                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Grid container spacing={1} sx={{ p: 1 }}>
                    <Grid item md={2}>
                      {!ProcessDataListForParticipantsVersion2[0]
                        ?.is_prompt_section ? (
                        <Autocomplete
                          id='combo-box-SessionType'
                          options={otherSessionTypes}
                          optionLabelKey='name'
                          label='record.SessionType'
                          value={
                            currentSessionType.length > 0
                              ? currentSessionType[0]
                              : null
                          }
                          onChange={handleReprocess}
                          disabled={true}
                          size='small'
                          sx={{
                            border: '#1206f9',
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=''
                              sx={{ width: '100% !important' }}
                            />
                          )}
                        />
                      ) : (
                        <Autocomplete
                          id='combo-box-SessionType'
                          options={otherSessionTypes}
                          optionLabelKey='name'
                          label='record.SessionType'
                          value={
                            currentSessionType.length > 0
                              ? currentSessionType[0]
                              : null
                          }
                          onChange={handleReprocess}
                          size='small'
                          sx={{
                            border: '#1206f9',
                          }}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=''
                              sx={{ width: '100% !important' }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {isEditing ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            justifyItems: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                            left: '0',
                            gap: '5px',
                          }}
                        >
                          <TextField
                            value={editedTitle}
                            size='small'
                            sx={{ width: '100%', mt: 1 }}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            fullWidth
                          />

                          <Box
                            onClick={handleUpdateVideoTitle}
                            sx={{
                              width: 'min-content',
                              height: 'min-content',
                              margin: 0,
                              padding: 0,
                              mt: 1,
                            }}
                          >
                            <SaveIcon />
                          </Box>
                          <Box
                            onClick={(event) => {
                              setIsEditing(false);
                            }}
                            sx={{
                              width: 'min-content',
                              height: 'min-content',
                              margin: 0,
                              padding: 0,
                              mt: 1,
                            }}
                          >
                            <CancelSaveIcon />
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',

                            alignItems: 'center',
                            alignSelf: 'flex-start',

                            p: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#33344B',
                              fontFamily: 'Poppins',
                              fontSize: '17px',
                              fontStyle: 'normal',
                              fontWeight: 300,
                              lineHeight: '20px',
                            }}
                            label={truncateFilename(
                              editedTitle ||
                                (ProcessDataListForParticipantsVersion2.length >
                                0
                                  ? ProcessDataListForParticipantsVersion2[0]
                                      ?.session_title
                                  : ProcessDataListForParticipantsVersion2[0]
                                      ?.processing_event_name),
                              35
                            )}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <Box
                        sx={{
                          width: '100%',
                          height: 'fit-content',
                          display: 'flex',
                        }}
                      >
                        <VideoPreviewAdvanceFilter
                          selectedFlags={selectedFlags}
                          setSelectedFlags={setSelectedFlags}
                          selectedUserRole={selectedUserRole}
                          setSelectedUserRole={setSelectedUserRole}
                          processDataListForParticipantsVersion2={
                            ProcessDataListForParticipantsVersion2
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>

              <>
                {componentView === 0 && (
                  <Grid item md={11.5} sx={{ marginRight: 0, gap: 2 }}>
                    <TabsForPromptSection
                      selectedSummaryTab={selectedSummaryTab}
                      setSummaryTab={setSummaryTab}
                      ProcessDataListForParticipantsVersion2={
                        ProcessDataListForParticipantsVersion2
                      }
                      selectedPrompt={selectedPrompt}
                      sessionId={sessionId}
                      isIframe={true}
                      apiKey={apiKey}
                    />
                  </Grid>
                )}

                {componentView === 1 && (
                  <Grid
                    item
                    md={11.5}
                    sx={{ marginRight: 0, gap: 2, display: 'flex' }}
                  >
                    <Grid item md={6} id='VideoPlayerGrid'>
                      <VideoPlayerVersion2
                        ProcessDataListForParticipants={
                          ProcessDataListForParticipantsVersion2
                        }
                        onTimeUpdate={handleVideoTimeUpdate}
                        videoPlaying={videoPlaying}
                        setVideoPlaying={setVideoPlaying}
                        tipsPlaying={tipsPlaying}
                        tipStartTime={tipStartTime}
                        videoData={
                          selectedPrompt === 0
                            ? ProcessDataListForParticipantsVersion2[0]
                            : ProcessDataListForParticipantsVersion2[0]
                                ?.prompt_sections[selectedPrompt]
                        }
                        currentVideoTime={currentVideoTime}
                        handleVideoTips={setTipsPlaying}
                        setTipStartTime={setTipStartTime}
                        cascadeRef={cascadeRef}
                        highlightedCardRef={highlightedCardRef}
                        vidRef={vidRef}
                        highlightedIndex={highlightedIndex}
                        setHighlightedIndex={setHighlightedIndex}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                        selectedUserRole={selectedUserRole}
                        selectedFlags={selectedFlags}
                        folder={folder}
                        envName={env}
                        emoticonSwitch={emoticonSwitch}
                        tipsSwitch={tipsSwitch}
                        selectedPrompt={selectedPrompt}
                      />
                    </Grid>

                    <Grid
                      item
                      md={3}
                      sx={{ marginRight: 0, gap: 2 }}
                      id='CascadeGrid'
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          border: '1px solid #D4D4D4',
                          background: '#FFF',
                          minHeight: '120vh',
                          width: '100%',
                          height: 'fit-content',
                          justifyContent: 'center',
                        }}
                      >
                        {ProcessDataListForParticipantsVersion2[0]
                          ?.is_prompt_section && (
                          <Box
                            sx={{
                              background: '#FFF',
                              width: '100%',
                              height: 'fit-content',
                              mt: 1,
                            }}
                          >
                            <PromptsPane
                              Data={
                                ProcessDataListForParticipantsVersion2[0]
                                  ?.prompt_sections || []
                              }
                              setSelectedPrompt={setSelectedPrompt}
                              selectedPrompt={selectedPrompt}
                              SessionId={
                                ProcessDataListForParticipantsVersion2[0]
                                  ?.session_type_id
                              }
                            />
                          </Box>
                        )}

                        <Box
                          sx={{
                            background: '#FFF',
                            width: '100%',
                            height: 'fit-content',
                          }}
                        >
                          <TipCascadeVersion2
                            videoData={
                              ProcessDataListForParticipantsVersion2[0]
                            }
                            currentVideoTime={currentVideoTime}
                            ProcessDataListForParticipants={
                              ProcessDataListForParticipantsVersion2
                            }
                            videoPlaying={videoPlaying}
                            handleVideoTips={setTipsPlaying}
                            setTipStartTime={setTipStartTime}
                            cascadeRef={cascadeRef}
                            highlightedCardRef={highlightedCardRef}
                            vidRef={vidRef}
                            highlightedIndex={highlightedIndex}
                            setHighlightedIndex={setHighlightedIndex}
                            currentIndex={currentIndex}
                            setCurrentIndex={setCurrentIndex}
                            selectedUserRole={selectedUserRole}
                            selectedFlags={selectedFlags}
                            selectedPrompt={selectedPrompt}
                          />
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      md={2.5}
                      sx={{ marginLeft: 0 }}
                      id='PerformanceGrid'
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          border: '1px solid #D4D4D4',
                          background: '#FFF',
                          minHeight: '120vh',
                          width: '100%',
                          height: 'fit-content',
                          justifyContent: 'center',
                        }}
                      >
                        <TabsForOverallAndPromptPerformance
                          selectedPerformanceTab={selectedPerformanceTab}
                          setPerformanceTab={setPerformanceTab}
                          ProcessDataListForParticipantsVersion2={
                            ProcessDataListForParticipantsVersion2
                          }
                          selectedPrompt={selectedPrompt}
                          selectedUserRole={selectedUserRole}
                          selectedFlags={selectedFlags}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                )}

                {componentView === 2 && (
                  <Grid item md={11.5} sx={{ marginRight: 0, gap: 2 }}>
                    <Paper
                      elevation={3}
                      sx={{
                        border: '1px solid #D4D4D4',
                        background: '#FFF',
                        minHeight: '120vh',
                        width: '100%',
                        height: 'fit-content',
                        justifyContent: 'center',
                      }}
                    >
                      <TranscriptComponent Data={Data} />
                    </Paper>
                  </Grid>
                )}
                <Grid item md={0.5} id='TabsGrid'>
                  <Tabs
                    orientation='vertical'
                    value={false}
                    onChange={handleTabChange}
                    aria-label='Vertical tabs example'
                    sx={{
                      borderRight: 1,

                      border: 'none',

                      alignItems: 'flex-start',
                      textAlign: 'left',
                      width: '50px',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Tab
                      label='Report'
                      value={0}
                      id='ReportTabButton'
                      sx={{
                        position: 'relative',
                        right: 20,
                        writingMode: 'vertical-lr',
                        textOrientation: 'updown',
                        backgroundColor:
                          componentView === 0 ? '#1206f9' : '#F2F2F2',
                        color: componentView === 0 ? '#fff' : '#000',
                        fontWeight: 700,
                        width: 'auto',
                        height: 'auto',
                        padding: '10.75px 35.47px',
                        borderRadius: '4.3px 4.3px 0px 0px',

                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',

                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        marginBottom: '10px',
                        minHeight: '150px',
                      }}
                    />
                    <Tab
                      label='Deep Dive'
                      value={1}
                      id='DeepDiveTabButton'
                      sx={{
                        position: 'relative',
                        right: 20,
                        writingMode: 'vertical-lr',
                        textOrientation: 'updown',
                        backgroundColor:
                          componentView === 1 ? '#1206f9' : '#F2F2F2',
                        color: componentView === 1 ? '#fff' : '#000',
                        fontWeight: 700,
                        width: 'auto',
                        height: 'auto',
                        padding: '10.75px 35.47px',
                        borderRadius: '4.3px 4.3px 0px 0px',

                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',

                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        marginBottom: '10px',
                        minHeight: '150px',
                      }}
                    />

                    <Tab
                      label='Transcript'
                      value={2}
                      id='TranscriptTabButton'
                      sx={{
                        position: 'relative',
                        right: 20,
                        writingMode: 'vertical-lr',
                        textOrientation: 'updown',
                        backgroundColor:
                          componentView === 2 ? '#1206f9' : '#F2F2F2',
                        color: componentView === 2 ? '#fff' : '#000',
                        fontWeight: 700,
                        width: 'auto',
                        height: 'auto',
                        padding: '10.75px 35.47px',
                        borderRadius: '4.3px 4.3px 0px 0px',

                        borderBottom: 'none',
                        opacity: 'var(--Tabs)',

                        textAlign: 'left',
                        justifyContent: 'flex-start',
                        display: 'flex',
                        marginBottom: '10px',
                        minHeight: '150px',
                      }}
                    />
                  </Tabs>
                </Grid>
              </>
            </>
          )}
        </Grid>
      )}
      {show && (
        <SessionChangeModal
          setShow={setShow}
          setReprocess={setReprocess}
          handleYesReprocess={handleYesReprocess}
        />
      )}
    </Box>
  );
}
export default IFrameVideoPreviewAdvanceVersion2;

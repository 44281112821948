import { createSlice } from '@reduxjs/toolkit';
import { RecoveryDashboardDataInitialStateTypes } from 'types/TeleHealth/RecoveryDashboard-types';

const getRecoveryDashboardDataInitialState: RecoveryDashboardDataInitialStateTypes =
  {
    isLoadingGetDashboardData: false,
    DashboardData: [],
    errorGetDashboardData: '',
  };

const getRecoveryDashboardDataSlice = createSlice({
  name: ' getRecoveryDashboardData',
  initialState: getRecoveryDashboardDataInitialState,
  reducers: {
    getRecoveryDashboardDataLoad(state) {
      state.isLoadingGetDashboardData = true;
    },
    getRecoveryDashboardDataSuccess(state, action) {
      state.isLoadingGetDashboardData = false;
      state.DashboardData = action.payload;
    },
    getRecoveryDashboardDataFailure(state, action) {
      state.isLoadingGetDashboardData = false;
      state.errorGetDashboardData = action.payload;
    },
  },
});

export const {
  getRecoveryDashboardDataLoad,
  getRecoveryDashboardDataSuccess,
  getRecoveryDashboardDataFailure,
} = getRecoveryDashboardDataSlice.actions;

export { getRecoveryDashboardDataSlice };

import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getProcessDataListVersion2Service } from 'services/Gallery-service';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import {
  globalSearchSelector,
  getProcessDataListVersion2Selector,
} from 'redux/selectors/selectors';
import { FilteredProcessDataList } from 'types/Dashboard-types';

// Typing for the useDashboard hook
function useDashboard(filteredDataWithInputs: FilteredProcessDataList[]) {
  const dispatch = useAppDispatch();

  const [processDataListWithSession, setProcessDataListWithSession] = useState<
    ProcessDataListVersion2[]
  >([]);

  const { globalSearchValue } = useAppSelector(globalSearchSelector);
  const { isLoadingProcessDataListVersion2, processDataListVersion2 } =
    useAppSelector(getProcessDataListVersion2Selector);

  useEffect(() => {
    dispatch(getProcessDataListVersion2Service());
  }, [dispatch]);

  useEffect(() => {
    searchVideosByTitle(globalSearchValue);
    // eslint-disable-next-line
  }, [globalSearchValue, processDataListVersion2]);

  function searchVideosByTitle(searchQuery: string) {
    const lowerCaseSearchQuery = searchQuery.toLowerCase();
    const filteredData = filteredDataWithInputs[0];

    let searchedData: ProcessDataListVersion2[] = [];

    if (filteredDataWithInputs.length === 0) {
      searchedData = processDataListVersion2.filter(({ session_title }) => {
        return (
          session_title &&
          session_title.toLowerCase().includes(lowerCaseSearchQuery)
        );
      });
    } else {
      if (filteredData.FilteredData) {
        searchedData = filteredData.FilteredData.filter(({ session_title }) => {
          return (
            session_title &&
            session_title.toLowerCase().includes(lowerCaseSearchQuery)
          );
        });
      }
    }
    setProcessDataListWithSession(searchedData);
  }

  return {
    isLoadingProcessDataListVersion2,
    processDataListWithSession,
    setProcessDataListWithSession,
  };
}

export default useDashboard;

import Box from '@mui/material/Box';
import React from 'react';
import Button from 'widgets/CustomButton/Button';
import Typography from 'widgets/Typography/Typography';

function ConfirmationComponent() {
  sessionStorage.clear();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        textAlign: 'center',
        gap: 3,
      }}
      id='confirmationBox'
    >
      <Typography
        label='Thank you for answering the questionnaire!'
        sx={{ color: '#000', fontSize: '26px' }}
      />
    </Box>
  );
}

export default ConfirmationComponent;

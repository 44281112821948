/** @format */

import React, { useEffect, useState } from 'react';
import AuthWrapper from 'shared/AuthWrapper/AuthWrapper';
import './dashboard.css';
import ProcessDataListTable from './ProcessDataListTable';
import { Box } from '@mui/material';
import Loader from 'widgets/Loader/Loader';
import useDashboard from './hooks/useDashboard';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { GetUserRolesService } from 'services/CommonServices/UserRoles-Serice';
import { FilteredProcessDataList } from 'types/Dashboard-types';
import { useTranslation } from 'react-i18next';
import { headCells } from './HeaderCells';
import Typography from 'widgets/Typography/Typography';
import { deleteProcessVideoV2Selector } from 'redux/selectors/selectors';
import { getAiPermissionServiceApi } from 'services/AiPermission-service';

function DashboardComponent() {
  const [filteredDataWithInputs, setFilteredDataWithInputs] = useState<
    FilteredProcessDataList[]
  >([]);
  const {
    isLoadingProcessDataListVersion2,
    processDataListWithSession,
    setProcessDataListWithSession,
  } = useDashboard(filteredDataWithInputs);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetUserRolesService());
    dispatch(getAiPermissionServiceApi());
  }, []);

  const { isDeletingProcessVideo } = useAppSelector(
    deleteProcessVideoV2Selector
  );

  return (
    <AuthWrapper>
      {isLoadingProcessDataListVersion2 || isDeletingProcessVideo ? (
        <Loader />
      ) : (
        <Box>
          <Typography
            id='DashboardTitle'
            sx={{
              color: '#2C2C2C',
              fontFamily: 'Poppins',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '-0.198px',
              marginTop: 2,
              marginLeft: 2,
            }}
            label='dashboard.Dashboard'
          />

          <ProcessDataListTable
            processDataListWithSession={processDataListWithSession}
            setProcessDataListWithSession={setProcessDataListWithSession}
            onFilterDataChange={setFilteredDataWithInputs}
            headCells={headCells}
          />
        </Box>
      )}
    </AuthWrapper>
  );
}

export default DashboardComponent;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import react from 'react';
import { MicrophoneDetails, CameraDetails } from 'types/MyAssessments-types';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CamerasType, MicrophonesType } from 'types/common/Common-types';
import RecordRTC from 'recordrtc';
import Typography from 'widgets/Typography/Typography';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

const initialValuesForRecordings: CamerasType | MicrophonesType = {
  deviceId: '',
  groupId: '',
  kind: '',
  label: '',
};

function PatientVideoIosRecorder({
  onRecordingComplete,
  setCurrentQuestionIndex,
  currentQuestionIndex,
  Questionnaire,
  selectedVideoDevice,
  selectedAudioDevice,
  status,
  mediaBlobUrl,
  isReUpload,
  isUploaded,
  isCameraEnabled,
}: {
  onRecordingComplete: (
    blobUrl: Blob | MediaSource | string | null,
    duration: number,
    isPrev: boolean
  ) => void;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  currentQuestionIndex: number;
  Questionnaire: Array<QuestionnaireData>;
  selectedVideoDevice: MicrophoneDetails;
  selectedAudioDevice: CameraDetails;
  status: string;
  mediaBlobUrl: string | Blob | MediaSource;
  isReUpload: boolean;
  isUploaded: boolean;
  isCameraEnabled: boolean;
}) {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery('(max-width: 245px)');

  const [recording, setRecording] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const videoRef = useRef<any>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<any[]>([]);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [recorder, setRecorder] = useState<RecordRTC | null>(null);
  let blob: Blob | MediaSource;
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const totalSecondsRef = useRef<number>(0);

  useEffect(() => {
    let countdownInterval: NodeJS.Timeout;

    if (recording) {
      handleStartRecording();
      setRecording(false);
    }

    return () => {
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line
  }, [recording]);

  useEffect(() => {
    let timerInterval: NodeJS.Timeout;

    if (isRunning) {
      timerInterval = setInterval(() => {
        setTotalSeconds((prev) => {
          const newTotal = prev + 1;
          totalSecondsRef.current = newTotal;
          return newTotal;
        });
      }, 1000);

      if (totalSeconds === 600) {
        handleStopRecording();
      }
    }

    return () => clearInterval(timerInterval);
  }, [isRunning, totalSeconds]);

  useEffect(() => {
    if (isRecording) {
      startTimer();
    }

    if (!isRecording) {
      pauseTimer();
    }
  }, [isRecording]);

  const startTimer = useCallback(() => {
    if (!isRecording) {
      setIsRunning(true);
    }
  }, [isRunning]);

  const pauseTimer = useCallback(() => {
    if (isRecording) {
      setIsRunning(false);
    }
  }, [isRunning]);

  const resetTimer = () => {
    setIsRunning(false);
    setTotalSeconds(0);
    totalSecondsRef.current = 0;
    setMinutes(0);
    setSeconds(0);
  };

  const handlePreviousQuestion = async () => {
    const recordedSeconds = totalSecondsRef.current;
    if (videoURL) {
      onRecordingComplete(videoURL, recordedSeconds, true);
      setVideoURL(null);
      resetTimer();
    } else {
      setCurrentQuestionIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;

        return newIndex;
      });
    }
  };

  const handleStartRecording = () => {
    if (recorder) {
      recorder.startRecording();
      setIsRecording(true);
    }
  };

  const handleStopRecording = useCallback(() => {
    if (recorder) {
      recorder.stopRecording(() => {
        blob = recorder.getBlob();
        setRecordedChunks((prevChunks) => [...prevChunks, blob]);
        const url = URL.createObjectURL(blob);
        setVideoURL(url);
        setIsRecording(false);
        const recordedSeconds = totalSecondsRef.current;
        if (url && currentQuestionIndex < Questionnaire.length - 1) {
          onRecordingComplete(url, recordedSeconds, false);
          resetTimer();
          setVideoURL(null);
        }
      });
    }
  }, [recorder, videoURL]);

  const handleNext = () => {
    setIsRecording(false);
    const recordedSeconds = totalSecondsRef.current;
    if (videoURL) {
      onRecordingComplete(videoURL, recordedSeconds, false);
      setVideoURL(null);
      resetTimer();
    } else {
      setCurrentQuestionIndex((prevIndex) => {
        if (prevIndex < Questionnaire.length - 1) {
          return prevIndex + 1;
        }
        return prevIndex;
      });
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    const getMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: isCameraEnabled ? true : false,
          audio: true,
        });
        if (videoRef.current && videoElement) {
          videoElement.srcObject = stream;
          videoElement.muted = true;
          videoElement.play();
        }

        const recorderInstance = new RecordRTC(stream, {
          type: 'video',
          mimeType: 'video/webm',
          canvas: { width: 1280, height: 720 },
          // video: {},
        });

        setRecorder(recorderInstance);
      } catch (error) {}
    };

    getMediaStream(); // Get the media stream when the component mounts

    return () => {
      // Cleanup the media stream
      // if (videoElement.srcObject) {
      //   const stream = videoElement.srcObject;
      //   const tracks = stream.getTracks();
      //   tracks.forEach((track) => track.stop()); // Stop all tracks
      // }
    };
  }, [recordedChunks, isCameraEnabled]);

  return (
    <Paper sx={{ height: '100%' }}>
      <Box sx={{ Height: '70%' }}>
        {isCameraEnabled ? (
          <video
            ref={videoRef}
            id='MemoizedVideoPreview'
            playsInline
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              aspectRatio: '16 / 9',
              objectFit: 'cover',
              transform: 'scaleX(-1)',
            }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              minHeight: '20vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AudiotrackIcon
              sx={{
                color: 'white',
                fontSize: 50,
              }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: '95%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
          p: 1,
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Button
            ref={nextButtonRef}
            sx={{ color: '#1206F9' }}
            endIcon={<ArrowBackIosIcon />}
            onClick={handlePreviousQuestion}
            disabled={currentQuestionIndex === 1 || status === 'recording'}
          >
            Prev
          </Button>
          {isRecording ? (
            <Button
              sx={{
                backgroundColor: '#1206F9',
                borderRadius: '30px',
                border: '1px solid #1206F9',
              }}
              variant='contained'
              startIcon={<StopRecordingIcon />}
              onClick={handleStopRecording}
              id='stopRecordingButton'
            >
              Stop
            </Button>
          ) : (
            <Box>
              <Button
                onClick={handleStartRecording}
                sx={{
                  backgroundColor: '#1206F9',
                  borderRadius: '30px',
                }}
                size='small'
                variant='contained'
                startIcon={<StartAssessmentRecordIcon />}
                id='startRecordingButton'
              >
                {isReUpload || videoURL
                  ? 'Re-record My Response'
                  : 'Record My Response'}
              </Button>
            </Box>
          )}
          <Button
            sx={{ color: '#1206F9' }}
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleNext}
            disabled={isRecording || (!isUploaded && !videoURL)}
            id={
              currentQuestionIndex === Questionnaire.length - 1
                ? 'FinishButton'
                : 'NextButton'
            }
          >
            {currentQuestionIndex === Questionnaire.length - 1
              ? 'Finish'
              : 'Next'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

export default PatientVideoIosRecorder;

import {
  Box,
  Grid,
  ListItemText,
  Checkbox,
  Divider,
  useMediaQuery,
  Chip,
} from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {
  FilteredProcessDataList,
  UserWidgetPreference,
} from 'types/Dashboard-types';
import { WidgetData } from 'types/Dashboard-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Loader from 'widgets/Loader/Loader';
import { Toast } from 'widgets/Toast/Toast';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { i18n } from 'i18n/i18n';
import { ProcessDataListVersion2 } from 'types/Gallery.types';
import Button from 'widgets/CustomButton/Button';
import { ProtectedUrls, PublicUrls } from 'routes/urls';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';
import { UploadMediaData } from 'types/Upload-types';

interface Params {
  [key: string]: string;
}
interface FilterOptions {
  fileName: string;
  sessionType: { id: number; name: string };
  userRole: { id: number; name: string };
  fromDate: string;
  toDate: string;
}

function DashboardFilter({
  onFilter,
  processDataListWithSession,
  setProcessDataListWithSession,
  setSelectedWidgets,
  selectedWidgets,
  processDataListVersion2,
}: {
  onFilter: (payload: FilteredProcessDataList) => void;
  processDataListWithSession: Array<ProcessDataListVersion2>;
  setProcessDataListWithSession: (data: ProcessDataListVersion2[]) => void;
  selectedWidgets: Array<WidgetData>;
  setSelectedWidgets: (data: WidgetData[]) => void;
  processDataListVersion2: ProcessDataListVersion2[];
}) {
  const [isopen, setIsOpen] = useState(false);
  const [isWidgetsOpen, setIsWidgetsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [widgetMenuAnchor, setWidgetMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  const [userRolesOptions, setUserRolesOptions] = useState<
    { id: number; name: string }[]
  >([]);
  const [apiKey, setApiKey] = useState('');
  const [env, setEnv] = useState('');
  const [widgetList, setWidgetList] = useState<{ id: number; name: string }[]>(
    []
  );
  const [sessionTypes, setSessionTypes] = useState<
    { id: number; name: string }[]
  >([]);
  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState<FilterOptions>({
      fileName: '',
      sessionType: { id: -1, name: '' },
      userRole: { id: -1, name: '' },
      fromDate: '',
      toDate: '',
    });

  const firstName = sessionStorage.getItem('first_name');
  const LastName = sessionStorage.getItem('last_name');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 1050px)');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  useEffect(() => {
    // Extract sessionId and apiKey from the params object
    const {
      apikey: newApiKey,

      env: envName,
    } = params;

    // Check if the values exist before updating the state

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (envName) {
      setEnv(envName);
    }

    return () => {};
  }, [params]);

  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          PublicUrls.getSessionRoute.url(1)
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL + PublicUrls.getSessionRoute.url(1)
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          PublicUrls.getSessionRoute.url(1)
        }`;
      } else if (env === 'ua') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_UA +
          PublicUrls.getSessionRoute.url(1)
        }`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          PublicUrls.getSessionRoute.url(1)
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          const filteredSessionTypes = response.data.filter(
            (sessionType: { id: number; name: string }) => {
              return sessionType.id === 3 || sessionType.id === 7;
            }
          );

          setSessionTypes(filteredSessionTypes);
        })
        .catch((error) => {});
    }
  }, [apiKey, env]);

  useEffect(() => {
    if (apiKey) {
      let apiUrl = '';

      if (env === 'pd') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else if (env === 'ci') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else if (env === 'qa') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_QA +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      } else if (env === 'ua') {
        apiUrl = `${process.env.REACT_APP_API_BASE_URL_UA}/v2.0/GetWidgetList`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD +
          ProtectedUrls.getWidgetPreferences.url()
        }`;
      }

      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
          },
        })
        .then((response) => {
          setWidgetList(response.data);
        })
        .catch((error) => {});
    }
  }, [apiKey, env]);

  useEffect(() => {
    handleSubmitFilterData();
  }, [selectedFilterOptions]);

  const applySelectedWidgets = (selectedWidgets: WidgetData[]) => {
    const userWidgetPreferenceCopy: UserWidgetPreference = {
      engagement: false,
      energy: false,
      variation: false,
      positivity: false,
      flags: false,
    };

    selectedWidgets.forEach((widget) => {
      userWidgetPreferenceCopy[
        widget.name.trim().toLowerCase() as keyof UserWidgetPreference
      ] = true;
    });

    setLoading(true);

    if (apiKey) {
      const apiUrl = constructUpdatePreferencesUrl(
        userWidgetPreferenceCopy,
        env
      );

      axios
        .post(
          apiUrl,
          {},
          {
            headers: {
              'x-api-key': apiKey,
              Accept: 'application/json',
            },
            params: userWidgetPreferenceCopy,
          }
        )
        .then((response) => {
          Toast(
            i18n.t(
              'toastMessageNotifications.YourWidgetPreferenceHaveBeenUpdated'
            ),
            'success'
          );
          setLoading(false);
        })
        .catch((error) => {});
    }
    setIsWidgetsOpen(false);
  };

  const constructUpdatePreferencesUrl = (
    preferences: UserWidgetPreference,
    env: string
  ) => {
    let apiUrl = '';

    if (env === 'pd') {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL_PD}`;
    } else if (env === 'ci') {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
    } else if (env === 'qa') {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL_QA}`;
    } else if (env === 'ua') {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL_UA}`;
    } else {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL_PD}`;
    }

    return `${
      apiUrl + ProtectedUrls.updateUserWidgetPreference.url(preferences)
    }`;
  };

  const handleClearSelectedFilterOptions = () => {
    setSelectedFilterOptions({
      fileName: '',
      sessionType: { id: -1, name: '' },
      userRole: { id: -1, name: '' },
      fromDate: '',
      toDate: '',
    });
  };

  const handleSubmitFilterData = () => {
    const filteredArray = processDataListVersion2.filter((item) => {
      const fileNameFilter =
        !selectedFilterOptions.fileName ||
        item.session_title
          .toLowerCase()
          .includes(selectedFilterOptions.fileName.toLowerCase());

      const sessionTypeFilter =
        selectedFilterOptions.sessionType.id === -1 ||
        item.session_type_id === selectedFilterOptions.sessionType.id;

      const formattedProcessingDate = new Date(item.processing_start_date_time)
        .toISOString()
        .split('T')[0];
      const fromDateFilter =
        !selectedFilterOptions.fromDate ||
        formattedProcessingDate >= selectedFilterOptions.fromDate;
      const toDateFilter =
        !selectedFilterOptions.toDate ||
        formattedProcessingDate <= selectedFilterOptions.toDate;

      return (
        fileNameFilter && sessionTypeFilter && fromDateFilter && toDateFilter
      );
    });

    setProcessDataListWithSession(filteredArray);
  };

  const handleWidgetSelection = (widget: WidgetData) => {
    if (
      selectedWidgets.some(
        (selectedWidget: WidgetData) => selectedWidget.id === widget.id
      )
    ) {
      setSelectedWidgets(
        selectedWidgets.filter(
          (selectedWidget: WidgetData) => selectedWidget.id !== widget.id
        )
      );
    } else {
      setSelectedWidgets([...selectedWidgets, widget]);
    }
  };

  const handleSet = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleWidgetSet = (event: React.MouseEvent<HTMLElement>) => {
    setIsWidgetsOpen(true);
    setWidgetMenuAnchor(event.currentTarget);
  };

  const clearSelectedWidgets = () => {
    setSelectedWidgets([]);
  };

  const handleDeleteFilterOptions = (key: string) => {
    switch (key) {
      case 'fileName':
      case 'fromDate':
      case 'toDate':
        {
          setSelectedFilterOptions({
            ...selectedFilterOptions,
            [key]: '',
          });
        }
        return;
      case 'userRole': {
        setSelectedFilterOptions({
          ...selectedFilterOptions,
          [key]: { id: -1, name: '' },
        });
        return;
      }

      case 'sessionType': {
        setSelectedFilterOptions({
          ...selectedFilterOptions,
          [key]: { id: -1, name: '' },
          userRole: { id: -1, name: '' },
        });
        return;
      }
    }
  };

  const createAppliedFilterChip = (key: string, text: string) => {
    return (
      <Chip
        label={
          key === 'fromDate' || key === 'toDate'
            ? new Date(text).toLocaleDateString('en-GB')
            : text.length <= 10
            ? text
            : text.slice(0, 10) + '...'
        }
        onDelete={() => handleDeleteFilterOptions(key)}
      />
    );
  };

  const gridConfig = {
    lg: 2,
    md: 6,
    sm: 6,
    xs: 12,
  };

  const handleSessionTypes = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue) {
        setSelectedFilterOptions({
          ...selectedFilterOptions,
          sessionType: newValue,
          userRole: { id: -1, name: '' },
        });

        if (newValue.id === 3) {
          setUserRolesOptions([{ id: 3, name: 'Participant' }]);
        } else if (newValue.id === 7) {
          setUserRolesOptions([
            { id: 5, name: 'Interviewer' },
            { id: 6, name: 'Interviewee' },
          ]);
        } else {
          setUserRolesOptions([]);
        }
      }
    },
    []
  );

  const handleUserRole = useCallback(
    (
      _event: SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue) {
        setSelectedFilterOptions({
          ...selectedFilterOptions,
          userRole: newValue,
        });
      }
    },
    []
  );

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
      }}
    >
      {isMobile || isTablet ? (
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Button
              sx={{
                borderRadius: '40px',
                border: '1px solid #1206F9',
                background: '#FFF',
                color: '#1206F9',
                width: 'fit-content',
              }}
              onClick={handleSet}
              label='dashboard.Filters'
              id='dashboardFilterButton'
              startIcon={<FilterListIcon />}
            />
          </Grid>
          <Grid item xs={7}>
            <Button
              sx={{
                borderRadius: '40px',
                border: '1px solid #1206F9',
                background: '#FFF',
                color: '#1206F9',
                width: 'fit-content',
              }}
              onClick={handleWidgetSet}
              startIcon={<WidgetsIcon />}
              label='dashboard.ManageWidgets'
              id='ManageWidgetButton'
            />

            <Menu
              anchorEl={widgetMenuAnchor}
              open={isWidgetsOpen}
              onClose={() => {
                setIsWidgetsOpen(false);
                setWidgetMenuAnchor(null);
              }}
              anchorReference='anchorEl'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem>
                <Typography
                  sx={{
                    color: '#33344B',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    letterSpacing: '-0.64px',
                  }}
                  label='dashboard.ManageWidgets'
                />
              </MenuItem>
              <MenuItem>
                <Typography
                  sx={{
                    color: '#33344B',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '-0.24px',
                  }}
                  label='dashboard.SelectTheWidgetsThatWouldAppearOnYourDashboard'
                />
              </MenuItem>
              <MenuItem>
                <Typography
                  sx={{
                    color: '#676767',
                    fontFamily: 'Poppins',
                    fontSize: '15px',
                    fontStyle: 'italic',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '-0.24px',
                  }}
                  label='dashboard.MaxLimit3AtATime'
                />
              </MenuItem>
              {<Divider />}
              {widgetList.map((widget: WidgetData) => (
                <MenuItem
                  key={widget.id}
                  onClick={() => handleWidgetSelection(widget)}
                >
                  <ListItemText primary={widget.name} />
                  <Checkbox
                    checked={selectedWidgets.some(
                      (selectedWidget: WidgetData) =>
                        selectedWidget.id === widget.id
                    )}
                    onChange={() => handleWidgetSelection(widget)}
                  />
                </MenuItem>
              ))}

              {<Divider />}
              <MenuItem>
                <Button
                  variant='contained'
                  onClick={() => applySelectedWidgets(selectedWidgets)}
                  sx={{ margin: '16px' }}
                  label='dashboard.ApplyWidgets'
                />

                <Button
                  variant='contained'
                  onClick={clearSelectedWidgets}
                  sx={{ margin: '16px' }}
                  label='dashboard.ClearAll'
                />
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Box sx={{ width: '70%', height: '100%' }}>
            <Typography
              variant='h6'
              sx={{
                color: 'var(--Body-Text, #33344B)',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '-0.22px',
              }}
              label='dashboard.WelcomeBack'
            />
          </Box>
          <Box
            sx={{
              width: '30%',
              height: '100%',
              display: 'flex',
              ml: 'auto',

              gap: '2%',
            }}
          >
            <Button
              id='dashboardFilterButton'
              sx={{
                borderRadius: '40px',
                border: '1px solid #1206F9',
                background: '#FFF',
                color: '#1206F9',
                width: 'auto',
              }}
              onClick={handleSet}
              startIcon={<FilterListIcon />}
              label='dashboard.Filters'
            />

            <>
              <Button
                id='ManageWidgetButton'
                sx={{
                  borderRadius: '40px',
                  border: '1px solid #1206F9',
                  background: '#FFF',
                  color: '#1206F9',
                  width: 'auto',
                }}
                onClick={handleWidgetSet}
                startIcon={<WidgetsIcon />}
                label='dashboard.ManageWidgets'
              />

              <Menu
                anchorEl={widgetMenuAnchor}
                open={isWidgetsOpen}
                onClose={() => {
                  setIsWidgetsOpen(false);
                  setWidgetMenuAnchor(null);
                }}
                anchorReference='anchorEl'
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem>
                  <Typography
                    sx={{
                      color: '#33344B',
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      letterSpacing: '-0.64px',
                    }}
                    label='dashboard.ManageWidgets'
                  />
                </MenuItem>
                <MenuItem>
                  <Typography
                    sx={{
                      color: '#33344B',
                      fontFamily: 'Poppins',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '-0.24px',
                    }}
                    label='dashboard.SelectTheWidgetsThatWouldAppearOnYourDashboard'
                  />
                </MenuItem>
                <MenuItem>
                  <Typography
                    sx={{
                      color: '#676767',
                      fontFamily: 'Poppins',
                      fontSize: '15px',
                      fontStyle: 'italic',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '-0.24px',
                    }}
                    label='dashboard.MaxLimit3AtATime'
                  />
                </MenuItem>
                {<Divider />}
                {widgetList.map((widget: WidgetData) => (
                  <MenuItem
                    key={widget.id}
                    onClick={() => handleWidgetSelection(widget)}
                  >
                    <ListItemText primary={widget.name} />
                    <Checkbox
                      checked={selectedWidgets.some(
                        (selectedWidget: WidgetData) =>
                          selectedWidget.id === widget.id
                      )}
                      onChange={() => handleWidgetSelection(widget)}
                    />
                  </MenuItem>
                ))}

                {<Divider />}
                <MenuItem>
                  <Button
                    variant='contained'
                    onClick={() => applySelectedWidgets(selectedWidgets)}
                    sx={{ margin: '16px' }}
                    label='dashboard.ApplyWidgets'
                  />

                  <Button
                    variant='contained'
                    onClick={clearSelectedWidgets}
                    sx={{ margin: '16px' }}
                    label='dashboard.ClearAll'
                  />
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
      )}

      {isopen ? (
        <Box
          sx={{
            width: '100%',
            height: 'auto',

            background: '#fff',
          }}
        >
          <Typography
            variant='h6'
            sx={{ marginLeft: '5%' }}
            label='dashboard.Filters'
          />

          <Box sx={{ width: '100%', height: 'auto', p: 3 }}>
            <Grid container spacing={3}>
              <Grid
                item
                lg={gridConfig.lg}
                md={gridConfig.md}
                sm={gridConfig.sm}
                xs={gridConfig.xs}
              >
                <TextField
                  id='FileName'
                  label='dashboard.FileName'
                  fullWidth
                  variant='outlined'
                  value={selectedFilterOptions.fileName}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => {
                    if (event && event.target) {
                      setSelectedFilterOptions({
                        ...selectedFilterOptions,
                        fileName: event.target.value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                lg={gridConfig.lg}
                md={gridConfig.md}
                sm={gridConfig.sm}
                xs={gridConfig.xs}
              >
                <Autocomplete
                  id='combo-box-session-types'
                  options={sessionTypes}
                  optionLabelKey='name'
                  label={'record.SessionType'}
                  value={selectedFilterOptions.sessionType}
                  onChange={handleSessionTypes}
                  renderInput={(params) => (
                    <TextField {...params} label='' sx={{ width: '100%' }} />
                  )}
                  getOptionLabel={(option: UploadMediaData) => option.name}
                />
              </Grid>
              <Grid
                item
                lg={gridConfig.lg}
                md={gridConfig.md}
                sm={gridConfig.sm}
                xs={gridConfig.xs}
              >
                <Autocomplete
                  id='combo-box-User-Roles'
                  options={userRolesOptions}
                  label='User Role'
                  optionLabelKey='name'
                  value={selectedFilterOptions.userRole}
                  onChange={handleUserRole}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=''
                      sx={{ width: '100% !important' }}
                    />
                  )}
                  getOptionLabel={(option: UploadMediaData) => option.name}
                />
              </Grid>
              <Grid
                item
                lg={gridConfig.lg}
                md={gridConfig.md}
                sm={gridConfig.sm}
                xs={gridConfig.xs}
              >
                <TextField
                  fullWidth
                  variant='outlined'
                  id='FromDate'
                  type='date'
                  value={selectedFilterOptions.fromDate}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => {
                    if (event && event.target) {
                      setSelectedFilterOptions({
                        ...selectedFilterOptions,
                        fromDate: event.target.value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                lg={gridConfig.lg}
                md={gridConfig.md}
                sm={gridConfig.sm}
                xs={gridConfig.xs}
              >
                <TextField
                  fullWidth
                  variant='outlined'
                  type='date'
                  id='ToDate'
                  value={selectedFilterOptions.toDate}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => {
                    if (event && event.target) {
                      setSelectedFilterOptions({
                        ...selectedFilterOptions,
                        toDate: event.target.value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                lg={gridConfig.lg}
                md={gridConfig.md}
                sm={gridConfig.sm}
                xs={gridConfig.xs}
                sx={{
                  display: 'flex',

                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  variant='contained'
                  onClick={handleSubmitFilterData}
                  sx={{ margin: '16px' }}
                  label='dashboard.Apply'
                />

                <Button
                  variant='contained'
                  onClick={handleClearSelectedFilterOptions}
                  sx={{ margin: '16px' }}
                  label='dashboard.ClearAll'
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}
      {selectedFilterOptions.fileName !== '' ||
      selectedFilterOptions.sessionType.id !== -1 ||
      selectedFilterOptions.userRole.id !== -1 ||
      selectedFilterOptions.fromDate !== '' ||
      selectedFilterOptions.toDate !== '' ? (
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            background: '#fff',
            alignItems: 'center',
            gap: 1,
            p: 1,
          }}
        >
          <Typography
            sx={{
              color: '#676767',
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '17px',
            }}
            label='dashboard.FiltersApplied'
          />

          {Object.entries(selectedFilterOptions).map(
            ([key, value]: [string, FilterOptions[keyof FilterOptions]]) => {
              switch (key) {
                case 'fileName':
                case 'fromDate':
                case 'toDate':
                  if (typeof value === 'string' && value !== '')
                    return createAppliedFilterChip(key, value);
                  else return null;
                case 'userRole':
                case 'sessionType':
                  if (typeof value === 'object' && value.id !== -1)
                    return createAppliedFilterChip(key, value.name);
                  else return null;
                default:
                  if (
                    (typeof value === 'string' || typeof value === 'string') &&
                    value
                  )
                    return createAppliedFilterChip(key, value);
                  else return null;
              }
            }
          )}
        </Box>
      ) : null}
    </Box>
  );
}

export default DashboardFilter;

/** @format */

import * as React from 'react';
import Button from 'widgets/CustomButton/Button';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { getSessionTypesSelector } from 'redux/selectors/selectors';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getSessionTypesServiceApi } from 'services/UploadMedia-services';
import { SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from 'widgets/TextField/TextField';
import Typography from 'widgets/Typography/Typography';
import Autocomplete from 'widgets/AutoComplete/AutocompleteComponent';

interface ChildModalProps {
  openReprocessChildVideoModal: boolean;
  handleCloseReprocessChildVideoModal: () => void;
  onReprocessConfirmation: () => void;
  setReprocessVideoData: React.Dispatch<
    SetStateAction<{
      video_title: string;
      metrics_processing_events_history_id_list: number;
      sessionDetails: { id: number; name?: string };
      text_analysis: boolean;
      video_analysis: boolean;
    }>
  >;
  reprocessVideoData: any;
  SessionTypes: Array<{
    id: number;
    name: string;
  }>;
  isBulkReprocess: boolean;
}

function ChildModal({
  openReprocessChildVideoModal,
  handleCloseReprocessChildVideoModal,
  onReprocessConfirmation,
  setReprocessVideoData,
  reprocessVideoData,
  SessionTypes,
  isBulkReprocess,
}: ChildModalProps) {
  const handleSessionTypes = useCallback(
    (
      _event: React.SyntheticEvent<Element, Event>,
      newValue: { id: number; name: string } | null
    ) => {
      if (newValue)
        setReprocessVideoData({
          ...reprocessVideoData,
          sessionDetails: {
            id: newValue.id,
            name: newValue.name,
          },
        });
    },
    [reprocessVideoData, setReprocessVideoData]
  );

  return (
    <Dialog
      open={openReprocessChildVideoModal}
      onClose={handleCloseReprocessChildVideoModal}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle sx={{ fontFamily: 'Poppins' }}>
        <Typography
          label={
            isBulkReprocess
              ? 'Select Session Type'
              : 'gallery.SelectSessionTitleAndType'
          }
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'
          sx={{
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          {!isBulkReprocess && (
            <TextField
              onChange={(e) =>
                setReprocessVideoData({
                  ...reprocessVideoData,
                  video_title: e.target.value,
                })
              }
              value={reprocessVideoData.video_title}
              variant='outlined'
              label={'gallery.VideoTitle'}
              InputProps={{
                style: {
                  borderRadius: '35px',
                  fontFamily: 'Poppins',
                },
              }}
              size='medium'
            />
          )}
          <Autocomplete
            disablePortal
            id='combo-box-demo'
            options={SessionTypes}
            optionLabelKey='name'
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                sx={{ width: '230px !important' }}
                {...params}
                label={'gallery.SessionTypes'}
              />
            )}
            onChange={handleSessionTypes}
            value={reprocessVideoData.sessionDetails}
            fullWidth
            label=''
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          id='reprocessSessionYes'
          sx={{ fontFamily: 'Poppins', backgroundColor: '#1206f9' }}
          onClick={onReprocessConfirmation}
          label='gallery.Ok'
        />

        <Button
          id='reprocessSessionNo'
          variant='contained'
          sx={{ fontFamily: 'Poppins', backgroundColor: '#1206f9' }}
          onClick={handleCloseReprocessChildVideoModal}
          label='gallery.Cancel'
        />
      </DialogActions>
    </Dialog>
  );
}
interface VideoProcessingInterface {
  openReprocessVideoModal: boolean;
  handleCloseReprocessVideoModal: () => void;
  onReprocessConfirmation: () => void;
  setReprocessVideoData: React.Dispatch<
    SetStateAction<{
      video_title: string;
      metrics_processing_events_history_id_list: number;
      sessionDetails: { id: number; name?: string };
      text_analysis: boolean;
      video_analysis: boolean;
    }>
  >;
  reprocessVideoData: {
    video_title: string;
    sessionDetails: {
      id: number;
    };
    text_analysis: boolean;
    video_analysis: boolean;
  };
  handleOpenReprocessChildVideoModal: () => void;
  openReprocessChildVideoModal: boolean;
  handleCloseReprocessChildVideoModal: () => void;
  isBulkReprocess: boolean;
}

export default function NestedModal({
  openReprocessVideoModal,
  handleCloseReprocessVideoModal,
  onReprocessConfirmation,
  setReprocessVideoData,
  reprocessVideoData,
  handleOpenReprocessChildVideoModal,
  openReprocessChildVideoModal,
  handleCloseReprocessChildVideoModal,
  isBulkReprocess,
}: VideoProcessingInterface) {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getSessionTypesServiceApi(-1));
  }, [dispatch]);
  const { SessionTypes } = useAppSelector(getSessionTypesSelector);

  return (
    <div>
      <Dialog
        id='reprocessDialog'
        open={openReprocessVideoModal}
        onClose={handleCloseReprocessVideoModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText
            sx={{ fontFamily: 'Poppins' }}
            id='alert-dialog-description'
          >
            <Typography label='gallery.AreyouSureYouWantToReprocessThisFile' />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            id='reprocessYes'
            sx={{
              fontFamily: 'Poppins',
              backgroundColor: '#1206f9',
              color: '#fff',
            }}
            onClick={handleOpenReprocessChildVideoModal}
            label='gallery.Yes'
          />

          <Button
            id='reprocessNo'
            variant='contained'
            sx={{
              fontFamily: 'Poppins',
              backgroundColor: '#1206f9',
              color: '#fff',
            }}
            onClick={handleCloseReprocessVideoModal}
            label='gallery.No'
          />
        </DialogActions>
      </Dialog>
      <ChildModal
        openReprocessChildVideoModal={openReprocessChildVideoModal}
        handleCloseReprocessChildVideoModal={
          handleCloseReprocessChildVideoModal
        }
        onReprocessConfirmation={onReprocessConfirmation}
        setReprocessVideoData={setReprocessVideoData}
        reprocessVideoData={reprocessVideoData}
        SessionTypes={SessionTypes}
        isBulkReprocess={isBulkReprocess}
      />
    </div>
  );
}

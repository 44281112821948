/** @format */
import { combineReducers } from 'redux';
import {
  getAuthProviderDetailsSlice,
  getLoginDataSlice,
  getMicrosoftLoginDataSlice,
  getMicrosoftSignUpDataSlice,
  getSignUpDataSlice,
  requestProfileDataSlice,
} from 'redux/slices/Auth-slice';

import { getBillingDataSlice } from 'redux/slices/Billing-slice';
import {
  getFeatureFlagsSlice,
  updateFeatureFlagsSlice,
} from 'redux/slices/FeatureFlags-slice';
import {
  getAIPermissionsSlice,
  getSessionTypesSlice,
  uploadMultipleFilesSlice,
  uploadSingleFileSlice,
  uploadVideoByLinkSlice,
} from 'redux/slices/UploadMedia-slice';

import { getProcessDataListForParticipantsSlice } from 'redux/slices/VideoPreview-slice';
import {
  deleteProcessVideoSlice,
  getProcessDataListSlice,
  getReprocessVideoSlice,
} from 'redux/slices/Gallery-slice';
import { globalSearchSlice } from 'redux/slices/Global-Search-slice';

import {
  getUserDetailsAsUserSlice,
  sendForgetPasswordMailSlice,
  updatePasswordSlice,
  updateUserDetailsSlice,
} from 'redux/slices/UserDetails-slice';
import {
  getAvailableSubscriptionPlansSlice,
  getStripeSessionIdSlice,
  getSubscriptionSlice,
  validateCouponCodeSlice,
} from 'redux/slices/Subscriptions-slice';
import {
  deleteProcessVideoV2Slice,
  getProcessDataListVersion2Slice,
  getReprocessVersion2VideoSlice,
} from 'redux/slices/GalleryVersion2-slice';
import { getEmotionTypeSlice } from 'redux/slices/Emotion-slice';
import {
  UpdateSummarySlice,
  getProcessDataListForParticipantsVersion2Slice,
  getProcessStatusSlice,
  getReportSlice,
  getTranscriptDataSlice,
  handleUpdateVideoTitleForIframeSlice,
  updateVideoTitleSlice,
} from 'redux/slices/videoPreviewVersion2-slice';
import { knowledgeTipsSlice } from 'redux/slices/KnoledgeTips-slice';
import { acceptCookiesSlice } from 'redux/slices/Cookies-slice';
import { setForgetPasswordSlice } from 'redux/slices/ForgetPassword-slice';
import {
  GoogleSSOSignInSlice,
  GoogleSSOSlice,
} from 'redux/slices/GoogleSSO-slice';
import {
  globalTranslationsForTestCasesSlice,
  loadTranslationSlice,
} from 'redux/slices/UnifiedStringsLoading-slice';
import { UserRolesSlice } from 'redux/slices/userRoles-slice';
import {
  UserWidgetPreferenceSlice,
  getWidgetListSlice,
  updateUserWidgetPreferenceSlice,
} from 'redux/slices/UserPreference-slice';
import { handleFeedbackSubmitSlice } from 'redux/slices/Feedback-slice';
import {
  allPromptsSectionsStatusSlice,
  getPractiseSectionsSlice,
  sectionUploadStatusSlice,
  uploadSectionVideoSlice,
} from 'redux/slices/MyAssessment-slice';
import {
  candidateAssignmentSubmissionCheckSlice,
  sendAssessmentEmailToCandidatesSlice,
} from 'redux/slices/SendAssessment-slice';
import {
  getPositionIdentifierSlice,
  addPositionIdentifierSlice,
  getTagsSlice,
  addTagsSlice,
  handleSubmitJobCreationFormSlice,
  drJobCreationVariablesSlice,
} from 'redux/slices/DeepRecruiter/JobCreationForm-slice';
import {
  createAssignmentSlice,
  getAssessmentListSlice,
} from 'redux/slices/DeepRecruiter/AssessmentSelection-slice';
import {
  SendMailToUserSlice,
  addUserToAssignmentListSlice,
} from 'redux/slices/DeepRecruiter/InviteCandidateForInterview-slice';
import {
  UpdateJobStatusSlice,
  deleteRecordSlice,
  getRecruiterRecordSlice,
  getRecruiterWidgetDataSlice,
} from 'redux/slices/DeepRecruiter/RecruiterDashboard-slice';
import { deepCandidateSignUpDataSlice } from 'redux/slices/DeepRecruiter/deepCandidate-slice';
import {
  FacebookSSOSignInSlice,
  FacebookSSOSignOutSlice,
} from 'redux/slices/FacebookSSO-slice';
import {
  VerifyOTPSlice,
  VisitorFeedbackSlice,
  sendOtpToUserSlice,
} from 'redux/slices/Preview/UserValidation-slice';
import { getRecoveryDashboardDataSlice } from 'redux/slices/PatientWorkFlow/RecoveryDashboard-slice';
export const rootReducer = combineReducers({
  getLoginDataReducer: getLoginDataSlice.reducer,
  getSignUpDataReducer: getSignUpDataSlice.reducer,
  getFeatureFlagsReducer: getFeatureFlagsSlice.reducer,
  updateFeatureFlagsReducer: updateFeatureFlagsSlice.reducer,
  getMicrosoftLoginDataReducer: getMicrosoftLoginDataSlice.reducer,
  getMicrosoftSignUpDataReducer: getMicrosoftSignUpDataSlice.reducer,
  getUploadMediaReducer: getSessionTypesSlice.reducer,
  getBillingReducer: getBillingDataSlice.reducer,
  getProcessDataListReducer: getProcessDataListSlice.reducer,
  getSessionTypesSReducer: getSessionTypesSlice.reducer,
  getProcessDataListForParticipantReducer:
    getProcessDataListForParticipantsSlice.reducer,
  getReprocessVideoReducer: getReprocessVideoSlice.reducer,
  deleteProcessVideoReducer: deleteProcessVideoSlice.reducer,
  getAuthProviderDetailsReducer: getAuthProviderDetailsSlice.reducer,
  uploadSingleFileReducer: uploadSingleFileSlice.reducer,
  uploadMultipleFilesReducer: uploadMultipleFilesSlice.reducer,
  globalSearchReducer: globalSearchSlice.reducer,
  getUserDetailsAsUserReducer: getUserDetailsAsUserSlice.reducer,
  updateUserDetailsReducer: updateUserDetailsSlice.reducer,
  getSubscriptionReducer: getSubscriptionSlice.reducer,
  getStripeSessionIdReducer: getStripeSessionIdSlice.reducer,
  getAvailableSubscriptionPlansReducer:
    getAvailableSubscriptionPlansSlice.reducer,
  getProcessDataListVersion2Reduce: getProcessDataListVersion2Slice.reducer,
  getReprocessVersion2VideoReduce: getReprocessVersion2VideoSlice.reducer,
  getEmotionTypeReduce: getEmotionTypeSlice.reducer,
  getProcessDataListForParticipantsVersion2Reduce:
    getProcessDataListForParticipantsVersion2Slice.reducer,
  KnowledgeTipsReduce: knowledgeTipsSlice.reducer,
  uploadVideoByLinkReducer: uploadVideoByLinkSlice.reducer,
  acceptCookiesReducer: acceptCookiesSlice.reducer,
  deleteProcessVideoV2reducer: deleteProcessVideoV2Slice.reducer,
  setForgetPasswordReducer: setForgetPasswordSlice.reducer,
  updatePasswordReducer: updatePasswordSlice.reducer,
  sendForgetPasswordMailReducer: sendForgetPasswordMailSlice.reducer,
  validateCouponCodeReducer: validateCouponCodeSlice.reducer,
  googleSSOReducer: GoogleSSOSlice.reducer,
  googleSSOSignInReducer: GoogleSSOSignInSlice.reducer,
  loadTranslationReducer: loadTranslationSlice.reducer,
  UserRolesReducer: UserRolesSlice.reducer,
  UserWidgetPreferenceReducer: UserWidgetPreferenceSlice.reducer,
  getWidgetListReducer: getWidgetListSlice.reducer,
  updateUserWidgetPreferenceReducer: updateUserWidgetPreferenceSlice.reducer,
  updateVideoTitleReducer: updateVideoTitleSlice.reducer,
  getProcessStatusReducer: getProcessStatusSlice.reducer,
  handleFeedbackSubmitReducer: handleFeedbackSubmitSlice.reducer,
  handleUpdateVideoTitleForIframeReducer:
    handleUpdateVideoTitleForIframeSlice.reducer,
  requestProfileDataReducer: requestProfileDataSlice.reducer,
  getPractiseSectionsReducer: getPractiseSectionsSlice.reducer,
  uploadSectionVideoReducer: uploadSectionVideoSlice.reducer,
  allPromptsSectionsStatusReducer: allPromptsSectionsStatusSlice.reducer,
  sectionUploadStatusReducer: sectionUploadStatusSlice.reducer,
  globalTranslationsForTestCasesReducer:
    globalTranslationsForTestCasesSlice.reducer,
  sendAssessmentEmailToCandidatesReducer:
    sendAssessmentEmailToCandidatesSlice.reducer,
  candidateAssignmentSubmissionCheckReducer:
    candidateAssignmentSubmissionCheckSlice.reducer,
  getPositionIdentifierReducer: getPositionIdentifierSlice.reducer,
  addPositionIdentifierReducer: addPositionIdentifierSlice.reducer,
  getTagsReducer: getTagsSlice.reducer,
  addTagsReducer: addTagsSlice.reducer,
  handleSubmitJobCreationFormReducer: handleSubmitJobCreationFormSlice.reducer,
  getAssessmentListSliceReducer: getAssessmentListSlice.reducer,
  drJobCreationVariablesReducer: drJobCreationVariablesSlice.reducer,
  createAssignmentReducer: createAssignmentSlice.reducer,
  addUserToAssignmentListReducer: addUserToAssignmentListSlice.reducer,
  getRecruiterRecordReducer: getRecruiterRecordSlice.reducer,
  UpdateJobStatusReducer: UpdateJobStatusSlice.reducer,

  deleteRecordReducer: deleteRecordSlice.reducer,
  deepCandidateSignUpDataReducer: deepCandidateSignUpDataSlice.reducer,
  SendMailToUserReducer: SendMailToUserSlice.reducer,
  facebookSSOSignInReducer: FacebookSSOSignInSlice.reducer,
  facebookSSOSignOutReducer: FacebookSSOSignOutSlice.reducer,
  getRecruiterWidgetDataReducer: getRecruiterWidgetDataSlice.reducer,

  sendOtpToUserReducer: sendOtpToUserSlice.reducer,
  VerifyOTPReducer: VerifyOTPSlice.reducer,
  VisitorFeedbackReducer: VisitorFeedbackSlice.reducer,
  getTranscriptDataReducer: getTranscriptDataSlice.reducer,
  getAIPermissionsReducer: getAIPermissionsSlice.reducer,
  getRecoveryDashboardDataReducer: getRecoveryDashboardDataSlice.reducer,
  getReportReducer: getReportSlice.reducer,
  UpdateSummaryReducer: UpdateSummarySlice.reducer,
});

import { Box } from '@mui/material';
import { PatientWorkFlowStepComponentList } from 'constants/PatientWorkflow';
import React, { useEffect, useState } from 'react';
import {
  DeviceInfo,
  QuestionnaireData,
} from 'types/TeleHealth/PatientWorkflow-types';
import { useLocation } from 'react-router-dom';
import { ProtectedUrls } from 'routes/urls';
import axios from 'axios';
import Loader from 'widgets/Loader/Loader';
import StepComponent from './StepComponent';
import { CameraDetails, MicrophoneDetails } from 'types/MyAssessments-types';

interface Params {
  [key: string]: string;
}

function PatientWorkFlowComponent() {
  const [currentPatientWorkFlowStep, setCurrentPatientWorkFlowStep] =
    useState<PatientWorkFlowStepComponentList>(
      PatientWorkFlowStepComponentList.Quesionnaire
    );

  const [videoDevices, setVideoDevices] = useState<DeviceInfo[]>([]);
  const [audioDevices, setAudioDevices] = useState<DeviceInfo[]>([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<CameraDetails>(
    { deviceId: '', kind: '', label: '', groupId: '' }
  );
  const [selectedAudioDevice, setSelectedAudioDevice] =
    useState<MicrophoneDetails>({
      deviceId: '',
      kind: '',
      label: '',
      groupId: '',
    });

  const [apiKey, setApiKey] = useState('');
  const [patientID, setPatientID] = useState('');
  const [providerID, setProviderID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Questionnaire, setQuestionnair] = useState<Array<QuestionnaireData>>(
    []
  );
  const [sessionId, setSessionID] = useState(58);
  const [emoticonSwitch, setEmoticonSwitch] = useState(1);
  const [tipsSwitch, setTipsSwitch] = useState(1);
  const [folder, setFolder] = useState('');
  const [customfilename, setCustomFileName] = useState('');

  useEffect(() => {
    let apiUrl;
    if (apiKey) {
      setLoading(true);
      if (window.location.origin !== 'https://personal.mirro.ai') {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL
        }/${ProtectedUrls.getPractiseSections.url(sessionId)}`;
      } else {
        apiUrl = `${
          process.env.REACT_APP_API_BASE_URL_PD
        }/${ProtectedUrls.getPractiseSections.url(sessionId)}`;
      }
      axios
        .get(apiUrl, {
          headers: {
            'x-api-key': apiKey,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          setQuestionnair(response.data);

          setLoading(false);
        })
        .catch((error) => {});
    }
  }, [apiKey, sessionId]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params: Params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  const {
    sessionid: newSessionId,
    apikey: newApiKey,
    company: folderName,
    emoticons: emoticonsSwitchValue,
    expert_tips: tipsSwitchValue,
    providerID: newproviderID,
    patientID: newpatientID,
    customTitle: newcustomfilename,
  } = params;

  useEffect(() => {
    if (newSessionId) {
      setSessionID(Number(newSessionId));
    }

    if (newApiKey) {
      setApiKey(newApiKey);
    }

    if (folderName) {
      setFolder(folderName);
    }

    if (emoticonsSwitchValue) {
      setEmoticonSwitch(Number(emoticonsSwitchValue));
    }
    if (tipsSwitchValue) {
      setTipsSwitch(Number(tipsSwitchValue));
    }
    if (newproviderID) {
      setProviderID(Number(newproviderID));
    }

    if (newpatientID) {
      setPatientID(newpatientID);
    }
    if (newcustomfilename) {
      setCustomFileName(newcustomfilename);
    }

    return () => {
      setEmoticonSwitch(1);
      setTipsSwitch(1);
    };
  }, [params]);
  const goToPatientWorkflowStep = (step: PatientWorkFlowStepComponentList) => {
    setCurrentPatientWorkFlowStep(step);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ m: '2%' }}>
          <StepComponent
            currentPatientWorkflowStep={currentPatientWorkFlowStep}
            goToPatientWorkflowStep={goToPatientWorkflowStep}
            videoDevices={videoDevices}
            audioDevices={audioDevices}
            selectedVideoDevice={selectedVideoDevice}
            selectedAudioDevice={selectedAudioDevice}
            setSelectedVideoDevice={setSelectedVideoDevice}
            setSelectedAudioDevice={setSelectedAudioDevice}
            setVideoDevices={setVideoDevices}
            setAudioDevices={setAudioDevices}
            apiKey={apiKey}
            Questionnaire={Questionnaire}
            patientID={patientID}
            providerID={providerID}
            sessionId={sessionId}
            customfilename={customfilename}
          />
        </Box>
      )}
    </>
  );
}

export default PatientWorkFlowComponent;

/** @format */

import TeleHealthDashboardComponent from 'components/TeleHealthDashboard/TeleHealthDashboard';
import React from 'react';

function TeleHealthDashboard() {
  return <TeleHealthDashboardComponent />;
}

export default TeleHealthDashboard;

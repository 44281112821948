/** @format */

import {
  Box,
  Paper,
  Table,
  TableContainer,
  TablePagination,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TeleHealthProcessDataTableBody from './TeleHealthProcessDataTableBody';
import TeleHealthTableToolbar from './TeleHealthTableToolbar';
import TeleHealthTableHead from './TeleHealthTableHead';
import useTeleHealthProcessDataListTable from './hook/useTeleHealthProcessDataListTable';
import Loader from 'widgets/Loader/Loader';
import { RecoveryDashboardData } from 'types/TeleHealth/RecoveryDashboard-types';

function TeleHealthProcessDataListTable({
  TableReceivedData,
}: {
  TableReceivedData: Array<RecoveryDashboardData>;
}) {
  const {
    handleRequestSort,
    handleChangeRowsPerPage,
    handleChangePage,
    formatDate,
    rows,
    page,
    emptyRows,
    rowsPerPage,
    visibleRows,
    order,
    orderBy,
  } = useTeleHealthProcessDataListTable(TableReceivedData);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [SearchFilter, setSearchFilter] = useState<String>('');
  const [commentSection, setCommentSection] = useState<Record<number, string>>(
    {}
  );
  const [Continuity, setContinuity] = useState<
    Record<number, number | boolean>
  >({});

  useEffect(() => {
    setCommentSection(
      rows.reduce((acc, row) => ({ ...acc, [row.id]: row.Action_Taken }), {})
    );

    setContinuity(
      rows.reduce((acc, row) => ({ ...acc, [row.id]: row.Continuity }), {})
    );
  }, [rows]);

  const hasSelectedAll = selectedRows.length === TableReceivedData.length;

  const handelSearchFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchFilter(event.target.value);
  };

  function handleOnChange(session_id: number) {
    setSelectedRows((selectedRows) => {
      const selectedIndex = selectedRows.indexOf(session_id);
      if (selectedIndex === -1) {
        return [...selectedRows, session_id];
      } else {
        const updatedSelectedRows = [...selectedRows];
        updatedSelectedRows.splice(selectedIndex, 1);
        return updatedSelectedRows;
      }
    });
  }

  function handleSelectAll() {
    if (selectedRows.length < TableReceivedData.length) {
      setSelectedRows([
        ...new Set(
          TableReceivedData.map((row) => row.coaching_session_reports_id)
        ),
      ]);
    }
    if (selectedRows.length === TableReceivedData.length) {
      setSelectedRows([]);
    }
  }

  function handleCommentSave(id: number) {}

  if (TableReceivedData.length === 0) {
    return <Loader />;
  }

  return (
    <Box>
      <Paper
        sx={{
          overflow: 'hidden',
          minHeight: '100vh',
          height: '100%',
          width: '100%',
        }}
      >
        <TeleHealthTableToolbar
          SearchFilter={SearchFilter}
          handleSearchOnchange={handelSearchFilter}
          selectedRows={selectedRows}
        />
        <TableContainer
          sx={{
            minHeight: '100vh',
            height: '100%',
            maxWidth: '92vw',
            width: '100%',
            overflow: 'auto',
          }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            size='small'
          >
            <TeleHealthTableHead
              handleSelectAll={handleSelectAll}
              hasSelectedAll={hasSelectedAll}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TeleHealthProcessDataTableBody
              selectRowId={selectedRows}
              handleOnChange={handleOnChange}
              TableReceivedData={visibleRows}
              commentSection={commentSection}
              setCommentSection={setCommentSection}
              handleCommentSave={handleCommentSave}
              Continuity={Continuity}
              setContinuity={setContinuity}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default TeleHealthProcessDataListTable;

/** @format */

import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  TableBody,
  TableRow,
  TableCell,
  Box,
  IconButton,
  Tooltip,
  Collapse,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  DashboardRowData,
  CurrencyOption,
  ActionPlan,
  RecoveryDashboardTableBodyProps,
} from 'types/TeleHealth/RecoveryDashboard-types';
import Typography from 'widgets/Typography/Typography';

const currencies: CurrencyOption[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

function TeleHealthProcessDataTableBody({
  TableReceivedData,
  selectRowId,
  handleOnChange,
  commentSection,
  setCommentSection,
  handleCommentSave,
  setContinuity,
  Continuity,
}: RecoveryDashboardTableBodyProps) {
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});
  const [focusedRowId, setFocusedRowId] = useState<number | null>(null);

  const handleContinuityOnChange = (id: number, value: string) => {
    setContinuity({ ...Continuity, [id]: value === 'yes' });
  };

  const toggleRowExpansion = (index: number) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleCommentSection = (
    id: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCommentSection({ ...commentSection, [id]: e.target.value });
  };

  const handleCommentDiscardChanges = (id: number) => {
    const row = TableReceivedData?.find((row) => row.id === id);
    if (row) {
      setCommentSection({ ...commentSection, [id]: row.Action_Taken });
    }
  };

  const handleIconMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  function formatActionPlan(actionPlan: ActionPlan): string {
    return `
      ${actionPlan.short_term_goals?.join(', ') || ''}
      ${actionPlan.emotional_support?.join(', ') || ''}
      ${actionPlan.immediate_actions?.join(', ') || ''}
      ${actionPlan.progress_monitoring?.join(', ') || ''}
      ${actionPlan.long_term_strategies?.join(', ') || ''}
    `.trim();
  }

  return (
    <TableBody>
      {TableReceivedData.map((row: DashboardRowData, index: number) => {
        const isExpanded = expandedRows[index] || false;
        return (
          <TableRow key={row.id}>
            <TableCell align='center'>
              <Checkbox
                checked={selectRowId.includes(row.id)}
                onChange={() => handleOnChange(row.id)}
                role='checkbox'
                aria-checked={selectRowId.includes(row.id)}
              />
            </TableCell>
            <TableCell align='center'>
              <div style={{ maxWidth: '150px', width: '400px' }}>
                {row.Sessions}
              </div>
            </TableCell>
            <TableCell align='center'>
              <div style={{ maxWidth: '150px', width: '400px' }}>
                {row.Date_Time}
              </div>
            </TableCell>
            <TableCell align='center'>
              <div style={{ maxWidth: '170px', width: '400px' }}>
                {row.Continuity_Risk}
              </div>
            </TableCell>
            <TableCell align='center'>
              <div style={{ maxWidth: '275px', width: '400px' }}>
                <Typography
                  label={formatActionPlan(row.Action_Plan)}
                  sx={{ fontSize: '10px' }}
                />
              </div>
            </TableCell>
            <TableCell align='center'>
              <TextField
                sx={{
                  width: '240px',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-input': {
                    padding: 0,
                    position: 'relative',
                    bottom: 13.5,
                  },
                  '& ::-webkit-scrollbar': { width: '6px' },
                  '& ::-webkit-scrollbar-thumb': {
                    backgroundColor: '#D9D9D9',
                    borderRadius: '4px',
                  },
                  '& ::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                }}
                multiline
                value={commentSection[row.id]}
                onChange={(e) => handleCommentSection(row.id, e)}
                onFocus={() => setFocusedRowId(row.id)}
                onBlur={() => setFocusedRowId(null)}
                rows={5}
                variant='outlined'
                placeholder='Comment/Note'
                InputProps={{
                  sx: { fontSize: '0.7rem', height: '115px' },
                  endAdornment:
                    focusedRowId === row.id ? (
                      <InputAdornment position='end'>
                        <Box
                          sx={{ position: 'relative', bottom: 12, right: 10 }}
                        >
                          <div style={{ display: 'flex', gap: 2 }}>
                            <IconButton
                              onClick={() => handleCommentSave(row.id)}
                              color='primary'
                              size='small'
                              onMouseDown={handleIconMouseDown}
                            >
                              <DoneIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleCommentDiscardChanges(row.id)
                              }
                              color='primary'
                              onMouseDown={handleIconMouseDown}
                              size='small'
                            >
                              <CloseIcon
                                sx={{ fontSize: '20px', color: 'red' }}
                              />
                            </IconButton>
                          </div>
                        </Box>
                      </InputAdornment>
                    ) : null,
                }}
              />
            </TableCell>
            <TableCell align='center'>
              <Select
                value={row.Continuity ? 'yes' : 'no'}
                onChange={(e) =>
                  handleContinuityOnChange(row.id, e.target.value as string)
                }
                sx={{
                  minWidth: 92,
                  color: row.Continuity ? '#EA3323' : '#62C960',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: row.Continuity ? '#EA3323' : '#62C960',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: row.Continuity ? '#EA3323' : '#62C960',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: row.Continuity ? '#EA3323' : '#62C960',
                  },
                }}
                size='small'
              >
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.emotion}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.engagement}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.positivity}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.Green}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.Yellow}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.Red}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.confidentTone}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.stressedTone}
            </TableCell>
            <TableCell
              sx={{
                wordWrap: 'break-word',

                padding: 2,
              }}
              align='center'
            >
              {row.fatiguedTone}
            </TableCell>

            {/* {isExpanded && (
              <TableRow
                sx={{
                  backgroundColor: '#F6FCFF',
                }}
              >
                <TableCell colSpan={17}>
                  <Collapse in={isExpanded}>
                    <TeleHealthDetailsTable DetailsPanelData={row.details} />
                  </Collapse>
                </TableCell>
              </TableRow>
            )} */}
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default TeleHealthProcessDataTableBody;

/** @format */

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  Box,
  Card,
  CardActionArea,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import './tipcascadeversion2.css';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Loader from 'widgets/Loader/Loader';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { GetEmotionTypeListService } from 'services/CommonServices/Emotion-service';
import { getEmotionTypeSelector } from 'redux/selectors/selectors';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CoachingMoment,
  FlagOption,
  SelectedUserRoleTypes,
  TimeSegment,
  TipCascadeBulbAndModalProps,
  knowledge_tips,
} from 'types/VideoPreview-types';
import { GetKnowledgeTipService } from 'services/CommonServices/Knowledgetips-service';
import { KnowledgeTipsSelector } from 'redux/selectors/selectors';

import BulbOn from 'icons/BulbOn';
import BulbOff from 'icons/BulbOff';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import TipCascadeModal from './TipcascadeModal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function TipCascadeBulbAndModal({
  ProcessDataListForParticipants,
  currentVideoTime,
  videoData,
  videoPlaying,

  handleVideoTips,
  setTipStartTime,
  handlePauseMainVideo,
  handlePlayPause,
  cascadeRef,
  highlightedCardRef,
  vidRef,
  highlightedIndex,
  setHighlightedIndex,
  currentIndex,
  setCurrentIndex,
  selectedUserRole,
  selectedFlags,
}: TipCascadeBulbAndModalProps) {
  let coachingMoment: CoachingMoment | undefined;
  const dispatch = useAppDispatch();
  const [tipCascadeModalOpen, setTipCascadeModalOpen] = useState(false);
  const [filteredSegments, setFilteredSegments] = useState<Array<TimeSegment>>(
    []
  );
  const attendeesRecord =
    ProcessDataListForParticipants[0]?.attendees_record || [];

  const segments = useMemo(() => videoData?.time_segments, [videoData]);

  const tempArray = useAppSelector(KnowledgeTipsSelector);
  const medialink = tempArray.TipDetails[0]?.media_link;

  const { isLoadingEmotionType, errorEmotionType, EmotionTypeList } =
    useAppSelector(getEmotionTypeSelector);

  useEffect(() => {
    if (filteredSegments && Array.isArray(filteredSegments)) {
      const newIndex = filteredSegments.findIndex(
        (item: TimeSegment) =>
          currentVideoTime >= Math.floor(item.start_time_stamp) &&
          currentVideoTime <= Math.floor(item.end_time_stamp)
      );
    }
  }, [currentVideoTime, filteredSegments]);

  useEffect(() => {
    dispatch(GetEmotionTypeListService());
    dispatch(GetKnowledgeTipService(32));
  }, [dispatch]);

  useEffect(() => {
    if (!videoData) {
      return;
    }

    let tempKnowledgeTipsArray: knowledge_tips[] = [];

    for (const coachingMoment of videoData.coaching_moments) {
      const knowledgeTips = coachingMoment.knowledge_tips;

      if (knowledgeTips.length > 0) {
        tempKnowledgeTipsArray = [...tempKnowledgeTipsArray, ...knowledgeTips];
        break;
      }
    }
  }, [videoData]);

  useEffect(() => {
    if (
      highlightedIndex !== null &&
      highlightedCardRef.current &&
      cascadeRef.current
    ) {
      const cardRect = highlightedCardRef.current.getBoundingClientRect();
      const cascadeRect = cascadeRef.current.getBoundingClientRect();
      const scrollYOffset = cardRect.top - cascadeRect.top - 20;
      cascadeRef.current.scrollTo({
        top: cascadeRef.current.scrollTop + scrollYOffset,
        behavior: 'smooth',
      });
    }
  }, [highlightedIndex, highlightedCardRef, cascadeRef]);

  useEffect(() => {
    dispatch(GetEmotionTypeListService());
  }, [dispatch]);

  function handleTipCascadeModalOpen(index: number) {
    handleTileClick(index);
    handlePauseMainVideo();
    setTipCascadeModalOpen(true);
  }
  function handleTipCascadeModalClose() {
    setTipCascadeModalOpen(false);
  }

  const updateSpeakerTimeSegments = useCallback(() => {
    setFilteredSegments((prevFilteredSegments) => {
      const filteredByFlags: TimeSegment[] = segments.filter(
        (segment: TimeSegment) =>
          selectedFlags.some(
            (selectedFlag: FlagOption) => selectedFlag.id === segment.flag_id
          )
      );

      if (selectedUserRole.length > 0) {
        const newFilteredSegments: TimeSegment[] = (
          selectedUserRole.flatMap((role: SelectedUserRoleTypes) => {
            const selectedAttendee = attendeesRecord[role.index];
            if (selectedAttendee) {
              return filteredByFlags.flatMap((segment: TimeSegment | null) =>
                selectedAttendee.time_segment_list.includes(segment?.sno || 0)
                  ? { ...segment, updatedValue: 'yourUpdatedValue' }
                  : null
              );
            }
            return [];
          }) as (TimeSegment | null)[]
        ).filter(
          (segment: TimeSegment | null): segment is TimeSegment =>
            segment !== null
        );

        const sortedNewFilteredSegments: TimeSegment[] =
          newFilteredSegments.sort(
            (a, b) => (a?.start_time_stamp || 0) - (b?.start_time_stamp || 0)
          );

        return sortedNewFilteredSegments;
      }

      const sortedFilteredByFlags = filteredByFlags.sort(
        (a, b) => a.start_time_stamp - b.start_time_stamp
      );

      return sortedFilteredByFlags;
    });
  }, [attendeesRecord, selectedUserRole, selectedFlags, segments]);

  useEffect(() => {
    if (segments) {
      updateSpeakerTimeSegments();
    }
  }, [segments, updateSpeakerTimeSegments]);

  function getEmotionName(emotionId: number): string {
    const matchedEmotion = EmotionTypeList.find(
      (emotion) => emotion.id === emotionId
    );
    return matchedEmotion?.name || '';
  }

  const handleTileClick = (index: number) => {
    let desiredSegment = videoData.time_segments[index];
    setTipStartTime(desiredSegment.start_time_stamp);
    if (!videoPlaying) {
    }
  };

  const handlePlay = () => {
    handleVideoTips('true');
  };

  const handleEnd = () => {
    handleVideoTips('false');
  };

  return (
    <Box>
      {highlightedIndex !== -1 ? (
        filteredSegments.map((item: TimeSegment, index: number) => {
          return (
            highlightedIndex === index && (
              <div key={index}>
                {videoData.coaching_moments
                  .find(
                    (moment: CoachingMoment) =>
                      moment.delivery_time_stamp === item.start_time_stamp
                  )
                  ?.knowledge_tips.filter(
                    (tip: knowledge_tips) => tip.media_link !== null
                  )[currentIndex] ? (
                  <IconButton onClick={() => handleTipCascadeModalOpen(index)}>
                    <BulbOn />
                  </IconButton>
                ) : (
                  <IconButton>
                    <BulbOff />
                  </IconButton>
                )}
              </div>
            )
          );
        })
      ) : (
        <IconButton>
          <BulbOff />
        </IconButton>
      )}{' '}
      {tipCascadeModalOpen && (
        <TipCascadeModal
          tipCascadeModalOpen={tipCascadeModalOpen}
          handleTipCascadeModalClose={handleTipCascadeModalClose}
          cascadeRef={cascadeRef}
          videoData={videoData}
          setCurrentIndex={setCurrentIndex}
          handleTileClick={handleTileClick}
          highlightedIndex={highlightedIndex}
          highlightedCardRef={highlightedCardRef}
          videoPlaying={videoPlaying}
          getEmotionName={getEmotionName}
          vidRef={vidRef}
          handlePlay={handlePlay}
          currentIndex={currentIndex}
          filteredSegments={filteredSegments}
        />
      )}
    </Box>
  );
}

export default TipCascadeBulbAndModal;

/** @format */

import { i18n } from 'i18n/i18n';
import { AppThunk } from 'redux/store';
import commonAPI from 'shared/Api/commonAPI';
import {
  sessionTypesLoad,
  sessionTypesSuccess,
  sessionTypesFailure,
  uploadSingleFileSuccess,
  uploadSingleFileFailure,
  uploadSingleFileLoad,
  uploadMultipleFilesLoad,
  uploadMultipleFilesFailure,
  uploadMultipleFilesSuccess,
  uploadVideoByLinkFailure,
  uploadVideoByLinkLoad,
  uploadVideoByLinkSuccess,
} from 'redux/slices/UploadMedia-slice';
import { Toast } from 'widgets/Toast/Toast';
import { ProtectedUrls, PublicUrls } from 'routes/urls';
import axios, {
  AxiosProgressEvent,
  CancelToken,
  CancelTokenSource,
} from 'axios';

export const getSessionTypesServiceApi =
  (typeId: number, isApicallFromAssessment?: boolean): AppThunk =>
  async (dispatch) => {
    const sessionTypeEnabled = [3, 7, 59];
    const assessmentTypeEnabled = [
      9, 10, 11, 14, 15, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
      41, 42, 43, 44, 45, 46, 47, 48, 56, 58,
    ];
    dispatch(sessionTypesLoad());

    await commonAPI
      .get(PublicUrls.getSessionRoute.url(typeId))
      .then((response) => {
        const filteredSessionTypes = isApicallFromAssessment
          ? response.data.filter((item: { type: string; id: number }) => {
              return (
                item.type === 'assessment' &&
                assessmentTypeEnabled.includes(item.id)
              );
            })
          : response.data.filter(
              (sessionType: { id: number; name: string }) => {
                return sessionTypeEnabled.includes(sessionType.id);
              }
            );

        dispatch(sessionTypesSuccess(filteredSessionTypes));

        if (response.data) {
        } else {
          Toast(response.data.message, 'error');
        }
      })
      .catch((error) =>
        dispatch(
          sessionTypesFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        )
      );
  };
export const UploadSingleFileServiceWithProgressStatus =
  (
    fixBlob: string,
    session_type_id: number,
    filename: string,
    recordingType: { id: number },
    handleClickOpenRecordedSessionUploadCompleteModal: Function,
    setUploadSingleFileProgressStatus: Function,
    cancelToken: any,
    setStatus: Function,
    dynamic_prediction: boolean,
    videoAnalysisEnabled: boolean,
    textAnalysisEnabled: boolean
  ): AppThunk =>
  async (dispatch) => {
    dispatch(uploadSingleFileLoad());
    if (fixBlob) {
      let fileName =
        recordingType.id === 1 ? `${filename}.mp4` : `${filename}.mp3`;
      try {
        const blob = await fetch(fixBlob).then((response) => response.blob());
        const formData = new FormData();
        formData.append('files', blob, fileName);

        const config: any = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );

            setUploadSingleFileProgressStatus({
              fileName: fileName,
              uploadProg: progress,
            });
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: cancelToken,
        };

        const response = await commonAPI.post(
          ProtectedUrls.uploadSingleFile.url(
            session_type_id,
            dynamic_prediction,
            videoAnalysisEnabled,
            textAnalysisEnabled
          ),
          formData,
          config
        );

        const responseJson = response.data;
        if (responseJson.Job_Id[0]) {
          handleClickOpenRecordedSessionUploadCompleteModal();
          dispatch(uploadSingleFileSuccess(responseJson));
          setStatus('uploaded');
        } else {
          Toast(
            i18n.t(
              'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
            ),
            'error'
          );
          dispatch(uploadSingleFileFailure(response));
        }
      } catch (error: any) {
        if (error.message !== 'Upload canceled by user.') {
          Toast(
            i18n.t(
              'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
            ),
            'error'
          );
        }
      }
    }
  };

export const UploadSingleFileService =
  (
    fixBlob: string,
    session_type_id: number,
    filename: string,
    recordingType: { id: number },
    handleClickOpenRecordedSessionUploadCompleteModal: Function,
    dynamic_prediction: boolean,
    videoAnalysisEnabled: boolean,
    textAnalysisEnabled: boolean
  ): AppThunk =>
  async (dispatch) => {
    dispatch(uploadSingleFileLoad());
    if (fixBlob) {
      let fileName =
        recordingType.id === 1 ? `${filename}.mp4` : `${filename}.mp3`;
      try {
        const blob = await fetch(fixBlob).then((response) => response.blob());
        const formData = new FormData();
        formData.append('files', blob, fileName);
        const response = await commonAPI.post(
          ProtectedUrls.uploadSingleFile.url(
            session_type_id,
            dynamic_prediction,
            videoAnalysisEnabled,
            textAnalysisEnabled
          ),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        const responseJson = response.data;
        if (responseJson.Job_Id[0]) {
          // Toast(
          //   recordingType.id === 1
          //     ? 'Video Uploaded Successfully'
          //     : 'Audio Uploaded Successfully',
          //   'success'
          // );
          handleClickOpenRecordedSessionUploadCompleteModal();
          dispatch(uploadSingleFileSuccess(responseJson));
        } else {
          Toast(
            i18n.t(
              'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
            ),
            'error'
          );
          dispatch(uploadSingleFileFailure(response));
        }
      } catch (error) {
        Toast(
          i18n.t('toastMessageNotifications.SomethingWentWrongPleaseTryAgain'),
          'error'
        );
      }
    }
  };

// Function not in use
/*
export const UploadMultipleFilesService =
  (
    files: Array<{ preview: string; name: string }>,
    session_type_id: number,
    setfiles: Function,
    cancelTokenSources: CancelTokenSource[],
    setCancelTokenSources: Function,
    setApiCalled: Function
  ): AppThunk =>
  async (dispatch) => {
    dispatch(uploadMultipleFilesLoad());
    const promises: Promise<void>[] = [];
    files.forEach((file, index) => {
      const formData: any = new FormData();
      formData.append('files', file);

      promises.push(
        new Promise<void>(async (resolve, reject) => {
          try {
            await commonAPI.post(
              ProtectedUrls.uploadSingleFile.url(session_type_id),
              formData,
              {
                headers: {
                  accept: 'application/json',
                  'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                  const percentCompleted = progressEvent.total
                    ? Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                    : 0;

                  const updatedFiles: any = [...files];
                  updatedFiles[index].progress = percentCompleted;
                  setfiles(updatedFiles);
                },
                cancelToken: cancelTokenSources[index].token,
              }
            );
            Toast(`File ${file.name} uploaded successfully`, 'success');
            resolve();
          } catch (error) {
            if (axios.isCancel(error)) {
              resolve();
              Toast(`Upload for file ${file.name} cancelled`, 'success');
            } else {
              Toast(`File upload for ${file.name} failed`, 'error');
              reject(error);
            }
          }
        })
      );
    });

    try {
      await Promise.all(promises);

      setApiCalled(false);
      dispatch(uploadMultipleFilesSuccess('files uploaded'));
    } catch (error) {
      Toast(i18n.t('toastMessageNotifications.OneOrMoreFileUploadsFailed'), 'error');
      dispatch(uploadMultipleFilesFailure(error));
    }
  };
*/
export const uploadVideoByLinkServiceApi =
  (
    payload: {
      videoLink: string;
      videoDesc: string;
      sessionId: number;
    },
    handleOpenUploadSuccessfulModal: () => void
  ): AppThunk =>
  async (dispatch) => {
    dispatch(uploadVideoByLinkLoad());

    await commonAPI
      .post(ProtectedUrls.uploadVideoByLink.url(payload))
      .then((response) => {
        dispatch(uploadVideoByLinkSuccess(response.data));
        handleOpenUploadSuccessfulModal();

        if (response.data) {
        } else {
          Toast(response.data.error, 'error');
        }
      })
      .catch((error) => {
        Toast(error.response.data.error, 'error');
        dispatch(
          uploadVideoByLinkFailure(
            error ||
              i18n.t(
                'toastMessageNotifications.SomethingWentWrongPleaseTryAgain'
              )
          )
        );
      });
  };

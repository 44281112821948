const getMediaDevices = async (
  setCameras: Function,
  setMicrophones: Function
) => {
  try {
    const mediaStream = await navigator.mediaDevices.enumerateDevices();
    const audioOutputs = mediaStream.filter(
      (item) => item.kind === 'audioinput'
    );
    const videoInputs = mediaStream.filter(
      (item) => item.kind === 'videoinput'
    );
    setCameras(videoInputs);
    setMicrophones(audioOutputs);
  } catch (error) {}
};

export default getMediaDevices;

/** @format */

import {
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
} from '@mui/material';
import SortTableDataIcon from 'icons/SortTableDataIcon';
import React from 'react';
import { RecoveryDashboardTableHeadProps } from 'types/TeleHealth/RecoveryDashboard-types';

function TeleHealthTableHead({
  handleSelectAll,
  hasSelectedAll,
  onRequestSort,
  orderBy,
  order,
}: RecoveryDashboardTableHeadProps) {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        backgroundColor: '#F7F7F9',
        color: '605E5E',
      }}
    >
      <TableRow
        style={{
          borderBottom: '2px solid #F7F7F9',
        }}
      >
        <TableCell id='ConfidentTone' colSpan={9}></TableCell>
        <TableCell id='Overall_Score' align='center' colSpan={2}>
          Overall Score
        </TableCell>
        <TableCell id='Flag' align='center' colSpan={3}>
          <div
            style={{
              position: 'relative',
              left: 8,
            }}
          >
            Flag
          </div>
        </TableCell>
        <TableCell id='' colSpan={3}></TableCell>
      </TableRow>
      <TableRow
        style={{
          borderBottom: '2px solid #F7F7F9',
        }}
      >
        {/* <TableCell id='' colSpan={1} /> */}
        <TableCell padding='checkbox' align='center' id='checkbox' colSpan={1}>
          <Checkbox
            checked={hasSelectedAll}
            onChange={handleSelectAll}
            role='checkbox'
            aria-checked='false'
          />
        </TableCell>
        <TableCell
          sortDirection={orderBy === 'Sessions' ? order : false}
          variant='head'
          id='Sessions'
          colSpan={1}
          align='center'
        >
          Sessions
          <IconButton
            onClick={createSortHandler('section')}
            aria-label='sort-by'
          >
            <SortTableDataIcon />
          </IconButton>
        </TableCell>
        <TableCell id='Date_Time' colSpan={1} align='center'>
          Date & Time
        </TableCell>
        <TableCell id='Continuity_Risk' colSpan={1} align='center'>
          Continuity Risk (CR)
        </TableCell>
        <TableCell id='Action_Plan' colSpan={1} align='center'>
          Action Plan
        </TableCell>
        <TableCell id='Action_Taken' colSpan={1} align='center'>
          Action Taken
        </TableCell>
        <TableCell id='Continuity' colSpan={1} align='center'>
          Continuity
        </TableCell>
        <TableCell id='emotion' colSpan={1} align='center'>
          Emotion
        </TableCell>
        <TableCell id='engagement' colSpan={1} align='center'>
          Engagement
        </TableCell>
        <TableCell id='positivity' colSpan={1} align='center'>
          Positivity
        </TableCell>
        <TableCell id='Green' colSpan={1} align='center'>
          Green
        </TableCell>
        <TableCell id='Yellow' colSpan={1} align='center'>
          Yellow
        </TableCell>
        <TableCell id='Red' colSpan={1} align='center'>
          Red
        </TableCell>
        <TableCell id='confidentTone' colSpan={1} align='center'>
          Confident Tone %
        </TableCell>
        <TableCell id='stressedTone' colSpan={1} align='center'>
          Stressed Tone %
        </TableCell>
        <TableCell id='fatiguedTone' colSpan={1} align='center'>
          Fatigued Tone %
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TeleHealthTableHead;

import React from 'react';

export default function EditSummaryIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icons/mode_edit_24px'>
        <path
          id='icon'
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.7067 2.3933L13.6067 3.2933C14.1333 3.8133 14.1333 4.65996 13.6067 5.17996L4.78667 14H2V11.2133L8.93333 4.2733L10.82 2.3933C11.34 1.8733 12.1867 1.8733 12.7067 2.3933ZM3.33333 12.6666L4.27333 12.7066L10.82 6.1533L9.88 5.2133L3.33333 11.76V12.6666Z'
          fill='#1206F9'
        />
      </g>
    </svg>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
} from '@mui/material';
import RecordedVideoPreview from 'components/Record/RecordedVideoPreview';
import SettingsIcon from 'icons/SettingsIcon';
import React, { useEffect, useMemo, useState } from 'react';
import {
  THSType,
  TempPromptQuestionsArrayType,
  UploadStatusType,
} from 'types/MyAssessments-types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import StartAssessmentRecordIcon from 'icons/StartAssessmentRecordIcon';
import Typography from 'widgets/Typography/Typography';
import VideoPreviewPlayer from 'widgets/VideoPreviewPlayer/VideoPreviewPlayer';
import StopRecordingIcon from 'icons/StopRecordingIcon';
import RecorderSettingsMenu from 'components/MyAssessments/RecorderSettingsMenu/RecorderSettingsMenu';
import CountUpDownTimer from 'widgets/CountUpDownTimer/CountUpDownTimer';
import { CandidateDetailsType } from 'types/SendAssessments-types';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useAppSelector } from 'redux/store';
import {
  sectionUploadStatusSelector,
  uploadSectionVideoSelector,
} from 'redux/selectors/selectors';
import ReRecordPromptModal from './ReRecordPromptModal';

function RecorderForPromptsQuestion({
  currentlySelectedQuestion,
  startRecording,
  previewStream,
  handleStopRecording,
  status,
  handleNext,
  handlePrev,
  selectedQuestionIndex,
  maxLengthofSectionsArray,
  toggleQuestionSkippedStatus,
  mediaBlobUrl,
  clearBlobUrl,
  setMainRecordingSettings,
  mainRecordingSettings,
  isLastVideoUploaded,
  handleOpenSubmitAssessmentModal,
  isFromCandidateAssessment,
  candidateDetails,
}: {
  currentlySelectedQuestion: TempPromptQuestionsArrayType;
  startRecording: () => void;
  previewStream: MediaProvider | null;
  handleStopRecording: () => void;
  handleOpenSubmitAssessmentModal: () => void;
  status: string;
  handleNext: (isLastVideo: boolean) => void;
  handlePrev: (isLastVideo: boolean) => void;
  selectedQuestionIndex: number;
  maxLengthofSectionsArray: number;
  toggleQuestionSkippedStatus: Function;
  mediaBlobUrl: string | null;
  clearBlobUrl: Function;
  setMainRecordingSettings: Function;
  mainRecordingSettings: THSType;
  isLastVideoUploaded: boolean;
  isFromCandidateAssessment: boolean;
  candidateDetails: CandidateDetailsType;
}) {
  const questionStyle = {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '-0.264px',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
  };

  const textOverlayStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: '2px solid red',
    width: '120px',
    height: '120px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  };
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let intervalId: NodeJS.Timer;

    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [seconds]);

  const handleClick = () => {
    setSeconds(15);
  };

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const [openReuploadConfirmationModal, setOpenReuploadConfirmationModal] =
    useState<boolean>(false);

  const { isUploadingSectionVideo } = useAppSelector(
    uploadSectionVideoSelector
  );
  const { uploadStatus } = useAppSelector(sectionUploadStatusSelector);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const [anchorElRecorderSettingsDialog, setAnchorElRecorderSettingsDialog] =
    React.useState<null | HTMLElement>(null);
  const handleClickRecorderSettingsDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElRecorderSettingsDialog(event.currentTarget);
  };
  const handleCloseRecorderSettingsDialog = () => {
    setAnchorElRecorderSettingsDialog(null);
  };
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
  const handleStartButtonClick = () => {
    setIsTimerRunning(true);
  };

  const handleStopButtonClick = () => {
    setIsTimerRunning(false);
  };

  useEffect(() => {
    if (status === 'recording') {
      handleStartButtonClick();
    } else if (status === 'stopped') {
      handleStopButtonClick();
    }
  }, [status]);

  const MemoizedVideoPreview = useMemo(() => {
    return <RecordedVideoPreview stream={previewStream} />;
  }, [status, mediaBlobUrl]);

  return (
    <Box>
      <Box sx={{ textAlign: 'right', mt: 2 }}>
        <IconButton
          onClick={handleClickRecorderSettingsDialog}
          disabled={status === 'recording' || status === 'acquiring_media'}
        >
          <SettingsIcon />
        </IconButton>
      </Box>
      {currentlySelectedQuestion.id !== -1 && (
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Typography sx={questionStyle} label=''>
            {currentlySelectedQuestion.sequence_number}.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={questionStyle} label=''>
              {currentlySelectedQuestion.question}
            </Typography>
            <Box
              sx={{
                height: '15vh',
                overflow: 'hidden',
                '&:hover': {
                  overflowY: 'auto',
                },
              }}
            >
              <pre
                style={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',

                  fontSize: '14px',
                  margin: 0,
                }}
              >
                {mainRecordingSettings.asessmentType.id === 56
                  ? 'Follow-up: '
                  : t('MyAssessments.Hints')}
                {currentlySelectedQuestion.description}
              </pre>

              <Typography
                sx={{ fontSize: '12px' }}
                label={`Status: ${status}`}
              ></Typography>
            </Box>{' '}
          </Box>{' '}
        </Box>
      )}
      <Paper>
        <Box
          sx={{
            height: '45vh',

            mt: 3,
            background: '#F8FBFF',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {mediaBlobUrl || currentlySelectedQuestion.mediaBlobUrl ? (
              mainRecordingSettings.recordingType.id === 1 ? (
                <VideoPreviewPlayer
                  mediaBlobUrl={
                    mediaBlobUrl
                      ? mediaBlobUrl
                      : currentlySelectedQuestion.mediaBlobUrl
                  }
                  isPlaying={isPlaying}
                />
              ) : (
                <Box>
                  <audio controls controlsList='nodownload'>
                    <source
                      src={mediaBlobUrl ? mediaBlobUrl : ''}
                      type='audio/mp3'
                    />
                    {t(
                      'MyAssessments.YourBrowserDoesNotSupportTheAudioElement'
                    )}
                  </audio>
                </Box>
              )
            ) : mainRecordingSettings.recordingType.id === 1 ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {MemoizedVideoPreview}
                {status === 'recording' && (
                  <CountUpDownTimer
                    direction={
                      mainRecordingSettings.asessmentType.timerdirection
                    }
                    seconds={currentlySelectedQuestion.maximum_time_seconds}
                    style={{
                      position: 'absolute',
                      top: '1%',
                      right: '1%',
                      zIndex: 1,
                      opacity: 0.7,
                    }}
                    isTimerRunning={isTimerRunning}
                  />
                )}
              </Box>
            ) : (
              <div>
                <p>
                  {status === 'recording' ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#000000',
                        color: 'white',
                      }}
                    >
                      <Typography variant='h5' label='record.RecordingAudio' />
                      {status === 'recording' && (
                        <CountUpDownTimer
                          direction={
                            mainRecordingSettings.asessmentType.timerdirection
                          }
                          seconds={
                            currentlySelectedQuestion.maximum_time_seconds
                          }
                          style={{
                            position: 'absolute',
                            top: '1%',
                            right: '1%',
                            zIndex: 1,
                            opacity: 0.7,
                          }}
                          isTimerRunning={isTimerRunning}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#000000',
                        color: 'white',
                      }}
                    >
                      <Typography variant='h5' label='record.RecordAnswer' />
                    </Box>
                  )}
                </p>
              </div>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
          }}
        >
          <Box sx={{ width: '20%' }}>
            {/* **********************temporary disabled please do not uncomment or delete below code ************************ */}
            {/* <Button
              sx={{ color: '#1206F9' }}
              startIcon={<RefreshIcon />}
              disabled={
                currentlySelectedQuestion.isSkipped ||
                mediaBlobUrl === null ||
                status === 'recording'
              }
              onClick={() => clearBlobUrl()}
            >
              Retry
            </Button> */}
            {/* **********************temporary disabled please do not uncomment or delete above code ************************ */}
          </Box>
          <Box
            sx={{
              display: 'flex',

              gap: 3,
            }}
          >
            <Button
              sx={{ color: '#1206F9' }}
              id='prevPromptButton'
              startIcon={<ArrowBackIosIcon />}
              onClick={() => {
                handlePrev(selectedQuestionIndex === maxLengthofSectionsArray);
              }}
              disabled={
                selectedQuestionIndex === 0 ||
                status === 'recording' ||
                status === 'acquiring_media'
              }
            >
              {t('MyAssessments.Prev')}
            </Button>
            {status === 'recording' ? (
              <Button
                sx={{
                  backgroundColor: '#1206F9',
                  borderRadius: '30px',
                  border: '1px solid #1206F9',
                }}
                variant='contained'
                onClick={handleStopRecording}
                startIcon={<StopRecordingIcon />}
                id='stopRecordingButton'
              >
                {t('MyAssessments.Stop')}
              </Button>
            ) : (
              <Box>
                {status === 'acquiring_media' ? (
                  <CircularProgress />
                ) : (
                  <Button
                    sx={{
                      backgroundColor: '#1206F9',
                      borderRadius: '30px',
                    }}
                    variant='contained'
                    startIcon={<StartAssessmentRecordIcon />}
                    id='startRecordingButton'
                    onClick={() =>
                      currentlySelectedQuestion.isCompleted
                        ? setOpenReuploadConfirmationModal(true)
                        : startRecording()
                    }
                    disabled={
                      mediaBlobUrl !== null ||
                      status === 'recording' ||
                      status === 'acquiring_media' ||
                      isLastVideoUploaded ||
                      uploadStatus.find(
                        (upload: UploadStatusType) =>
                          upload.sectionId === currentlySelectedQuestion.id &&
                          upload.status === 'uploading'
                      ) !== undefined
                    }
                  >
                    {t('MyAssessments.Start')}
                  </Button>
                )}
              </Box>
            )}

            <Button
              sx={{ color: '#1206F9' }}
              endIcon={<ArrowForwardIosIcon />}
              onClick={() => {
                handleNext(selectedQuestionIndex === maxLengthofSectionsArray);
              }}
              id='nextPromptButton'
              disabled={
                status === 'recording' ||
                status === 'acquiring_media' ||
                (!currentlySelectedQuestion.isCompleted &&
                  !mediaBlobUrl &&
                  !currentlySelectedQuestion.isSkipped)
              }
            >
              {selectedQuestionIndex === maxLengthofSectionsArray
                ? t('MyAssessments.Finish')
                : t('MyAssessments.Next')}
            </Button>
          </Box>
          <Box>
            <Button
              sx={{
                color: '#1206F9',
                borderRadius: '30px',
                border: '1px solid #1206F9',
              }}
              id='skipPromptButton'
              variant='outlined'
              endIcon={<KeyboardDoubleArrowRightIcon />}
              onClick={() => {
                toggleQuestionSkippedStatus(
                  currentlySelectedQuestion,
                  selectedQuestionIndex === maxLengthofSectionsArray
                );
              }}
              disabled={
                mediaBlobUrl !== null ||
                status === 'recording' ||
                currentlySelectedQuestion.isCompleted ||
                status === 'acquiring_media' ||
                currentlySelectedQuestion.isSkipped ||
                isLastVideoUploaded
              }
            >
              {t('MyAssessments.Skip')}
            </Button>
          </Box>
        </Box>
      </Paper>
      <RecorderSettingsMenu
        setMainRecordingSettings={setMainRecordingSettings}
        mainRecordingSettings={mainRecordingSettings}
        anchorEl={anchorElRecorderSettingsDialog}
        handleCloseRecorderSettingsDialog={handleCloseRecorderSettingsDialog}
        mediaRecorderStatus={status}
        isFromCandidateAssessment={isFromCandidateAssessment}
        candidateDetails={candidateDetails}
      />

      {openReuploadConfirmationModal && (
        <ReRecordPromptModal
          setOpenReuploadConfirmationModal={setOpenReuploadConfirmationModal}
          openReuploadConfirmationModal={openReuploadConfirmationModal}
          startRecording={startRecording}
        />
      )}
    </Box>
  );
}

export default RecorderForPromptsQuestion;

/** @format */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from 'shared/Footer/Footer';
import Header from 'shared/Header/Header';
import { AuthWrapperProps } from 'types/common/Common-types';
import AppDrawer from 'shared/Drawer/Drawer';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  minHeight: '100vh',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function Wrapper({ children }: AuthWrapperProps) {
  const [openDesktopDrawer, setOpenDesktopDrawer] = React.useState(false);
  const [openMobileDrawer, setOpenMobileDrawer] = React.useState(false);

  function handleOpenMobileDrawer() {
    setOpenMobileDrawer(true);
  }
  function handleCloseMobileDrawer() {
    setOpenMobileDrawer(false);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header
        setOpen={setOpenDesktopDrawer}
        open={openDesktopDrawer}
        handleOpenMobileDrawer={handleOpenMobileDrawer}
        handleCloseMobileDrawer={handleCloseMobileDrawer}
        openMobileDrawer={openMobileDrawer}
        ShowMenuIcons={false}
      />

      <Main
        open={openDesktopDrawer}
        sx={{
          marginTop: 6,
          marginBottom: 6,
          marginLeft: 0,
          backgroundColor: '#F8FBFF',
        }}
      >
        {children}
      </Main>
      <Footer />
    </Box>
  );
}

import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QuestionVideoPlayer from './QuestionVideoPlayer';
import Loader from 'widgets/Loader/Loader';
import { QuestionnaireData } from 'types/TeleHealth/PatientWorkflow-types';
import env from 'envConfig';
import YoutubeSpinner from './Spinner';
import { ids } from '../../../constants/ConstantsForTestCases';

function VideoQuestionaire({
  question,
  currentQuestionIndex,
  status,
  isIOS,
}: {
  question: QuestionnaireData;
  currentQuestionIndex: number;
  status: string;
  isIOS: boolean;
}) {
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string>('');

  useEffect(() => {
    if (question.media_link) {
      setVideoUrl(`${env.REACT_APP_BASE_URL}/${question.media_link}`);
      setIsVideoLoading(true);
    } else {
      setIsVideoLoading(false);
    }
  }, [question]);

  const handleVideoLoaded = () => {
    setIsVideoLoading(false);
  };

  return (
    <Paper>
      <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
        <QuestionVideoPlayer
          key={videoUrl}
          questionData={{
            question: question.question,
            questionNum: currentQuestionIndex,
            followUpQuestions: question.description,
          }}
          url={videoUrl}
          onCanPlay={handleVideoLoaded}
          isVideoLoading={isVideoLoading}
          status={status}
          isIOS={isIOS}
        />
      </Box>
    </Paper>
  );
}

export default VideoQuestionaire;

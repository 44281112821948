/** @format */

import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { AttendeeRecordForDashboard, Data, Order } from 'types/Dashboard-types';
import {
  DashboardRowData,
  RecoveryDashboardData,
} from 'types/TeleHealth/RecoveryDashboard-types';

function useTeleHealthProcessDataListTable(data: Array<RecoveryDashboardData>) {
  const [rows, setRows] = useState<Array<DashboardRowData>>([]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof DashboardRowData>('Sessions');
  const [page, setPage] = useState(
    Number(localStorage.getItem('currentPageDashboardPagination'))
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [visibleRows, setVisibleRows] = useState<Array<DashboardRowData>>([]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof Data>(
    order: Order,
    orderBy: Key
  ): (
    a: {
      [key in Key]:
        | number
        | string
        | boolean
        | Array<AttendeeRecordForDashboard>;
    },
    b: {
      [key in Key]:
        | number
        | string
        | boolean
        | Array<AttendeeRecordForDashboard>;
    }
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function handleRequestSort(
    event: MouseEvent<unknown>,
    property: keyof DashboardRowData
  ) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(_event: unknown, newPage: number) {
    localStorage.setItem(
      'TeleHethCurrentPageDashboardPagination',
      newPage.toString()
    );
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function formatDate(dateString: string) {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toLocaleString('en-US', options);
    const formattedTime = dateObject.toLocaleString('en-US', optionsTime);
    return `${formattedDate} | ${formattedTime}`;
  }

  useEffect(() => {
    const updatedRows = data.map((tableData: RecoveryDashboardData) => {
      return {
        id: tableData.coaching_session_reports_id,
        Sessions: tableData.session_title,
        Date_Time: tableData.processing_end_date_time,
        Continuity_Risk: tableData.continuity_risk,
        Action_Plan: tableData.action_plan,
        Action_Taken: tableData.action_taken,
        Continuity: tableData.continuity,
        emotion: tableData.overall_emotion_name,
        engagement: tableData.engagement_score,
        positivity: tableData.positivity_score,
        Green: tableData.green_flags,
        Yellow: tableData.yellow_flags,
        Red: tableData.red_flags,
        confidentTone: tableData.confident_tone,
        stressedTone: tableData.stressed_tone,
        fatiguedTone: tableData.fatigued_tone,

        ...tableData,
      };
    });

    setRows(updatedRows);

    setVisibleRows(updatedRows);
  }, [data]);

  return {
    handleRequestSort,
    handleChangeRowsPerPage,
    handleChangePage,
    formatDate,
    visibleRows,
    rows,
    page,
    emptyRows,
    rowsPerPage,
    order,
    orderBy,
  };
}

export default useTeleHealthProcessDataListTable;

import React, { useState, useRef } from 'react';
import { TextField, useMediaQuery, Box } from '@mui/material';
import Typography from 'widgets/Typography/Typography';
import Button from 'widgets/CustomButton/Button';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { TFunction } from 'i18next';
import { useAppDispatch } from 'redux/store';
import * as Yup from 'yup';
import {
  SendUserOtpServiceApi,
  VerifyOtpServiceApi,
} from 'services/PreviewServices/UserValidation-service';
import { useTranslation } from 'react-i18next';
import PreviewTermsAndConditions from '../PreviewTermsAndConditions';

interface FormValues {
  Email: string;
  otp: string;
}

const VisitorValidationSchema = (
  showOtpFields: boolean,
  t: TFunction<'translation', undefined>
) =>
  Yup.object().shape({
    Email: !showOtpFields
      ? Yup.string()
          .email(t('loginpage.InvalidEmail'))
          .required(t('loginpage.EmailIsRequired'))
      : Yup.string(),
    otp: showOtpFields
      ? Yup.string()
          .length(6, t('loginpage.InvalidOTP'))
          .required(t('loginpage.OTPIsRequired'))
      : Yup.string(),
  });

const initialValuesVisitorValidation: FormValues = {
  Email: '',
  otp: '',
};

const VisitorValidationForm: React.FC = () => {
  const [showOtpFields, setShowOtpFields] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const isTablet = useMediaQuery('(max-width: 960px)');

  const dispatch = useAppDispatch();
  const otpRefs = useRef<(HTMLInputElement | null)[]>([]);

  const { t } = useTranslation();

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): void => {
    setEmail(values.Email);
    sessionStorage.setItem('Email', values.Email);
    dispatch(SendUserOtpServiceApi(values.Email));
    setShowOtpFields(true);
    setSubmitting(false);
  };

  const handleOtpSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ): void => {
    setSubmitting(false);
    dispatch(VerifyOtpServiceApi(values, setIsVisible));
  };

  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void,
    values: FormValues
  ): void => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const otpArray = values.otp.split('');
      otpArray[index] = value;
      setFieldValue('otp', otpArray.join(''));
      if (value !== '' && index < 5) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpPaste = (
    e: React.ClipboardEvent<HTMLDivElement>,
    setFieldValue: (field: string, value: string) => void
  ): void => {
    const pasteData = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pasteData)) {
      const otpArray = pasteData.split('');
      setFieldValue('otp', otpArray.join(''));
      otpArray.forEach((digit, index) => {
        if (otpRefs.current[index]) {
          otpRefs.current[index]!.value = digit;
        }
      });
      otpRefs.current[5]?.focus();
    }
  };

  return (
    <Box
      sx={{
        width: isMobile ? '100%' : isTablet ? '100%' : '100%',
        height: '100%',
        zIndex: 1,
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          height: 'fit-content',
          borderRadius: '6px',
          p: 2,
        }}
      >
        <Formik
          initialValues={initialValuesVisitorValidation}
          validationSchema={VisitorValidationSchema(showOtpFields, t)}
          onSubmit={showOtpFields ? handleOtpSubmit : handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    color: '#000',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '23px',
                  }}
                  label='Preview.Previewpreanalyzedrecordingsuploadorrecordyourownmedia'
                />
                <Typography
                  sx={{
                    color: '#101828',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    mb: 3,
                  }}
                  label='Preview.ReceiveafreeindepthanalysisDiscoverthepowerofmirrosemotionalintelligenceAltoday'
                />
                <Box sx={{ maxWidth: 400 }}>
                  <Typography
                    sx={{
                      color: '#1906F9',
                      fontSize: '20px',
                      fontWeight: 800,
                      mb: 1,
                    }}
                    label='Preview.WelcomePleaseenteryourdetails'
                  />

                  {!showOtpFields && (
                    <Field
                      name='Email'
                      as={TextField}
                      variant='outlined'
                      fullWidth
                      helperText={
                        <ErrorMessage
                          name='Email'
                          component='Box'
                          className='error-message'
                        />
                      }
                      id='txtuseremailid'
                      InputProps={{
                        style: {
                          borderRadius: '35px',
                        },
                      }}
                      label='Email'
                      InputLabelProps={{
                        shrink: true,
                        required: true,
                      }}
                      sx={{
                        minHeight: '80px',
                      }}
                    />
                  )}
                  {showOtpFields && (
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                      onPaste={(e) => handleOtpPaste(e, setFieldValue)}
                    >
                      {Array.from({ length: 6 }, (_, index) => (
                        <TextField
                          key={index}
                          variant='outlined'
                          inputRef={(el) => (otpRefs.current[index] = el)}
                          inputProps={{
                            maxLength: 1,
                            style: { textAlign: 'center' },
                          }}
                          sx={{ width: '45px' }}
                          value={values.otp[index] || ''}
                          onChange={(e) =>
                            handleOtpChange(e, index, setFieldValue, values)
                          }
                        />
                      ))}
                    </Box>
                  )}
                  {showOtpFields && (
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                      }}
                    >
                      <Box sx={{ width: '60%' }}></Box>
                      <Box sx={{ width: '40%' }}>
                        <Typography
                          label='Preview.ResendAccesscode'
                          sx={{
                            color: '#1206f9',
                            fontSize: '14px',
                            fontWeight: 600,
                            cursor: 'pointer',
                            mt: 1,
                            mb: 1,
                          }}
                          onClick={() => {
                            dispatch(SendUserOtpServiceApi(email));
                          }}
                        />
                      </Box>
                    </Box>
                  )}

                  <Button
                    sx={{
                      backgroundColor: '#1206f9',
                      color: '#fff',
                      padding: 2,
                      borderRadius: '10px',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#1206f9',
                        color: '#fff',
                      },
                    }}
                    variant='contained'
                    type='submit'
                    label={
                      showOtpFields
                        ? 'Preview.VerifyAccesscode'
                        : 'Preview.GetAccesscode'
                    }
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  mt: 2,
                  color: '#79747E',
                  fontWeight: 400,
                }}
                label='Preview.Youremailaddresswillsolelybeusedtodeliveryourpersonalaccesscode'
              />

              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '12px',
                  mt: 1,
                  color: '#1D1B20',
                  fontWeight: 600,
                }}
                label='Preview.NoSignUpNoCredit'
              />
            </Form>
          )}
        </Formik>
      </Box>
      {isVisible && (
        <PreviewTermsAndConditions
          Onopen={isVisible}
          onClose={() => {
            setIsVisible(false);
            setShowOtpFields(false);
          }}
        />
      )}
    </Box>
  );
};

export default VisitorValidationForm;

/** @format */

import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Box, Card, CardActionArea } from '@mui/material';
import './tipcascadeversion2.css';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Loader from 'widgets/Loader/Loader';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { GetEmotionTypeListService } from 'services/CommonServices/Emotion-service';
import { getEmotionTypeSelector } from 'redux/selectors/selectors';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CoachingMoment,
  CoachingSessionReport,
  FlagOption,
  SelectedUserRoleTypes,
  TimeChunk,
  TimeSegment,
  TipCascadeProps,
  UserRole,
  knowledge_tips,
} from 'types/VideoPreview-types';
import { GetKnowledgeTipService } from 'services/CommonServices/Knowledgetips-service';
import { KnowledgeTipsSelector } from 'redux/selectors/selectors';
import env from 'envConfig';
import { useTranslation } from 'react-i18next';
import { formatTimeInMinSecs } from 'utils/formatTimeInMinSecs';
import Typography from 'widgets/Typography/Typography';
import AudioGreenFlagAnalysisIcon from 'icons/AudioGreenFlagAnalysisIcon';
import AudioRedFlagAnalysisIcon from 'icons/AudioRedFlagAnalysisIcon';
import AudioYellowFlagAnalysisIcon from 'icons/AudioYellowFlagAnalysisIcon';
import TextGreenFlagAnalysisIcon from 'icons/TextGreenFlagAnalysisIcon';
import TextGreyFlagAnalysisIcon from 'icons/TextGreyFlagAnalysisIcon';
import TextRedFlagAnalysisIcon from 'icons/TextRedFlagAnalysisIcon';
import TextYellowFlagAnalysisIcon from 'icons/TextYellowFlagAnalysisIcon';
import VideoGreenFlagAnalysisIcon from 'icons/VideoGreenFlagAnalysisIcon';
import VideoGreyFlagAnalysisIcon from 'icons/VideoGreyFlagAnalysisIcon';
import VideoRedFlagAnalysisIcon from 'icons/VideoRedFlagAnalysisIcon';
import VideoYellowFlagAnalysisIcon from 'icons/VideoYellowFlagAnalysisIcon';
import AudioGreyFlagAnlysisIcon from 'icons/AudioGreyFlagAnlysisIcon';
import { EmotionType } from 'types/common/Emotion-types';

function TipCascadeVersion2({
  ProcessDataListForParticipants,
  currentVideoTime,
  videoData,
  videoPlaying,

  handleVideoTips,
  setTipStartTime,
  cascadeRef,
  highlightedCardRef,
  vidRef,
  highlightedIndex,
  setHighlightedIndex,
  currentIndex,
  setCurrentIndex,
  selectedUserRole,
  selectedFlags,
  selectedPrompt,
}: TipCascadeProps) {
  const dispatch = useAppDispatch();

  const [filteredSegments, setFilteredSegments] = useState<Array<TimeSegment>>(
    []
  );

  const attendeesRecord =
    ProcessDataListForParticipants[selectedPrompt]?.attendees_record || [];

  const { EmotionTypeList } = useAppSelector(getEmotionTypeSelector);

  useEffect(() => {
    if (videoData && Array.isArray(videoData?.time_segments)) {
      const newIndex = videoData.time_segments.findIndex(
        (item: TimeSegment) =>
          currentVideoTime >= Math.floor(item.start_time_stamp) &&
          currentVideoTime <= Math.floor(item.end_time_stamp)
      );
      setHighlightedIndex(newIndex);
    }
  }, [currentVideoTime, setHighlightedIndex, videoData]);

  useEffect(() => {
    dispatch(GetEmotionTypeListService());
    dispatch(GetKnowledgeTipService(32));
  }, [dispatch]);

  useEffect(() => {
    if (!videoData) {
      return;
    }

    let tempKnowledgeTipsArray: Array<knowledge_tips> = [];

    for (const coachingMoment of videoData.coaching_moments) {
      const knowledgeTips = coachingMoment.knowledge_tips;

      if (knowledgeTips.length > 0) {
        tempKnowledgeTipsArray = [...tempKnowledgeTipsArray, ...knowledgeTips];

        break;
      }
    }
  }, [videoData]);

  useEffect(() => {
    if (
      highlightedIndex !== null &&
      highlightedCardRef.current &&
      cascadeRef.current
    ) {
      const cardRect = highlightedCardRef.current.getBoundingClientRect();
      const cascadeRect = cascadeRef.current.getBoundingClientRect();
      const scrollYOffset = cardRect.top - cascadeRect.top - 20;
      cascadeRef.current.scrollTo({
        top: cascadeRef.current.scrollTop + scrollYOffset,
        behavior: 'smooth',
      });
    }
  }, [cascadeRef, highlightedCardRef, highlightedIndex]);
  useEffect(() => {
    dispatch(GetEmotionTypeListService());
  }, [dispatch]);

  const segments = useMemo(() => {
    if (
      ProcessDataListForParticipants &&
      ProcessDataListForParticipants[0]?.is_prompt_section
    ) {
      return ProcessDataListForParticipants[0]?.prompt_sections[selectedPrompt]
        ?.time_segments;
    } else {
      return ProcessDataListForParticipants[0]?.time_segments;
    }
  }, [ProcessDataListForParticipants, selectedPrompt]);

  const updateSpeakerTimeSegments = useCallback(() => {
    setFilteredSegments((prevFilteredSegments) => {
      const filteredByFlags: TimeSegment[] = segments.filter(
        (segment: TimeSegment) =>
          selectedFlags.some(
            (selectedFlag: FlagOption) => selectedFlag.id === segment.flag_id
          )
      );

      if (selectedUserRole.length > 0) {
        const newFilteredSegments: TimeSegment[] = (
          selectedUserRole.flatMap((role: UserRole) => {
            const selectedAttendee = attendeesRecord[role.index];
            if (selectedAttendee) {
              return filteredByFlags.flatMap((segment: TimeSegment | null) =>
                selectedAttendee.time_segment_list.includes(segment?.sno || 0)
                  ? { ...segment, updatedValue: 'yourUpdatedValue' }
                  : null
              );
            }
            return [];
          }) as (TimeSegment | null)[]
        ).filter(
          (segment: TimeSegment | null): segment is TimeSegment =>
            segment !== null
        );

        const sortedNewFilteredSegments: TimeSegment[] =
          newFilteredSegments.sort(
            (a, b) => (a?.start_time_stamp || 0) - (b?.start_time_stamp || 0)
          );

        return sortedNewFilteredSegments;
      }

      const sortedFilteredByFlags = filteredByFlags.sort(
        (a, b) => a.start_time_stamp - b.start_time_stamp
      );

      return sortedFilteredByFlags;
    });
  }, [segments, selectedUserRole, selectedFlags, attendeesRecord]);

  useEffect(() => {
    if (segments) {
      updateSpeakerTimeSegments();
    }
  }, [segments, selectedPrompt, updateSpeakerTimeSegments]);

  const getEmotionName = useCallback(
    (emotionId: number): string => {
      const matchedEmotion = EmotionTypeList.find(
        (emotion: EmotionType) => emotion.id === emotionId
      );
      return matchedEmotion?.name || '';
    },
    [EmotionTypeList]
  );

  useEffect(() => {
    if (currentVideoTime && filteredSegments.length > 0) {
      const newIndex = filteredSegments.findIndex(
        (item: TimeSegment) =>
          currentVideoTime >= Math.floor(item.start_time_stamp) &&
          currentVideoTime <= Math.floor(item.end_time_stamp)
      );
      setHighlightedIndex(newIndex);
    }
  }, [currentVideoTime, filteredSegments, setHighlightedIndex]);

  const handleTileClick = useCallback(
    (index: number) => {
      if (highlightedIndex === index) {
        return;
      }

      let desiredSegment = filteredSegments[index];
      setTipStartTime(desiredSegment.start_time_stamp);
      if (!videoPlaying) {
        setHighlightedIndex(index);
      }
    },
    [highlightedIndex, filteredSegments, setTipStartTime, videoPlaying]
  );

  const handlePlay = useCallback(() => {
    handleVideoTips('true');
  }, [handleVideoTips]);

  const handleEnd = useCallback(() => {
    handleVideoTips('false');
  }, [handleVideoTips]);

  return (
    <Box
      sx={{
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        height: 'auto',
        maxHeight: '125vh',
        overflow: 'scroll',
        pr: 2,
      }}
      ref={cascadeRef}
    >
      {filteredSegments.map((item: TimeSegment, index: number) => {
        let coachingMoment;

        if (videoData?.prompt_sections && selectedPrompt) {
          const promptSections = videoData?.prompt_sections[selectedPrompt];
          if (promptSections && promptSections.coaching_moments) {
            coachingMoment = promptSections.coaching_moments.find(
              (moment: CoachingMoment) =>
                moment.delivery_time_stamp === item.start_time_stamp
            );
          }
        } else {
          coachingMoment = videoData?.coaching_moments.find(
            (moment: CoachingMoment) =>
              moment.delivery_time_stamp === item.start_time_stamp
          );
        }

        const handleBackward = () => {
          if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
          }
        };

        const handleForward = () => {
          const nextIndex = currentIndex + 1;
          if (nextIndex < knowledgeTipsWithMedia.length) {
            setCurrentIndex(nextIndex);
          }
        };

        let knowledgeTipsWithMedia;
        if (coachingMoment) {
          knowledgeTipsWithMedia = coachingMoment.knowledge_tips.filter(
            (tip: knowledge_tips) => tip.media_link !== null
          );
        } else {
          knowledgeTipsWithMedia = [];
        }

        const isSingleTipWithMedia = knowledgeTipsWithMedia?.length === 1;
        const disableForward =
          knowledgeTipsWithMedia.length <= 1 ||
          currentIndex >= knowledgeTipsWithMedia.length - 1;
        const disableBackward = currentIndex === 0;

        const renderVisualIcon = () => {
          if (item.time_chunks) {
            switch (item.time_chunks[0].visual_flag) {
              case 'green':
                return <VideoGreenFlagAnalysisIcon />;
              case 'yellow':
                return <VideoYellowFlagAnalysisIcon />;
              case 'red':
                return <VideoRedFlagAnalysisIcon />;
              case 'grey':
              default:
                return <VideoGreyFlagAnalysisIcon />;
            }
          }
        };

        const renderToneIcon = () => {
          if (item.time_chunks) {
            switch (item.time_chunks[0].tone_flag) {
              case 'green':
                return <AudioGreenFlagAnalysisIcon />;
              case 'yellow':
                return <AudioYellowFlagAnalysisIcon />;
              case 'red':
                return <AudioRedFlagAnalysisIcon />;
              case 'grey':
              default:
                return <AudioGreyFlagAnlysisIcon />;
            }
          }
        };

        const renderWordIcon = () => {
          if (item.time_chunks) {
            switch (item.time_chunks[0].word_flag) {
              case 'green':
                return <TextGreenFlagAnalysisIcon />;
              case 'yellow':
                return <TextYellowFlagAnalysisIcon />;
              case 'red':
                return <TextRedFlagAnalysisIcon />;
              case 'grey':
              default:
                return <TextGreyFlagAnalysisIcon />;
            }
          }
        };

        return (
          <>
            {item?.time_chunks?.length > 0 ? (
              <div
                className='card-container'
                key={index}
                onClick={() => handleTileClick(index)}
              >
                <div className='connecting-line'></div>
                <div
                  key={index}
                  ref={highlightedIndex === index ? highlightedCardRef : null}
                  className={`tip-card ${
                    highlightedIndex === index ? 'highlighted' : ''
                  }`}
                >
                  <div
                    className={`dot ${
                      index % 2 === 0 ? 'dot-even' : 'dot-odd'
                    }`}
                  ></div>
                  <div
                    onClick={
                      videoPlaying ? undefined : (e) => e.preventDefault()
                    }
                    key={index}
                    ref={highlightedIndex === index ? highlightedCardRef : null}
                    className={`tip-card ${
                      highlightedIndex === index ? 'highlighted' : ''
                    }`}
                    data-start-time={Math.floor(item.start_time_stamp)}
                    data-end-time={Math.floor(item.end_time_stamp)}
                  >
                    <Card
                      sx={{
                        margin: '20px',
                        width: '100%',
                        borderRadius: '4px',
                        border: '1px solid #D4D2FA',
                        backgroundColor:
                          highlightedIndex === index ? '#FFF' : 'transparent',
                        padding: '5px',
                      }}
                    >
                      <CardActionArea>
                        <Typography
                          sx={{ display: 'inline-flex', marginLeft: '10px' }}
                          label=''
                        >
                          <Typography
                            sx={{
                              color: '#0E4EEC',
                              backgroundColor: '#E2FCFF',
                              width: 'fit-content',
                              height: 'fit-content',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              borderRadius: '35px',
                            }}
                            label=''
                          >
                            {formatTimeInMinSecs(
                              Math.floor(item.start_time_stamp)
                            )}
                          </Typography>
                          {item.time_chunks[0].overall_flag === 'red' && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: '#FF0000' }}
                              className='rotate-icon'
                            />
                          )}
                          {item.time_chunks[0].overall_flag === 'yellow' && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: '#F6D41F' }}
                              className='rotate-icon'
                            />
                          )}
                          {item.time_chunks[0].overall_flag === 'green' && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: '#62C960' }}
                              className='rotate-icon'
                            />
                          )}
                          {item.time_chunks[0].overall_flag === 'grey' && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: '#808080' }}
                              className='rotate-icon'
                            />
                          )}
                          {getEmotionName(item.emotion_id)}
                        </Typography>
                        {highlightedIndex === index && (
                          <Box
                            sx={{
                              width: '100%',
                              height: 'fit-content',
                              mt: 1,
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 1,
                                mb: 1,
                                gap: 1,
                              }}
                            >
                              {renderToneIcon()}
                              <Typography
                                label='Tone'
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  color: '#1F2937',
                                }}
                              />
                            </Box>
                            {ProcessDataListForParticipants[0]
                              .session_type_id !== 58 &&
                              ProcessDataListForParticipants[0]
                                .session_type_id !== 59 && (
                                <Typography
                                  sx={{
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    color: '##000000',
                                  }}
                                  id='segmentLable'
                                  label={item.time_segment_label}
                                />
                              )}
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 1,
                                mb: 1,
                                gap: 1,
                              }}
                            >
                              {renderVisualIcon()}
                              <Typography
                                label='Expression'
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  color: '#1F2937',
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 1,
                                mb: 1,
                                gap: 1,
                              }}
                            >
                              {renderWordIcon()}
                              <Typography
                                label='Words'
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  color: '#1F2937',
                                }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 500,
                                color: '##000000',
                              }}
                              label={
                                item.time_chunks
                                  .filter(
                                    (chunk) =>
                                      currentVideoTime >=
                                        chunk.start_time_stamp &&
                                      currentVideoTime < chunk.end_time_stamp
                                  )
                                  .map((chunk) => chunk.words)[0] || ''
                              }
                            />

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mt: 1,
                                mb: 1,
                                gap: 1,
                              }}
                            >
                              <Typography
                                label='Markers'
                                sx={{
                                  fontWeight: 600,
                                  fontSize: '14px',
                                  color: '#1F2937',
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                              }}
                            >
                              {item.time_chunks
                                .filter(
                                  (chunk) =>
                                    currentVideoTime >=
                                      chunk.start_time_stamp &&
                                    currentVideoTime < chunk.end_time_stamp
                                )
                                .map((chunk, index) => (
                                  <Box key={chunk.chunk_id}>
                                    {chunk.mental_markers &&
                                      Object.keys(chunk.mental_markers).length >
                                        0 &&
                                      Object.entries(chunk.mental_markers).map(
                                        ([key, value]) => (
                                          <Typography
                                            label={
                                              key.charAt(0).toUpperCase() +
                                              key.slice(1)
                                            }
                                            key={key}
                                            sx={{
                                              fontSize: '14px',
                                              fontWeight: 500,
                                              color: '#000',
                                            }}
                                          >
                                            : {String(value)}
                                          </Typography>
                                        )
                                      )}
                                  </Box>
                                ))}
                            </Box>
                          </Box>
                        )}
                      </CardActionArea>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className='card-container'
                key={index}
                onClick={() => handleTileClick(index)}
              >
                <div className='connecting-line'></div>
                <div
                  key={index}
                  ref={highlightedIndex === index ? highlightedCardRef : null}
                  className={`tip-card ${
                    highlightedIndex === index ? 'highlighted' : ''
                  }`}
                >
                  <div
                    className={`dot ${
                      index % 2 === 0 ? 'dot-even' : 'dot-odd'
                    }`}
                  ></div>
                  <div
                    onClick={
                      videoPlaying ? undefined : (e) => e.preventDefault()
                    }
                    key={index}
                    ref={highlightedIndex === index ? highlightedCardRef : null}
                    className={`tip-card ${
                      highlightedIndex === index ? 'highlighted' : ''
                    }`}
                    data-start-time={Math.floor(item.start_time_stamp)}
                    data-end-time={Math.floor(item.end_time_stamp)}
                  >
                    <Card
                      sx={{
                        margin: '20px',
                        width: '100%',
                        borderRadius: '4px',
                        border: '1px solid #D4D2FA',
                        backgroundColor:
                          highlightedIndex === index ? '#FFF' : 'transparent',
                        boxShadow:
                          highlightedIndex === index
                            ? '0px 0px 7px 0px rgba(90, 98, 241, 0.59)'
                            : 'none',
                        padding: '5px',
                      }}
                    >
                      <CardActionArea>
                        <Typography
                          sx={{ display: 'inline-flex', marginLeft: '10px' }}
                          label=''
                        >
                          <Typography
                            sx={{
                              color: '#0E4EEC',
                              backgroundColor: '#E2FCFF',
                              width: 'fit-content',
                              height: 'fit-content',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              borderRadius: '35px',
                            }}
                            label=''
                          >
                            {formatTimeInMinSecs(
                              Math.floor(item.start_time_stamp)
                            )}
                          </Typography>
                          {item.flag_id === 1 && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: 'red' }}
                              className='rotate-icon'
                            />
                          )}
                          {item.flag_id === 2 && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: 'yellow' }}
                              className='rotate-icon'
                            />
                          )}
                          {item.flag_id === 3 && (
                            <BookmarkIcon
                              sx={{ marginRight: '5px', color: 'green' }}
                              className='rotate-icon'
                            />
                          )}
                          {getEmotionName(item.emotion_id)}
                        </Typography>
                        {highlightedIndex === index && (
                          <div>
                            <Typography
                              variant='h6'
                              sx={{
                                color: '#2C2C2C',
                                fontSize: '12px',
                                fontStyle: 'bold',
                                fontWeight: 900,
                                lineHeight: 'normal',

                                margin: '5%',
                              }}
                              label='videoPreviewAdvance.Suggestions'
                            />

                            <Typography
                              sx={{ margin: '10px' }}
                              label={item.time_segment_label}
                            />
                          </div>
                        )}
                      </CardActionArea>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </Box>
  );
}

export default TipCascadeVersion2;

import React, { useRef, useState, useEffect } from 'react';
import { Play, Pause, Volume2, VolumeX } from 'lucide-react';

interface VideoPlayerProps {
  url: string;
  poster?: string;
}

export const IosVideoPlayer: React.FC<VideoPlayerProps> = ({ url, poster }) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const video = playerRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      setProgress((video.currentTime / video.duration) * 100);
      setCurrentTime(video.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(video.duration);
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  const togglePlay = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (playerRef.current) {
      playerRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const video = playerRef.current;
    if (!video) return;

    const newTime = (parseFloat(e.target.value) / 100) * video.duration;
    video.currentTime = newTime;
    setProgress(parseFloat(e.target.value));
  };

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        backgroundColor: 'black',
      }}
    >
      <video
        id='livePreview'
        ref={playerRef}
        playsInline
        autoPlay
        poster={poster}
        style={{
          width: '100%',
          aspectRatio: '16/9',
          objectFit: 'cover',
        }}
      >
        <source src={url} type='video/mp4' />
      </video>

      {/* Custom Controls */}
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          background:
            'linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent)',
          padding: '16px',
        }}
      >
        {/* Progress Bar */}
        <input
          type='range'
          min='0'
          max='100'
          value={progress}
          onChange={handleProgressChange}
          style={{
            width: '100%',
            height: '4px',
            background: `linear-gradient(to right, white ${progress}%, gray ${progress}%)`,
            borderRadius: '2px',
            appearance: 'none',
            cursor: 'pointer',
          }}
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            {/* Play/Pause Button */}
            <button
              onClick={togglePlay}
              style={{
                color: 'white',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                transition: 'color 0.2s',
              }}
            >
              {isPlaying ? (
                <Pause style={{ width: '24px', height: '24px' }} />
              ) : (
                <Play style={{ width: '24px', height: '24px' }} />
              )}
            </button>

            {/* Mute Button */}
            <button
              onClick={toggleMute}
              style={{
                color: 'white',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                transition: 'color 0.2s',
              }}
            >
              {isMuted ? (
                <VolumeX style={{ width: '24px', height: '24px' }} />
              ) : (
                <Volume2 style={{ width: '24px', height: '24px' }} />
              )}
            </button>

            {/* Time Display */}
            <div
              style={{
                color: 'white',
                fontSize: '14px',
              }}
            >
              {formatTime(currentTime)} / {formatTime(duration)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
